import i18n from 'i18next'
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Error } from "./error"
import { closeModal, openModal, PASSWORD_REGEX } from "../const/common"
import { userService } from "../service/user-service"
import { store } from "../repository/store"
import { infoToast } from "../const/utils"
import AuthModal from "../component/auth-modal"
import { authService } from "../service/authorization-service"

export default function(props) {
    const navigate = useNavigate()
    const { state } = useLocation()

    if (state == null) return <Error/>

    const identifier = state.identifier

    if (identifier == null) return <Error/>

    return <
        Password
        { ...props }
        navigate={ navigate }
        identifier={ identifier }
    />
}

class Password extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            username: {
              value: props.identifier,
              isValid: false,
            },
            password: {
                value: "",
                isValid: false,
                hidden: true,
            },
            authId: null,
            authCode: null,

            isAuthTimerOn: false,
        }

        this.navigate = this.props.navigate
        this.identifier = this.props.identifier

        this.handleUsername = this.handleUsername.bind(this)
        this.handlePassword = this.handlePassword.bind(this)
        this.handleCode = this.handleCode.bind(this)
        this.handleHidden = this.handleHidden.bind(this)

        this.onHandleTimerOn = this.onHandleTimerOn.bind(this)
        this.onHandleAuth = this.onHandleAuth.bind(this)
        this.changePassword = this.changePassword.bind(this)
        this.onHandleAuthModalClose = this.onHandleAuthModalClose.bind(this)

        this.hiddenOrNotIcon = this.hiddenOrNotIcon.bind(this)

        this.passwordType = this.passwordType.bind(this)
    }

    handleUsername(e) {
        const username = e.target.value

        const isValid = username === "" || username.length > 0

        this.setState((state, _) => ( { username: { isValid: isValid, value: username } } ))
    }

    handlePassword(e) {
        const password = e.target.value

        const isValid = password === "" || password.match(PASSWORD_REGEX)

        this.setState((state, _) => ( {
            password: {
                isValid: isValid,
                value: password,
                hidden: state.password.hidden
            }
        } ))
    }

    handleCode(e) {
        const code = e.target.value.trim()
        const isValid = code !== ""

        const codeState = { isValid: isValid, value: code }

        this.setState((state, _) => ( { code: codeState } ))
    }

    handleHidden(_) {
        this.setState(
            (state, _) => ({
                    password: {
                        isValid: state.password.isValid,
                        value: state.password.value,
                        hidden: !state.password.hidden
                    }
                }
            )
        )
    }

    onHandleTimerOn(isAuthTimerOn) {
        this.setState(
            (state, _) => ( { isAuthTimerOn: isAuthTimerOn } ),
        )
    }

    async onHandleAuth() {
        const identifier = this.state.username.value

        if (this.state.authId == null) {
            const auth = await authService.create([ "PASSWORD_UPDATE" ], "EMAIL", identifier)

            if (auth.error != null || auth.httpCode != null) return this.onHandleAuthModalClose()

            this.setState(( { authId: auth.id, isAuthTimerOn: true } ))
        }

        if (this.state.authCode == null) openModal("auth-modal")
    }

    async changePassword(authCode) {
        const username = this.state.username.value
        const password = this.state.password.value

        const isValid = username.length > 0 && password.match(PASSWORD_REGEX)

        if (!isValid) return

        const data = await userService.updatePassword(
            this.state.authId,
            authCode,
            username,
            password,
        )

        this.onHandleAuthModalClose()

        const anyError = data.error != null || data.httpCode != null

        if (!anyError) {
            store.data.user.password = null
            store.save()

            const callback = () => { this.navigate("/login") }

            infoToast(i18n.t("toast.success.password-update"))
            setTimeout(callback, 2000)
        }
    }

    onHandleAuthModalClose() {
        closeModal("auth-modal")

        this.setState((state, _) => ( { authId: null, authCode: null, isAuthTimerOn: false } ))
    }

    hiddenOrNotIcon() {
        if (this.state.password.hidden) return <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-eye-fill"
            viewBox="0 0 16 16"
        >
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
        </svg>

        return <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-eye-slash"
            viewBox="0 0 16 16"
        >
            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
        </svg>
    }

    passwordType() {
        return this.state.password.hidden ? "password" : "text"
    }

    disable() {
        const username = this.state.username
        const password = this.state.password

        return username.value.length <= 0 || !password.isValid
    }


    render() {
        return (
            <div className="px-0 mx-0">
                <div className="col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-4 text-center py-0 px-0 m-auto">
                    <div className="container">
                        <div className="row row-gap-3">
                            <div className="card shadow-sm py-3">
                                <span className="h4 fw-normal mb-0">{ i18n.t("password.title") }</span>
                            </div>
                            <form className="px-0">
                                <div className="col-12 p-0">
                                    <div className="form-floating shadow-sm rounded">
                                        <input
                                            type="email"
                                            className="form-control rounded rounded-bottom-0"
                                            id="floatingInput"
                                            placeholder="username"
                                            value={ this.state.username.value }
                                            onChange={ this.handleUsername }
                                            autoComplete="username"
                                        />
                                        <label htmlFor="floatingInput">{ i18n.t("common.identifier") }</label>
                                    </div>
                                    <div className="input-group shadow-sm rounded">
                                        <div className="form-floating">
                                            <input
                                                id="floatingPassword"
                                                type={ this.passwordType() }
                                                className="form-control rounded-top-0 mb-0"
                                                placeholder="New Password"
                                                value={ this.state.password.value }
                                                onChange={ this.handlePassword }
                                                autoComplete="new-password"
                                            />
                                            <label htmlFor="floatingPassword">{ i18n.t("password.newPassword") }</label>
                                        </div>
                                        <div className="input-group-text rounded-top-0 p-0">
                                            <button
                                                type="button"
                                                className="btn rounded-top-0 rounded-start-0"
                                                style={ { width: "56px", height: "56px" } }
                                                onClick={ this.handleHidden }
                                            >
                                                { this.hiddenOrNotIcon() }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <button
                                className="btn btn-primary shadow-sm col-12 m-auto"
                                onClick={ this.onHandleAuth }
                                disabled={ this.disable() }
                            >
                                { i18n.t("password.reset") }
                            </button>
                            <div className="text-start p-0 mb-4">
                                <ul className="list-group list-group-flush border shadow-sm rounded">
                                    <li className="list-group-item">
                                        <span className="fw-medium text-decoration-underline">
                                            { i18n.t("common.password.name") }:
                                        </span>
                                        <br/>
                                        <span className="fst-italic small">
                                            { i18n.t("common.password.rule.1SpecialCharacter") } (~, @, #, $, &)
                                        </span>
                                        <br/>
                                        <span className="fst-italic small">
                                            { i18n.t("common.password.rule.1UppercaseCharacter") }
                                        </span>
                                        <br/>
                                        <span className="fst-italic small">
                                            { i18n.t("common.password.rule.1LowercaseCharacter") }
                                        </span>
                                        <br/>
                                        <span className="fst-italic small">
                                            { i18n.t("common.password.rule.moreThan8Characters") }
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <AuthModal
                    isTimerOn={ this.state.isAuthTimerOn }
                    setIsTimerOn={ this.onHandleTimerOn }
                    nextAction={ this.changePassword }
                    close={ this.onHandleAuthModalClose }
                />
            </div>
        );
    }
}