import React from "react"

export const Context = React.createContext(
    {
        user: {
            isConnected: false,
            userId: null,
            username: null,
            type: null,
            gender: null,
            isAdmin: false
        },
        search: {
            games: [],
            records: [],
            requestTime: null
        }
    }
)