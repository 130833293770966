import i18n from "i18next"
import {
    capitalizeFirstLetter,
    gender,
    level,
    onClipboard,
    regionFinder,
    regionTranslation,
    teamsInfo, timesParser
} from "../const/common"
import { formatPrice, handleTheme, startDate } from "../const/utils"
import Linkify from "react-linkify"
import React, { useEffect, useState } from "react"

export default function GameBodyOffcanvas(props) {

    const [ game, setGame ] = useState(props.game)

    useEffect(() => setGame(props.game), [ props.game ])

    const users = !!props.users ? props.users : []

    const color = handleTheme() === "dark" ? "white" : "black"

    const region = regionFinder(game.region)

    return <div className="offcanvas-body">

        <div className="row row-gap-3 mx-0">
            <div className="card p-0 shadow-sm" hidden={ game.whatsAppGroupLink == null }>
                <div className="card-body p-0">
                    <a
                        className="btn whatsapp-button border-0 px-2 py-3 w-100 h-100"
                        target="_blank"
                        href={ game.whatsAppGroupLink }
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16"
                            fill="currentColor"
                            className="bi bi-whatsapp"
                            viewBox="0 0 16 16"
                        >
                            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                        </svg>{ "\t" }{ i18n.t("common.join-whats-app-group") }
                    </a>
                </div>
            </div>

            <div className="card shadow-sm">
                <div className="card-body px-1">
                    { game.name.toUpperCase() }
                </div>
            </div>

            <div className="card shadow-sm">
                <div className="card-body px-1">
                    <div className="d-flex justify-content-between">
                        <div>
                            { i18n.t("home.ground") }
                        </div>
                        <div>
                            { capitalizeFirstLetter(game.ground.toLowerCase()) }
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow-sm">
                <div className="card-body px-1">
                    <div className="d-flex justify-content-between">
                        <div>
                            Date
                        </div>
                        <div>
                            { startDate(game.startDate) }
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow-sm">
                <div className="card-body px-1">
                    <div className="d-flex justify-content-between">
                        <div>
                            { i18n.t("common.hourly") }
                        </div>
                        <div>
                            { timesParser(game.startTime, game.endTime) }
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow-sm" hidden={ !game.canManageRegistration }>
                <div className="card-body px-1">
                    <div className="d-flex justify-content-between">
                        <div>
                            { i18n.t("home.team-registered") }
                        </div>
                        <div>
                            { game.nbOfRegisterTeam } / { game.nbOfTeam }
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow-sm">
                <div className="card-body px-1">
                    <div className="d-flex justify-content-between">
                        <div>
                            { game.nbOfMember } { i18n.t("home.players") }
                        </div>
                        <div>
                            { gender(game) }
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" hidden={ game.nbOfExtraMember === 0 }>
                        <div hidden={ game.nbOfExtraMember !== 1 }>
                            { i18n.t("common.additional-player") }
                        </div>
                        <div hidden={ game.nbOfExtraMember !== 1 }>
                            { game.nbOfExtraMember } { i18n.t("common.accepted") }
                        </div>

                        <div hidden={ game.nbOfExtraMember < 2 }>
                            { i18n.t("common.additional-players") }
                        </div>
                        <div hidden={ game.nbOfExtraMember < 2 }>
                            { game.nbOfExtraMember } { i18n.t("common.accepteds") }
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow-sm">
                <div className="card-body px-1">
                    <div className="d-flex justify-content-between">
                        <div>
                            { i18n.t("level.name") }
                        </div>
                        <div>
                            { level(game) }
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow-sm">
                <div className="card-body px-1">
                    <div className="d-flex justify-content-between">
                        <div>
                            { i18n.t("home.price-player") }
                        </div>
                        <div className="my-auto" style={ { minWidth: "fit-content" } }>
                            { formatPrice(game.playerPrice) }
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-between"
                        hidden={ game.nbOfExtraMember === 0 }
                    >
                        <div hidden={ game.nbOfExtraMember === 0 }>
                            { i18n.t("home.price-additional-player") }
                        </div>
                        <div
                            className="my-auto"
                            hidden={ game.nbOfExtraMember === 0 }
                            style={ { minWidth: "fit-content" } }
                        >
                            { formatPrice(game.playerPrice) }
                        </div>
                    </div>
                    <div className="d-flex justify-content-between"
                         hidden={ game.dayOfEventPlayerPrice == null }>
                        <div hidden={ game.dayOfEventPlayerPrice == null }>
                            { i18n.t("home.price-at-day-of-event") }
                        </div>
                        <div
                            className="my-auto"
                            hidden={ game.dayOfEventPlayerPrice == null }
                            style={ { minWidth: "fit-content" } }
                        >
                            { formatPrice(game.dayOfEventPlayerPrice) }
                        </div>
                    </div>
                </div>
            </div>

            <button
                className="btn text-start p-0"
                onClick={ () => onClipboard(i18n.t("common.phoneNumber"), game.phoneNumber) }
                hidden={ game.phoneNumber == null || game.phoneNumber === "" }
            >
                <div className="card shadow-sm">
                    <div className="card-body p-0">
                        <div className="row p-3">
                            <div className="col-10">
                                { game.phoneNumber }
                            </div>
                            <div className="col-2 text-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-copy h-100 my-auto"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </button>

            <button
                className="btn text-start p-0"
                onClick={ () => onClipboard(i18n.t("common.mail"), game.mail) }
                hidden={ game.mail == null || game.mail === "" }
            >
                <div className="card shadow-sm">
                    <div className="card-body p-0">
                        <div className="row p-3">
                            <div className="col-10">
                                { game.mail }
                            </div>
                            <div className="col-2 text-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-copy h-100 my-auto"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </button>

            <button
                className="btn text-start p-0"
                onClick={ () => onClipboard(i18n.t("common.address"), game.address) }
            >
                <div className="card shadow-sm">
                    <div className="card-body p-0">
                        <div className="row p-3">
                            <div className="col-10">
                                { game.address }, { regionTranslation(region) }, { capitalizeFirstLetter(region.country) }
                            </div>
                            <div className="col-2 text-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-copy h-100 my-auto"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </button>

            <div className="card shadow-sm" hidden={ game.detail == null || game.detail === "" }>
                <div className="card-body px-1">
                    <Linkify properties={ { target: '_blank', style: { color: color } } }>
                        <div style={ {
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word"
                        } }>{ game.detail }</div>
                    </Linkify>
                </div>
            </div>

            <div
                className="container my-1"
                hidden={ !game.canManageRegistration || game.teams.length === 0 }
            >
                <div className="col card" style={ { alignSelf: "center" } }></div>
            </div>

            { teamsInfo(game, users) }
        </div>
    </div>
}