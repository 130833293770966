import React, { useContext } from "react"
import i18n from "i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from "../const/context"
import { disconnect, isNotConnected } from "../const/utils"
import Login from "./login"
import { userService } from "../service/user-service"
import { pictureByGender, removeObjectWithId } from "../const/common"
import { gameService } from "../service/game-service"
import Games from "./games"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    const state = location.state

    if (isNotConnected(context)) return <Login/>

    const isEmptyState = state == null

    if (isEmptyState || state.game == null) return <Games/>

    const game = state.game

    return <Managers
        { ...props }
        navigate={ navigate }
        location={ location }
        context={ context }
        game={ game }
    />
}

class Managers extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            keyword: "",
            users: [],
            userAreMangers: {}
        }

        this.navigate = props.navigate

        this.onHandleKeyword = this.onHandleKeyword.bind(this)
        this.onHandleDeleteKeyword = this.onHandleDeleteKeyword.bind(this)

        this.onHandleRemove = this.onHandleRemove.bind(this)

        this.removeButton = this.removeButton.bind(this)
        this.addButton = this.addButton.bind(this)

        this.user = this.user.bind(this)
    }

    componentDidMount() {
        this.getAll()
    }

    getAll() {
        const managerIds = this.props.game.managers
        const connectedUserId = this.props.context.user.userId

        const isEmptyManagers = managerIds == null || managerIds.length === 0

        if (isEmptyManagers) return this.searchUserNotManger([], connectedUserId, [])

         else userService.search(managerIds, [], this.state.keyword).then(res => {

            const filteredManagers = res.filter(
                it => it.username !== "joyboy" && it.id !== connectedUserId
            ).map(
                it => ({ ...it, isManager: this.isManager(it.id, true) })
            )

            this.searchUserNotManger(managerIds, connectedUserId, filteredManagers)
        })
    }

    searchUserNotManger(managerIds, connectedUserId, filteredManagers) {
        userService.search([], managerIds, this.state.keyword).then(res => {

            const filteredNotManagers = res.filter(
                it => it.username !== "joyboy" && it.id !== connectedUserId
            ).map(
                it => ({ ...it, isManager: this.isManager(it.id, false) })
            )

            const userAreMangers = this.state.userAreMangers

            filteredManagers.forEach(it => userAreMangers[it.id] = it.isManager)
            filteredNotManagers.forEach(it => userAreMangers[it.id] = it.isManager)

            const users = filteredManagers.concat(filteredNotManagers)

            this.setState((state, _) => ({ users, userAreMangers }))
        })
    }

    isManager(userId, defaultValue) {
        const userAreMangers = this.state.userAreMangers

        return userId in userAreMangers ? userAreMangers[userId] : defaultValue
    }

    onHandleKeyword(e) {
        const keyword = e.target.value
        this.setState(
            (state, _) => ({ keyword: keyword }),
            () => { this.getAll(keyword) }
        )
    }

    onHandleDeleteKeyword(_) {
        const currentKeyword = this.state.keyword

        this.setState(
            (state, _) => ({ keyword: "" }),
            () => {
                if (this.state.keyword !== currentKeyword) this.getAll()
            }
        )
    }

    async onHandleRemove(userId) {
        const res = await gameService.updateManager(this.props.game.id, userId, "DELETE")

        if (res.status === 401) return disconnect(this.navigate, this.props.context, "/games")

        if (res.error != null || res.httpCode != null) return

        const userAreMangers = ({...this.state.userAreMangers, [userId]: false})

        const users = this.state.users

        const userToUpdate = users.filter(it => it.id === userId)[0]

        userToUpdate.isManager = false

        removeObjectWithId(users, userId)

        users.push(userToUpdate)

        return this.setState(() => ({ users, userAreMangers }))
    }


    async onHandleAdd(userId) {
        const res = await gameService.updateManager(this.props.game.id, userId, "ADD")

        if (res.status === 401) return disconnect(this.navigate, this.props.context, "/games")

        if (res.error != null || res.httpCode != null) return

        const userAreMangers = ({...this.state.userAreMangers, [userId]: true})

        const users = this.state.users

        const userToUpdate = users.filter(it => it.id === userId)[0]

        userToUpdate.isManager = true

        removeObjectWithId(users, userId)

        users.push(userToUpdate)

        return this.setState(() => ({ userAreMangers }))
    }

    removeButton(userId) {
        return <button
            className="btn btn-danger btn-sm rounded-top-0 col-12"
            onClick={ () => this.onHandleRemove(userId) }
        >{ i18n.t("button.delete") }</button>
    }

    addButton(userId) {
        return <button
            className="btn btn-primary btn-sm rounded-top-0 col-12"
            onClick={ () => this.onHandleAdd(userId) }
        >{ i18n.t("button.add") }</button>
    }

    user(it) {
        const userId = it.id

        const userIsManager = this.state.userAreMangers[userId]

        const button = userIsManager ? this.removeButton(userId) : this.addButton(userId)

        const name = it.partner === "HELLO_ASSO" ? <a
            className="link-offset-2 link-underline link-underline-opacity-0"
            target="_blank"
            href={ it.haUrl }
        >
            { it.username }
        </a> : it.firstname + " " + it.lastname

        return <div className="col-6 col-md-4 emergence">
            <div className="card h-100 shadow-sm">
                <img
                    src={ pictureByGender(it) }
                    className="img-thumbnail border-0 bg-transparent w-50 m-auto pt-3"
                    alt="user_image"
                />
                <div className="card-body">
                    <div className="text-center fw-semibold small">{ name }</div>
                </div>
                <div className="card-footer p-0">{ button }</div>
            </div>
        </div>
    }

    users() {
        return this.state.users.sort(
            (a, b) => Number(b.isManager) - Number(a.isManager)
        ).map(
            it => <React.Fragment key={ it.id }>{ this.user(it) }</React.Fragment>
        )
    }

    render() {
        return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto p-0 mb-3">

            <div className="row border rounded shadow-sm px-3 py-2 mx-0 mb-3">
                <div className="col-7 col-md-9 px-0 my-auto">
                    <p className="fs-2 m-0">{ i18n.t("managers.title") }</p>
                </div>
                <div className="col-5 col-md-3 text-end m-auto px-0">
                    <button
                        className="btn btn-warning"
                        onClick={ () => { this.navigate(-1) } }
                    >
                        <div className="row">
                            <div className="col-1">

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17" height="17"
                                    fill="currentColor"
                                    className="bi bi-arrow-left-circle"
                                    viewBox="-2 0 20 15"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                    />
                                </svg>

                            </div>
                            <div className="col px-1">{ i18n.t("button.back") }</div>
                        </div>
                    </button>
                </div>
            </div>

            <div className="px-1 my-4">
                <div className="col card border-2" style={ { alignSelf: "center" } }></div>
            </div>

            <div className="input-group shadow-sm rounded col-12 my-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder={ i18n.t("sideBar.keyword") }
                    value={ this.state.keyword }
                    onChange={ this.onHandleKeyword }
                />
                <button
                    id="deleteField-keyword"
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={ this.onHandleDeleteKeyword }
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-x-lg"
                        viewBox="0 0 16 16"
                        style={ { pointerEvents: "none" } }
                    >
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </button>
            </div>

            <div className="row row-gap-3 row-col-3">
                { this.users() }
            </div>
        </div>
    }
}