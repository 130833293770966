import React from "react"

export class InputNumber extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            value: props.initialValue
        }

        this.label = props.label
        this.minValue = props.minValue != null ? props.minValue : 0
        this.hideFiveButton = !!props.hideFiveButton ? props.hideFiveButton : false
        this.disabled = !!props.disabled ? props.disabled : false

        this.min = this.min.bind(this)

        this.onHandleMin5 = this.onHandleMin5.bind(this)
        this.onHandleAdd5 = this.onHandleAdd5.bind(this)

        this.onHandleMin = this.onHandleMin.bind(this)
        this.onHandleAdd = this.onHandleAdd.bind(this)
    }

    min(value) {
        if (this.state.value <= this.minValue) return this.minValue

        const expectedValue = this.state.value - value

        return expectedValue < this.minValue ? this.minValue : expectedValue
    }

    onHandleMin5(_) {
        if (this.disabled) return

        this.setState(
            (state, _) => ( { value: this.min(5) } ),
            () => this.props.onChange(this.state.value)
        )
    }

    onHandleAdd5(_) {
        if (this.disabled) return

        this.setState(
            (state, _) => ( { value: state.value + 5 } ),
            () => this.props.onChange(this.state.value)
        )
    }

    onHandleMin(_) {
        if (this.disabled) return

        this.setState(
            (state, _) => ( { value: this.min(1) } ),
            () => this.props.onChange(this.state.value)
        )
    }

    onHandleAdd(_) {
        if (this.disabled) return

        this.setState(
            (state, _) => ( { value: state.value + 1 } ),
            () => this.props.onChange(this.state.value)
        )
    }

    render() {
        return <div className="input-group shadow-sm rounded">
            <div className="form-floating">
                <input
                    type="number"
                    className="form-control"
                    placeholder={ this.label }
                    value={ this.state.value }
                    onChange={ this.props.onChange }
                    readOnly={ true }
                    disabled={ this.disabled }
                />
                <label htmlFor="floatingInput">{ this.label }</label>
            </div>
            <div className="input-group-text p-0">
                <button
                    type="button"
                    className="btn rounded-0 p-1"
                    style={ { width: "56px", height: "56px" } }
                    onClick={ this.onHandleMin5 }
                    hidden={ this.hideFiveButton }
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-5-circle"
                        viewBox="0 0 16 16"
                    >
                        <path d="M1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8m15 0A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-8.006 4.158c-1.57 0-2.654-.902-2.719-2.115h1.237c.14.72.832 1.031 1.529 1.031.791 0 1.57-.597 1.57-1.681 0-.967-.732-1.57-1.582-1.57-.767 0-1.242.45-1.435.808H5.445L5.791 4h4.705v1.103H6.875l-.193 2.343h.064c.17-.258.715-.68 1.611-.68 1.383 0 2.561.944 2.561 2.585 0 1.687-1.184 2.806-2.924 2.806Z"/>
                    </svg>
                </button>
                <button
                    type="button"
                    className="btn rounded-0 p-1"
                    style={ { width: "56px", height: "56px" } }
                    onClick={ this.onHandleAdd5 }
                    hidden={ this.hideFiveButton }
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-5-circle-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-8.006 4.158c1.74 0 2.924-1.119 2.924-2.806 0-1.641-1.178-2.584-2.56-2.584-.897 0-1.442.421-1.612.68h-.064l.193-2.344h3.621V4.002H5.791L5.445 8.63h1.149c.193-.358.668-.809 1.435-.809.85 0 1.582.604 1.582 1.57 0 1.085-.779 1.682-1.57 1.682-.697 0-1.389-.31-1.53-1.031H5.276c.065 1.213 1.149 2.115 2.72 2.115Z"/>
                    </svg>
                </button>
                <div className="d-flex flex-column">
                    <button
                        type="button"
                        className="btn"
                        style={
                            {
                                width: "56px",
                                height: "28px",
                                padding: "0px",
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                                borderBottomRightRadius: "0px",
                                borderTopRightRadius: "var(--bs-border-radius)"
                            }
                        }
                        onClick={ this.onHandleAdd }
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                        </svg>
                    </button>
                    <button
                        type="button"
                        className="btn"
                        style={
                            {
                                width: "56px",
                                height: "28px",
                                padding: "0px",
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                                borderBottomRightRadius: "var(--bs-border-radius)",
                                borderTopRightRadius: "0px"
                            }
                        }
                        onClick={ this.onHandleMin }
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-dash" viewBox="0 0 16 16">
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    }
}