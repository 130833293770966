import React from "react"
import { GROUND } from "../const/common"

export class Ground extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            value: props.ground
        }

        this.onHandleGround = this.onHandleGround.bind(this)
    }

    onHandleGround(e) {
        this.setState(
            (state, _) => ( { value: e.target.id } ),
            () => this.props.onHandleGround(this.state.value)
        )
    }

    divArrangement() {
        if (window.mobileCheck || window.innerWidth < 765) return <div className="row">
            {
                GROUND.filter(it => it.name === "INDOOR") .map(it => <div
                    className="col-12 col-md-2 mt-3"
                    key={ it.key }
                >
                    <input
                        type="radio"
                        className="btn-check"
                        name="ground"
                        id={ it.key }
                        autoComplete="off"
                        onChange={ this.onHandleGround }
                        checked={ this.state.value === it.key }
                    />
                    <label
                        className="btn btn-outline-secondary shadow-sm pt-3"
                        htmlFor={ it.key }
                    >
                        <img src={ it.image } className="img-thumbnail border-0" alt="Bootstrap"/>
                        <p className="text-center pt-3 mb-0">{ it.value }</p>
                    </label>
                </div>)
            }
            {
                GROUND.filter(it => it.name !== "INDOOR").map(it => <div
                    className="col-6 col-md-2 mt-3 h-100"
                    key={ it.key }
                >
                    <input
                        type="radio"
                        className="btn-check"
                        name="ground"
                        id={ it.key }
                        autoComplete="off"
                        onChange={ this.onHandleGround }
                        checked={ this.state.value === it.key }
                    />
                    <label
                        className="btn btn-outline-secondary shadow-sm pt-3"
                        htmlFor={ it.key }
                    >
                        <img src={ it.image } className="img-thumbnail border-0" alt="Bootstrap"/>
                        <p className="text-center pt-3 mb-0">{ it.value }</p>
                    </label>
                </div>)
            }
        </div>



        return  <div className="d-flex justify-content-between">
            {
                GROUND.map(it => <React.Fragment key={ it.key }>
                    <input
                        type="radio"
                        className="btn-check"
                        name="ground"
                        id={ it.key }
                        autoComplete="off"
                        onChange={ this.onHandleGround }
                        checked={ this.state.value === it.key }
                    />
                    <label
                        className="btn btn-outline-secondary shadow-sm col-12 col-md-2 mt-3"
                        htmlFor={ it.key }
                    >
                        <div className="col ps-2 pt-2 pe-2 pb-0">
                            <img src={ it.image } className="img-thumbnail bg-transparent border-0" alt="Bootstrap"/>
                            <p className="text-center pt-3 mb-0">{ it.value }</p>
                        </div>
                    </label>
                </React.Fragment>)
            }
        </div>
    }

    render() {
        return this.divArrangement()
    }
}