import React from "react"
import { Error } from "../route/error"
import Navbar from "../navbar/navbar"

export class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props)
        this.state = { hasError: !!props.isOnError }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) { }

    render() {
        if (this.state.hasError) return <React.Fragment>
            <header>
                <Navbar/>
            </header>
            <main>
                <Error/>
            </main>
        </React.Fragment>

        return this.props.children
    }
}