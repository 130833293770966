import "dotenv/config"
import { APPLICATION_JSON, GET, POST, PUT } from "../const/request"
import { store } from "../repository/store"
import { refreshToken, resParser } from "../const/utils"

class UserService {

    async getAll(
        keyword
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const formattedKeyword = keyword == null ? "" : keyword

        let path = process.env.BASE_URL + "/api/v1/users?page=0&size=100"

        if (keyword != null && keyword !== "") path = path + "&keyword=" + formattedKeyword

        const response = await fetch(path, {
            method: GET,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            }
        })

        return await resParser(response)
    }

    async getById(
        userId
    ){
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/users/" + userId

        const response = await fetch(path, {
            method: GET,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            }
        })

        return await resParser(response)
    }

    async search(
        ids,
        notIds,
        keyword,
        gender,
        type,
        canSuggestInGame,
        usernames = [],
        size
    ) {
        await refreshToken()

        const formattedKeyword = keyword == null ? "" : keyword
        const formattedIds = ids == null ? [] : ids
        const formattedNotIds = notIds == null ? [] : notIds

        const auth = "Bearer " + store.data.token.access.value

        const rawBody = {
            ids: formattedIds,
            nIds: formattedNotIds,
            keyword: formattedKeyword,
            gender: gender,
            type: type,
            canSuggestInGame: canSuggestInGame,
            usernames: usernames,
            size: size
        }

        const body = JSON.stringify(rawBody)

        const path = process.env.BASE_URL + "/api/v1/users/search"

        const response = await fetch(path, {
            method: POST,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
            body,
        })

        return await resParser(response)
    }

    async create(
        authId,
        authCode,
        username,
        password,
        mail,
        firstname,
        lastname,
        gender,
        bornDate
    ) {

        const headers = {
            "Content-Type": APPLICATION_JSON,
            "Authorization-Id": authId,
            "Authorization-Code": authCode,
        }

        const body = JSON.stringify({
            username: username,
            password: password,
            userTag: mail,
            firstname: firstname,
            lastname: lastname,
            gender: gender,
            bornDate: bornDate,
            authCode: authCode
        })

        const path = process.env.BASE_URL + "/api/v1/users"

        const response = await fetch(path, {
            method: POST,
            headers: headers,
            body,
        })

        return await resParser(response)
    }

    async update(
        userId,
        username,
        firstname,
        lastname,
        gender,
        bornDate
    ){
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/users/" + userId

        const body = JSON.stringify({
            username: username,
            firstname: firstname,
            lastname: lastname,
            gender: gender,
            bornDate: bornDate
        })

        const response = await fetch(path, {
            method: PUT,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
            body,
        })

        return await resParser(response)
    }

    async updatePassword(
        authId, authCode,
        identifier,
        password,
    ) {

        const headers = {
            "Content-Type": APPLICATION_JSON,
            "Authorization-Id": authId,
            "Authorization-Code": authCode,
        }

        const path = process.env.BASE_URL + "/api/v1/users/password"

        const body = JSON.stringify({ identifier: identifier, password: password })

        const response = await fetch(path, {
            method: PUT,
            headers: headers,
            body,
        })

        return await resParser(response)
    }

    async updateLock(
        userId,
        isLock
    ){
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/users/" + userId + "/account-lock"

        const body = JSON.stringify({ isLock: isLock })

        const response = await fetch(path, {
            method: PUT,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
            body,
        })

        return await resParser(response)
    }

    async userHAUpdate(
        userId,
        isCertified
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/users-ha/" + userId

        const body = JSON.stringify({ isCertified: isCertified })

        const response = await fetch(path, {
            method: PUT,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
            body,
        })

        return await resParser(response)
    }
}

export const userService = new UserService();