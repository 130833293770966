import i18n from "i18next"
import {
    captainCard,
    closeModal,
    i118nTPlayerWith,
    levelFromEnum,
    openModal,
    userCard
} from "../const/common"
import React, { useContext, useEffect, useState } from "react"
import GameOffcanvas from "./game-offcanvas"
import { teamOrderService } from "../service/team-order-service"
import PaymentsOffcanvas from "./payments-offcanvas"
import { userService } from "../service/user-service"
import { Context } from "../const/context"
import { teamService } from "../service/team-service"
import ButtonHelloAssoPayment from "./button-hello-asso-payment"
import { disconnect, formatPrice, isPOA } from "../const/utils"
import moment from "moment"
import MembersSettingsOffcanvas from "./members-settings-offcanvas"
import { useNavigate } from "react-router-dom"
import { parsePhoneNumber } from "libphonenumber-js"

export default function RegistrationTeamDefault(props) {
    const navigate = useNavigate()
    const context = useContext(Context)

    const currentUserId = !!props.userId ? props.userId : context.user.userId
    const currentUserUsername = !!props.username ? props.username : context.user.username

    const [ orders, setOrders ] = useState([])
    const [ users, setUsers ] = useState([])

    const [ event, setEvent ] = useState(props.event)
    const [ team, setTeam ] = useState(props.team)

    const isDayOfEvent = moment(event.startDate, "YYYY-MM-DD") <= moment()

    const [ basketPrice, setBasketPrice ] = useState("")
    const [ nbOfTicketToPay, setNbOfTicketToPay ] = useState(0)

    const [ disablePayment, setDisablePayment ] = useState(false)

    useEffect(() => setEvent(props.event), [ props.event ])
    useEffect(() => setTeam(props.team), [ props.team ])

    useEffect(() => {
        const price = nbOfTicketToPay * playerPrice()

        setBasketPrice(formatPrice(price))
    }, [ nbOfTicketToPay ])

    useEffect(() => {

        async function fetchOrders() {
            const res = await teamOrderService.getTeamOrders(event.id, team.id)

            if (res.status === 401) return disconnect(navigate, context, location.pathname)

            if (res.error != null || res.httpCode != null) return

            setOrders(res)
            setNbOfTicketToPay(nbOfTicketToPaid(event, team, users, res))
        }

        if (event != null && team != null) fetchOrders().then()
    }, [ team, users ])

    useEffect(() => {

        async function fetchUsers() {

            const res = await userService.search(
                [],
                [],
                "",
                null,
                "USER_DEFAULT",
                null,
                usernames()
            )

            setUsers(res)
        }

        if (team != null) fetchUsers().then()
    }, [ team ])

    const usernames = () => {
        if (team == null) []

        const teamMemberUsername = team.members.map(it => it.username)

        return [...teamMemberUsername, ...team.requestMembers]
    }

    const isMember = (team, userId) => team.members.find(it => it.userId === userId) != null

    const currentUser = (userId) => {
        if (users.length === 0) return <React.Fragment></React.Fragment>

        const user = users.find(it => it.id === userId)

        const asMember = team.members.find(it => it.userId === userId)

        const isMember = asMember != null
        const isCaptain = isMember && team.captain === asMember.username
        const isOnRequest = team.requestMembers.includes(user.username)

        if (isCaptain) return captainCard(user, "", asMember.phoneNumber)
        if (isMember) return userCard(user, () => {}, "")
        if (isOnRequest) return userCard(user, () => {}, "")
    }

    const isCaptain = (team, userId) => {
        const asMember = team.members.find(it => it.userId === userId)

        if (asMember == null) return false

        return team.captain === asMember.username
    }

    const captainPhoneNumber = (team) => {
        const captain = team.members.find(it => it.username === team.captain)

        const phoneNumber = parsePhoneNumber(captain.phoneNumber, "FR")

        return phoneNumber.formatInternational().replace(/ /g,'')
    }

    const user = (username) => {
        if (users.length === 0) return <React.Fragment></React.Fragment>

        const user = users.find(it => it.username === username)

        const asMember = team.members.find(it => it.userId === user.id)

        const isMember = asMember != null
        const isCaptain = isMember && team.captain === asMember.username

        if (isCaptain) return captainCard(user, "shadow-sm", asMember.phoneNumber)
        if (isMember) return userCard(user, null, "shadow-sm")
    }

    const playerPrice = () => {
        if (event.dayOfEventPlayerPrice == null || event.dayOfEventPlayerPrice === "") return event.playerPrice

        return isDayOfEvent ? event.dayOfEventPlayerPrice : event.playerPrice
    }

    const nbOfTicketToPaid = (event, team, users, orders) => {
        if (team == null) return 0

        const nbOfTeamMember = team.members.length
        const nbOfMembers = event.nbOfMember

        const nbOfEnableOrders = orders.filter(
            it => it.payment.state === "TO_PAY_ONLINE" || it.payment.state === "PROCESSED"
        ).flatMap(
            it => it.items.filter(it => it.type === "TICKET")
        ).map(
            it => it.quantity
        ).reduce(
            (qat1, qat2) => qat1 + qat2 , 0
        )

        const maxNbOfMembers = nbOfMembers >= nbOfTeamMember ? nbOfMembers : nbOfTeamMember

        if (event.nbOfExtraMember === 0) return maxNbOfMembers > nbOfEnableOrders ? maxNbOfMembers - nbOfEnableOrders : 0

        const teamMemberUsernames = team.members.map(it => it.username)
        const members = users.filter(it => teamMemberUsernames.includes(it.username))

        const nbMalesInTeam = members.filter(it => it.gender === "MALE").length
        const nbFemaleInTeam = members.filter(it => it.gender === "FEMALE").length

        const minMaleInTeam = event.minMaleInTeam
        const minFemaleInTeam = event.minFemaleInTeam

        const nbOfMemberWithFemaleRestriction = nbMalesInTeam + minFemaleInTeam
        const nbOfMaleWithMaleRestriction = nbFemaleInTeam + minMaleInTeam

        const isConsistencyWithFemaleRestriction = nbOfMemberWithFemaleRestriction > nbOfEnableOrders && nbOfMemberWithFemaleRestriction > maxNbOfMembers
        const isConsistencyWithMaleRestriction = nbOfMaleWithMaleRestriction > nbOfEnableOrders && nbOfMaleWithMaleRestriction > maxNbOfMembers

        if (isConsistencyWithFemaleRestriction) return nbOfMemberWithFemaleRestriction - nbOfEnableOrders
        if (isConsistencyWithMaleRestriction) return nbOfMaleWithMaleRestriction - nbOfEnableOrders

        return maxNbOfMembers > nbOfEnableOrders ? maxNbOfMembers - nbOfEnableOrders : 0
    }

    const isDisableTicketRange = () => {
        const startDate = moment(event.startDate, 'YYYY-MM-DD')
        const deadlineOfIndividualPaymentDate = startDate.subtract(event.deadlineOfIndividualPayment, "d")
        const currentDate = moment()

        return currentDate.isAfter(deadlineOfIndividualPaymentDate)
    }

    const isCurrentUserAdminOrManager = () => context.user.isAdmin || event.managers.includes(context.user.userId)

    const onHandleTicketRange = (e) => setNbOfTicketToPay(e.target.value)

    const onHandleAccept = async () => {
        const res = await teamService.updateRequestMember(event.id, team.id, "ADD")

        setTeam(res)
    }

    const onHandleRefuse = async () => {
        const res = await teamService.updateRequestMember(event.id, team.id, "DELETE")

        if (res.error != null || res.httpCode != null) return

        setTeam(null)
    }

    const onHandleDeleteMember = async (username) => {
        await teamService.deleteMember(event.id, team.id, username)

        setTeam(null)
    }

    const onHandleCancelTeam = async (eventId, teamId) => {
        const res = await teamService.cancel(eventId, teamId)

        if (res.error == null && res.httpCode == null) setTeam(res)
    }

    const onHandleCreateOrder = async () => {
        setDisablePayment(true)
        setTimeout(() => { setDisablePayment(false) }, 10000)

        if (nbOfTicketToPay === 0) return

        const backURL = process.env.BASE_HA_REDIRECT_URL + "/registrations"

        const res = await teamOrderService.create(event.id, team.id, nbOfTicketToPay, backURL, backURL, backURL)

        if (res.error != null || res.httpCode != null) return closeModal("pay-modal-" + team.id)

        if (res.isPOA) {
            team.status = "REGISTERED"
            return setOrders([res])
        }

        window.location.href = res.payment.paymentUrl
    }

    const onHandleTeamUpdate = async () => navigate("/team", { state: { event: event, team: team, orders: orders } })

    const anyEnableOrders = (username) => orders.filter(
        it => it.username === username
    ).filter(
        it => it.payment.state === "TO_PAY_ONLINE" || it.payment.state === "PROCESSED"
    ).length > 0

    const nbOfProcessedTicket = (orders) => orders.filter(
        it => it.payment.state === "PROCESSED"
    ).flatMap(
        it => it.items.filter(it => it.type === "TICKET")
    ).map(
        it => it.quantity
    ).reduce(
        (qat1, qat2) => qat1 + qat2 , 0
    )

    const deleteMember = (username) => <button
        className="btn btn-sm btn-danger emergence h-100 w-100 px-1"
        onClick={ () => onHandleDeleteMember(username) }
        style={ { maxHeight: "100%", alignSelf: "center" } }
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16" height="16"
            fill="currentColor"
            className="bi bi-trash" viewBox="0 0 16 16"
        >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
        </svg>{ "\t" }{ i18n.t("common.delete") }
    </button>

    const disableCanAccept = () => team.members.length >= (event.nbOfMember + event.nbOfExtraMember)

    const accept = () => <button
        className="btn btn-sm btn-primary emergence w-100 h-100 px-1"
        onClick={ () => onHandleAccept() }
        disabled={ disableCanAccept() }
        hidden={ isMember(team, currentUserId) }
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16" height="16"
            fill="currentColor"
            className="bi bi-check-lg"
            viewBox="0 0 16 16"
        >
            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
        </svg>
    </button>

    const registeringMessage = (nbOfTicketsRemaining) => {
        if (nbOfTicketsRemaining === 0) return i18n.t("team-status.payment-in-validation")
        if (nbOfTicketsRemaining === 1) return i18n.t("team-status.remains-to-be-reserved") + " " + nbOfTicketsRemaining + " Ticket"

        return i18n.t("team-status.remains-to-be-reserved") + " " + nbOfTicketsRemaining + " Tickets"
    }

    const statusButton = (event, team, users, orders) => {
        const nbOfTicketsRemaining = nbOfTicketToPaid(event, team, users, orders)
        const isMemberOfTeam = isMember(team, currentUserId)

        const availablePlaces = event.nbOfTeam > event.nbOfRegisterTeam
        const isRegistering = (team.status === "REGISTERING" && isMemberOfTeam) || (team.status === "WAITING_LIST" && availablePlaces && event.isPaymentAllow)

        const isPOARegistration = !event.isPaymentAllow && isPOA(orders)

        if (isRegistering) return <button
            className="btn btn-sm btn-warning h-100"
            style={ { placeContent: "center" } }
            disabled={ nbOfTicketsRemaining === 0 }
            onClick={ () => openModal("pay-modal-" + team.id) }
        >
            { registeringMessage(nbOfTicketsRemaining) }
        </button>
        if (team.status === "REGISTERING" && !isMemberOfTeam) return <button
            className="btn btn-sm btn-warning h-100"
            style={ { placeContent: "center" } }
        >
            { i18n.t("team-status.currently-booking") }
        </button>
        if (team.status === "REGISTERED" && isPOARegistration) return <button
            className="btn btn-sm btn-success bg-success border-success h-100"
        >
            { i18n.t("team-status.to-pay-on-arrival") }
        </button>
        if (team.status === "REGISTERED") return <button
            className="btn btn-sm btn-success bg-success border-success h-100"
        >
            { i18n.t("team-status.REGISTERED") }
        </button>
        if (team.status === "WAITING_LIST" && availablePlaces && isPOARegistration) return <button
            className="btn btn-sm btn-warning h-100"
            onClick={ onHandleCreateOrder }
        >
            { registeringMessage(nbOfTicketsRemaining) }
        </button>
        if (team.status === "WAITING_LIST") return <button
            className="btn btn-sm btn-info bg-info border-info h-100"
        >
            { i18n.t("team-status.WAITING_LIST") }
        </button>
        if (team.status === "CANCELED") return <button
            className="btn btn-sm btn-danger bg-danger border-danger h-100"
        >
            { i18n.t("team-status.CANCELED") }
        </button>
    }

    const onRequestUser = (username) => {
        if (users.length === 0) return <React.Fragment></React.Fragment>

        const user = users.find(it => it.username === username)

        if (user == null) return <React.Fragment></React.Fragment>

        return userCard(user, null, "shadow-sm")
    }

    if (team == null) return <React.Fragment></React.Fragment>

    return <div className="card shadow-sm emergence">
        <div className="card-body px-0">
            <div className="row row-gap-3">
                <div className="d-flex justify-content-between mx-0" style={ { alignItems: "center" } }>
                    <div className="d-flex h-100 mx-0">
                        <button className="btn fw-bold border">{ team.name }</button>
                        <button
                            className="btn btn-sm border-0"
                            style={ { verticalAlign: "sub" } }
                            disabled
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16"
                                fill="currentColor"
                                className="bi bi-arrow-right"
                                viewBox="0 -1 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                />
                            </svg>
                        </button>
                        { statusButton(event, team, users, orders) }
                    </div>
                    <div className="d-flex h-100 mx-0 ps-2 ps-md-0">
                        <button
                            className="btn btn-danger"
                            hidden={ !isCurrentUserAdminOrManager() || team.status === "CANCELED" }
                            onClick={ () => onHandleCancelTeam(event.id, team.id) }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16"
                                fill="currentColor"
                                className="bi bi-x-lg" viewBox="0 0 16 16"
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="row row-gap-3 mx-0">
                    <div className="d-flex px-0" style={ { alignItems: "center" } }>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-return-right"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0 h-100">
                            <button
                                className="btn btn-sm h-100 btn-primary"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target={ "#game-offcanvas-" + team.id }
                                aria-controls="game-offcanvas"
                            >
                                { i18n.t("common.event") }
                            </button>
                        </div>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-right"
                                    viewBox="0 -1 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border"
                                style={ {
                                    maxWidth: "140px",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                } }
                            >
                                { event.name }
                            </button>
                        </div>
                    </div>
                    <div className="d-flex px-0" style={ { alignItems: "center" } }>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-return-right"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0">
                            <button
                                className="btn btn-sm btn-primary"
                                data-bs-toggle="offcanvas"
                                data-bs-target={ "#members-offcanvas-" + team.id }
                                aria-controls="game-offcanvas"
                            >
                                { i18n.t("common.team") }
                            </button>
                        </div>
                        <div className="px-0" hidden={ team.level == null }>
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-right"
                                    viewBox="0 0 16 16">
                                    <path
                                        fillRule="evenodd"
                                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0" hidden={ team.level == null }>
                            <button
                                className="btn btn-sm border"
                            >{ i18n.t("level." + levelFromEnum(team.level)) }</button>
                        </div>
                    </div>
                    <div className="d-flex px-0" style={ { alignItems: "center" } }>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-return-right"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0">
                            <button
                                className="btn btn-sm btn-primary"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target={ "#payments-offcanvas-" + team.id }
                                aria-controls="game-offcanvas"
                            >
                                { i18n.t("common.payments") }
                            </button>
                        </div>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-right"
                                    viewBox="0 -1 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border"
                            >
                                { nbOfProcessedTicket(orders) } Ticket{ nbOfProcessedTicket(orders) > 1 ? "s" : "" }
                            </button>
                        </div>
                    </div>
                    <div className="px-0">
                        <div className="card">
                            <div className="card-body p-2">
                                <div className="row row-gap-2 rounded">
                                    <div className="col">{ currentUser(currentUserId) }</div>

                                    <div
                                        className="col-md-2 col-12 ps-md-0"
                                        hidden={ currentUserId !== context.user.userId }
                                    >
                                        <div className="row row-gap-2 h-100 m-0 p-0">
                                            <div
                                                className="card p-0"
                                                style={ { maxHeight: "100%" } }
                                                hidden={ !isMember(team, currentUserId) }
                                            >
                                                <div
                                                    className="card-body w-100 h-100 m-auto p-0"
                                                    style={ { alignSelf: "center" } }
                                                >
                                                    <button
                                                        className="btn btn-sm emergence h-100 w-100 px-1"
                                                        data-bs-toggle="offcanvas"
                                                        data-bs-target={ "#members-settings-offcanvas-" + team.id + "-" + currentUserId }
                                                        aria-controls="members-settings-offcanvas"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16" height="16"
                                                            fill="currentColor" className="bi bi-gear"
                                                            viewBox="0 0 16 16"
                                                            style={ { paddingTop: "2px" } }
                                                        >
                                                            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                                                            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                                                        </svg>{ "\t" }{ i18n.t("common.settings") }
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                className="card border-0 p-0"
                                                style={ { maxHeight: "100%" } }
                                                hidden={ !isMember(team, currentUserId) || anyEnableOrders(currentUserUsername) || isCaptain(team, currentUserId) }
                                            >
                                                <div
                                                    className="card-body w-100 h-100 m-auto p-0"
                                                    style={ { alignSelf: "center" } }
                                                >
                                                    { deleteMember(currentUserUsername) }
                                                </div>
                                            </div>
                                            { accept() }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <GameOffcanvas id={ "game-offcanvas-" + team.id } game={ event } users={ users }/>
        <PaymentsOffcanvas id={ "payments-offcanvas-" + team.id } users={ users } orders={ orders }/>

        <div
            id={ "members-offcanvas-" + team.id }
            className="offcanvas offcanvas-end"
            data-bs-scroll="false"
            tabIndex="-1"
        >
            <div className="offcanvas-header border-bottom shadow">
                <div className="row col-12 mx-0" style={ { height: "48px" } }>
                    <div className="col-6 ps-0">
                        <button
                            type="button"
                            className="btn btn-primary shadow-sm h-100 w-100"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            hidden={ !isCaptain(team, currentUserId) }
                            disabled={ team.status === "CANCELED" }
                            onClick={ onHandleTeamUpdate }
                        >
                            { i18n.t("button.update") }
                        </button>
                        <div className="card shadow-sm py-0" hidden={ isCaptain(team, currentUserId) }>
                            <div
                                className="card-body fs-6 fw-semibold py-0 px-1 text-center"
                                style={ { alignContent: "center", height: "46px" } }
                            >
                                { i18n.t("common.team") }
                            </div>
                        </div>
                    </div>
                    <div className="col-6 pe-0">
                        <button
                            type="button"
                            className="btn btn-warning shadow-sm h-100 w-100"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16"
                                fill="currentColor"
                                className="bi bi-x-lg" viewBox="0 0 16 16"
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="offcanvas-body">
                <div className="row row-gap-3 px-0 mx-0">

                    <div className="card p-0 shadow-sm" hidden={ isCaptain(team, context.user.userId) }>
                        <div className="card-body p-0">
                            <a
                                className="btn btn-primary px-2 py-3 w-100 h-100"
                                target="_blank"
                                href={ "sms:/" + captainPhoneNumber(team) }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-chat-dots"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2"/>
                                </svg>
                                { "\t" }{ i18n.t("common.send-message-to-captain") }
                            </a>
                        </div>
                    </div>

                    <div className="row px-0 mx-0">
                        <div className="col card ms-1" style={ { alignSelf: "center" } }></div>

                        <div
                            className="text-center small text-secondary px-2"
                            style={ { maxWidth: "fit-content" } }
                        >
                            { i18n.t("common.members") }
                        </div>

                        <div className="col card me-1" style={ { alignSelf: "center" } }></div>
                    </div>
                    {
                        team.members.map(
                            it => <React.Fragment key={ "members-" + it.username }>{ user(it.username) }</React.Fragment>
                        )
                    }
                    <div
                        className="row row-gap-3 px-0 mx-0"
                        hidden={ !team.requestMembers || team.requestMembers.length === 0 }
                    >
                        <div className="col card ms-1" style={ { alignSelf: "center" } }></div>

                        <div
                            className="text-center small text-secondary px-2"
                            style={ { maxWidth: "fit-content" } }
                        >
                            { i18n.t("common.on-request") }
                        </div>

                        <div className="col card me-1" style={ { alignSelf: "center" } }></div>
                    </div>
                    {
                        team.requestMembers.map(
                            it => <React.Fragment key={ "request-members-" + it }>{ onRequestUser(it) }</React.Fragment>
                        )
                    }
                </div>
            </div>
        </div>

        <div
            id={ "pay-modal-" + team.id }
            className="modal fade"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ i18n.t("common.hello-asso.payment-with") }</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            disabled={ disablePayment }
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row row-gap-3">
                            <span className="small">
                                { i18n.t("common.hello-asso.payment-disclaimer") }
                            </span>
                            <div>
                                <div className="text-center border rounded py-4 px-4">
                                    <label htmlFor="nbOfTicketRange" className="form-label">
                                        { i18n.t("common.book-for") } { nbOfTicketToPay } { i118nTPlayerWith(nbOfTicketToPay) } { basketPrice }
                                    </label>
                                    <input
                                        id="price-range"
                                        type="range"
                                        step="1"
                                        className="form-range"
                                        value={ nbOfTicketToPay }
                                        onChange={ onHandleTicketRange }
                                        min="1" max={ nbOfTicketToPaid(event, team, users, orders) }
                                        disabled={ isDisableTicketRange() }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer p-0"></div>
                    <ButtonHelloAssoPayment onClick={ onHandleCreateOrder } disabled={ disablePayment } />
                </div>
            </div>
        </div>

        <MembersSettingsOffcanvas
            id={ "members-settings-offcanvas-" + team.id + "-" + currentUserId }
            eventId={ event.id }
            teamId={ team.id }
            member={ team.members.find(it => it.userId === currentUserId) }
        />
    </div>
}