import i18n from 'i18next'
import React, { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { gameService } from "../service/game-service"
import Login from "./login"
import { recordService } from "../service/record-service"
import GameEditionCard from "../component/game-edition-card"
import { Context } from "../const/context"
import { disconnect, isNotConnected, record } from "../const/utils"
import { PActionButton } from "../component/action-button"
import moment from "moment"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    if (isNotConnected(context)) return <Login/>

    return <Games { ...props } navigate={ navigate } location={ location } context={ context }/>
}

class Games extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            archive: false,
            games: [],
            records: [],
            modalUrl: null
        }

        this.navigate = this.props.navigate

        this.onHandleArchive = this.onHandleArchive.bind(this)
        this.onHandleModalUrl = this.onHandleModalUrl.bind(this)
    }

    componentDidMount() { this.setGames() }

    setGames() {
        const now = moment()
        const startDate = !this.state.archive ? now.format('YYYY-MM-DD') : null
        const endDate = this.state.archive ? now.subtract(1, 'days').format('YYYY-MM-DD') : null

        gameService.getAll(
            null,
            null,
            [], null, null,
            startDate, endDate,
            null, null,
            [],
            [ this.props.context.user.userId ]
        ).then(async res => {

            if (res.status === 401) return disconnect(this.navigate, this.props.context, this.props.location.pathname)

            this.setState(
                () => ({ games: res }),
                async () => {
                    await recordService.search(
                        res.map(it => it.id), []
                    ).then(
                        records => this.setState(() => ({ records: records }))
                    )
                }
            )
        })
    }

    games() {
        return this.state.games.sort(
            (first, second) => moment(second.startDate, 'YYYY-MM-DD') - moment(first.startDate, 'YYYY-MM-DD')
        ).map(it => <div className="col-12 col-md-6 col-lg-4 mb-4 emergence" key={ it.id }>
                <GameEditionCard
                    game={ it }
                    record={ record(this.state.records, it.id) }
                    onHandleModalUrl={ this.onHandleModalUrl }
                />
            </div>
        )
    }

    onHandleArchive(_) {
        this.setState(
            (state, _) => ({ archive: !state.archive }),
            async () => this.setGames()
        )
    }

    onHandleModalUrl(url) {
        this.setState((state, _) => ({ modalUrl: url }))
    }

    render() {
        return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto">

            <div className="row border shadow-sm rounded px-3 py-2 mx-0">
                <div className="col-7 col-md-9 px-0 my-auto">
                    <p className="fs-2 m-0">{ i18n.t("gameCardEdition.title") }</p>
                </div>
                <div className="col-5 col-md-3 text-end m-auto px-0">
                    <div className="d-flex justify-content-end">
                        <PActionButton onClick={ () => { this.navigate("/game-edition") } }>
                            <div className="row">
                                <div className="col-1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17" height="17" fill="currentColor"
                                        className="bi bi-plus-circle"
                                        viewBox="0 -1 20 15"
                                    >
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                    </svg>
                                </div>
                                <div className="col px-1">{ i18n.t("gameCardEdition.newEvent") }</div>
                            </div>
                        </PActionButton>
                    </div>
                </div>
            </div>

            <div className="px-1 my-4">
                <div className="col card border-2" style={ { alignSelf: "center" } }></div>
            </div>

            <div
                className="d-flex justify-content-end border rounded py-2 px-3 mb-4"
                hidden={ this.state.games.length < 0 }
            >
                <div className="form-check form-switch form-check-reverse">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="archiveSwitchCheckReverse"
                        onChange={ this.onHandleArchive }
                        checked={ this.state.archive }
                    />
                    <label className="form-check-label" htmlFor="archiveSwitchCheckReverse">
                        { i18n.t("common.archive") }
                    </label>
                </div>
            </div>

            <div className="card shadow-sm emergence" hidden={ this.state.games.length > 0 }>
                <div className="card-body text-center fs-4">
                    { i18n.t("common.no-games-founded") }
                </div>
            </div>

            <div className="row">{ this.games() }</div>

            <div
                id="gameModal"
                className="modal fade"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="col">
                            <div>
                                <img
                                    src={ this.state.modalUrl }
                                    className="card-img-top"
                                    alt="tounament_image"
                                    style={ { borderTopRightRadius: "0.375rem", borderTopLeftRadius: "0.375rem" } }
                                />
                            </div>
                            <div style={ { position: "absolute", top: "8px", right: "8px" } }>
                                <button
                                    type="button"
                                    className="z-1 btn btn-lg btn-danger shadow-sm"
                                    data-bs-dismiss="modal"
                                    style={ { paddingBottom: "9px", paddingTop: "5px" } }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16" height="16"
                                        fill="currentColor"
                                        className="bi bi-x m-auto"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

