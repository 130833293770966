import "../css/auth-input.css"

import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { PActionButton } from "../component/action-button"
import { disconnect, formatPrice, refreshToken } from "../const/utils"
import Input from "../component/input"
import {
    closeModal,
    EMAIL_REGEX,
    LEVEL, levels, openModal, PHONE_REGEX, registerTeam,
} from "../const/common"
import ButtonHelloAssoPayment from "../component/button-hello-asso-payment"
import AuthModal from "../component/auth-modal"
import MailModal from "../component/mail-modal"
import { teamService } from "../service/team-service"
import { orderService } from "../service/order-service"
import moment from "moment"
import GameRegistrySummary from "../component/game-registry-summary"
import { notifierService } from "../service/notifier-service"
import { eventTransformer } from "../service/game-service"
import i18n from "i18next"

const MODAL_OPEN_ORDER = {
    1 : "ask-modal",
    2 : "pay-modal",
    3 : "mail-modal",
    4 : "auth-modal",
}

export default function GameRegistryAsGuest(props) {
    const guestScope = [ "CREATE_TEAM", "READ_ORDER" ]

    const navigate = useNavigate()
    const location = useLocation()

    const [ searchParams] = useSearchParams()

    const [ game, setGame ] = useState(props.game)

    const levelFrom = LEVEL[game.levelFrom]
    const levelTo = LEVEL[game.levelTo]
    const isDayOfEvent = moment(game.startDate, "YYYY-MM-DD") <= moment()

    const teamNameParam = searchParams.get("teamName")
    const firstnameParam = searchParams.get("firstname")
    const lastnameParam = searchParams.get("lastname")
    const phoneNumberParam = searchParams.get("phoneNumber")
    const emailParam = searchParams.get("email")
    const nbOfExtraMemberParam = searchParams.get("nbOfExtraMember")

    const [ actionButtonDisable, setActionButtonDisable ] = useState(true)

    const [ nbOfExtraMember, setNbOfExtraMember ] = useState(!nbOfExtraMemberParam ? 0 : parseInt(nbOfExtraMemberParam))

    const [ teamName, setTeamName ] = useState(!teamNameParam ? "" : teamNameParam)
    const [ firstname, setFirstname ] = useState(!firstnameParam ? "" : firstnameParam)
    const [ lastname, setLastname ] = useState(!lastnameParam ? "" : lastnameParam)
    const [ phoneNumber, setPhoneNumber ] = useState(!phoneNumberParam ? "" : phoneNumberParam)
    const [ email, setEmail ] = useState(!emailParam ? "" : emailParam)

    const [ level, setLevel] = useState("CHOOSE")
    const [ isOnlyLicensedPlayer, setIsOnlyLicensedPlayer ] = useState(false)

    const [ authId, setAuthId ] = useState(null)
    const [ isTimerOn, setIsTimerOn ] = useState(false)

    const [ modalOrder, setModalOrder ] = useState(0)

    const [ emittedTime, setEmittedTime ] = useState(null)

    useEffect(() => {

        const fetchNotifierTime = async () => {
            const res = await notifierService.notifierTime()

            setEmittedTime(res.data)
        }

        const fetchData = async () => {
            const res = await notifierService.notifier(emittedTime)

            const isUnAuthorized = res.status === 401 || res.status === 403 || res.httpCode === 403

            if (isUnAuthorized) return disconnect(navigate, context, location.pathname)
            else if (res.status > 500) return

            res.map(it => {
                setEmittedTime(it.emittedTime)

                const content = it.data
                const type = it.type

                if (type !== "GAME_UPDATED") return

                const eventGame = eventTransformer(content)

                if (game.id !== eventGame.id) return

                const registrationNotAllow = registerTeam(eventGame).length >= eventGame.nbOfTeam

                if (registrationNotAllow) return navigate("/home")

                setGame(eventGame)
            })
        }

        if (emittedTime == null) fetchNotifierTime().then()

        const timer = setInterval( () => { if (emittedTime != null) fetchData().then() }, 5000)

        return () => clearInterval(timer)
    }, [ emittedTime ])

    useEffect(() => {
        if (registerTeam(game).length >= game.nbOfTeam) return navigate("/home")
    }, [ game ])

    useEffect(() => {

        const isValidTeamName = teamName != null && teamName.length > 0

        const isValidFirstname = firstname != null && firstname.length > 0
        const isValidLastname = lastname != null && lastname.length > 0
        const isValidPhoneNumber = phoneNumber != null && phoneNumber.match(PHONE_REGEX)
        const isValidMail = email != null && email.toLowerCase().match(EMAIL_REGEX)

        const actionButtonDisable = !isValidTeamName || !isValidFirstname || !isValidLastname || !isValidMail || !isValidPhoneNumber

        setActionButtonDisable(actionButtonDisable)
    }, [
        teamName,
        firstname, lastname,
        phoneNumber,
        email
    ])

    const playerPrice = () => {

        if (game.dayOfEventPlayerPrice == null || game.dayOfEventPlayerPrice === "") return game.playerPrice

        return isDayOfEvent ? game.dayOfEventPlayerPrice : game.playerPrice
    }

    const basketPrice = () => {
        const price = (game.nbOfMember + nbOfExtraMember) * playerPrice()

        return formatPrice(price)
    }

    const teamPrice = () => {
        const price = game.nbOfMember * playerPrice()

        return formatPrice(price)
    }

    function render() {
        return <div className="row row-gap-3 mx-0">
            <GameRegistrySummary game={ game }/>

            <div className="card bg-info-subtle shadow-sm">
                <div className="card-body px-0">
                    <div className="d-flex justify-content-between">
                        <span>
                            { i18n.t("common.basket") }
                        </span>
                        <span>
                            { basketPrice() }
                        </span>
                    </div>
                </div>
            </div>

            <div className="card shadow-sm">
                <div className="card-body px-0">
                    <div className="d-flex justify-content-between">
                        <div>
                            <span className="bold">
                                { i18n.t("common.team-registration") }, { game.nbOfMember } { i18n.t("common.players").toLowerCase() }
                            </span>
                        </div>
                        <div>
                            <span className="bold">
                                { teamPrice() }
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow-sm" hidden={ game.nbOfExtraMember === 0 }>
                <div className="card-body px-0">
                    <div className="d-flex justify-content-between">
                        <div className="my-auto">
                            <span className="bold">
                                { i18n.t("common.additional-player") }
                            </span>
                        </div>
                        <div className="d-flex">
                            <button
                                className="btn btn-sm btn-secondary me-2 rounded-1"
                                onClick={ () => setNbOfExtraMember(nbOfExtraMember - 1) }
                                disabled={ nbOfExtraMember === 0 }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16"
                                    style={ { verticalAlign: "text-bottom" } }
                                >
                                    <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"/>
                                </svg>
                            </button>
                            <button
                                className="btn btn-sm active rounded-1"
                                disabled
                                style={ { minWidth: "42px" } }

                            >
                                <span className="mx-1 bold">{ nbOfExtraMember }</span>
                            </button>
                            <button
                                className="btn btn-sm btn-secondary ms-2 rounded-1"
                                disabled={ nbOfExtraMember >= game.nbOfExtraMember }
                                onClick={ () => setNbOfExtraMember(nbOfExtraMember + 1) }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-plus-lg" viewBox="0 0 16 16"
                                    style={ { verticalAlign: "text-bottom" } }
                                >
                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Input
                id="team-name"
                type="text"
                iconName="people"
                placeholder="teamName"
                label="Team Name*"
                value={ teamName }
                validator={ (value) => value.length > 0 }
                onHandleInput={ (id, value) => setTeamName(value) }
            />

            <div className="row row-gap-3 px-0 mx-0">
                <div className="col-12 col-md-5 pe-md-2 p-0">
                    <Input
                        id="firstname"
                        type="text"
                        iconName="firstname"
                        placeholder="firstname"
                        label="Firstname*"
                        value={ firstname }
                        validator={ (value) => value.length > 0 }
                        onHandleInput={ (id, value) => setFirstname(value) }
                    />
                </div>
                <div className="col-12 col-md-7 ps-md-2 p-0">
                    <Input
                        id="lastname"
                        type="text"
                        iconName="lastname"
                        placeholder="lastname"
                        label="Lastname*"
                        value={ lastname }
                        validator={ (value) => value.length > 0 }
                        onHandleInput={ (id, value) => setLastname(value) }
                    />
                </div>
            </div>

            <div className="row row-gap-3 px-0 mx-0">
                <div className="col-12 col-md-6 pe-md-2 p-0">
                    <Input
                        id="phoneNumber"
                        type="text"
                        iconName="phone"
                        placeholder="phoneNumber"
                        label="Phone number*"
                        value={ phoneNumber }
                        validator={ (value) => value.match(PHONE_REGEX) }
                        onHandleInput={ (id, value) => setPhoneNumber(value) }
                    />
                </div>
                <div className="col-12 col-md-6 ps-md-2 p-0">
                    <Input
                        id="mail"
                        type="mail"
                        iconName="mail"
                        placeholder="mail"
                        label="Mail*"
                        value={ email }
                        validator={ (value) => value.toLowerCase().match(EMAIL_REGEX) }
                        onHandleInput={ (id, value) => setEmail(value) }
                    />
                </div>
            </div>
        </div>
    }

    const reinitModalOrder = () => setModalOrder(0)

    const showModal = () => {
        let modalTarget = modalOrder

        if (modalTarget === 0 || modalTarget === 4) {
            closeModal(MODAL_OPEN_ORDER[modalTarget])
            setModalOrder(1)
            modalTarget = 1
            if (game.shouldValidateLevel || game.shouldAskPlayerLicensed) return openModal(MODAL_OPEN_ORDER[1])
        }
        if (modalTarget === 1) {
            closeModal(MODAL_OPEN_ORDER[modalTarget])
            setModalOrder(2)
            modalTarget = 2
            if (game.isPaymentAllow) return openModal(MODAL_OPEN_ORDER[2])
        }
        if (modalTarget === 2) {
            closeModal(MODAL_OPEN_ORDER[modalTarget])
            setModalOrder(3)
            modalTarget = 3
            return openModal(MODAL_OPEN_ORDER[3])
        }
        if (modalTarget === 3) {
            closeModal(MODAL_OPEN_ORDER[modalTarget])
            setModalOrder(4)
            setIsTimerOn(true)
            return openModal(MODAL_OPEN_ORDER[4])
        }
    }

    const createTeam = async (authCode) => {

        const teamNameParam = "teamName=" + teamName
        const firstnameParam = "firstname=" + firstname
        const lastnameParam = "lastname=" + lastname
        const phoneNumberParam = "phoneNumber=" + phoneNumber
        const emailParam = "email=" + email
        const nbOfExtraMemberParam = "nbOfExtraMember=" + nbOfExtraMember

        const and = "&"

        const registryParam = teamNameParam + and + firstnameParam + and + lastnameParam + and + phoneNumberParam + and + emailParam + and + nbOfExtraMemberParam

        const backUrl = process.env.BASE_HA_REDIRECT_URL + location.pathname + "?status=back" + and + registryParam
        const errorUrl = backUrl + "?status=payment-failed" + and + registryParam
        const returnUrl = process.env.BASE_HA_REDIRECT_URL + "/success?message=success.team-created"

        const levelFormatted = level === "CHOOSE" ? null : level

        const res = await teamService.createAsGuest(
            authId,
            authCode,
            game.id,
            teamName,
            email,
            firstname,
            lastname,
            phoneNumber,

            nbOfExtraMember,
            levelFormatted,
            backUrl, errorUrl, returnUrl
        )

        closeModal("auth-modal")

        if (res.error != null || res.httpCode != null) return
        if (!game.isPaymentAllow) return navigate("/success?message=success.team-created")

        const orderId = res.captain.orderId

        if (orderId == null) return

        const orderRes = await orderService.getByIdAsGuest(authId, authCode, orderId)

        if (orderRes.error != null || orderRes.httpCode != null) return

        window.location.href = orderRes.payment.paymentUrl
    }

    const actionButton = (disabled) => {

        if (disabled) return <PActionButton disabled={ true }>
            <div className="row">
                <div className="col-1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16" height="16"
                        fill="currentColor"
                        className="bi bi-ticket-perforated-fill"
                        viewBox="0 -1 16 16"
                        style={ { verticalAlign: "text-bottom" } }
                    >
                        <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6zm4-1v1h1v-1zm1 3v-1H4v1zm7 0v-1h-1v1zm-1-2h1v-1h-1zm-6 3H4v1h1zm7 1v-1h-1v1zm-7 1H4v1h1zm7 1v-1h-1v1zm-8 1v1h1v-1zm7 1h1v-1h-1z"/>
                    </svg>
                </div>
                <div className="col px-1">{ i18n.t("common.register") }</div>
            </div>
        </PActionButton>

        return <div className="d-flex justify-content-end" style={ { padding: "2px" } }>
            <PActionButton onClick={ showModal }>
                <div className="row">
                    <div className="col-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16"
                            fill="currentColor"
                            className="bi bi-ticket-perforated-fill"
                            viewBox="0 -1 16 16"
                            style={ { verticalAlign: "revert-layer" } }
                        >
                            <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6zm4-1v1h1v-1zm1 3v-1H4v1zm7 0v-1h-1v1zm-1-2h1v-1h-1zm-6 3H4v1h1zm7 1v-1h-1v1zm-7 1H4v1h1zm7 1v-1h-1v1zm-8 1v1h1v-1zm7 1h1v-1h-1z"/>
                        </svg>
                    </div>
                    <div className="col px-1">{ i18n.t("common.register") }</div>
                </div>
            </PActionButton>
        </div>
    }

    return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto p-0 pb-3">

        <div className="row border rounded shadow-sm px-3 py-2 mx-0 mb-3">
            <div className="col-7 col-md-9 px-0 my-auto">
                <p className="fs-2 m-0">{ i18n.t("common.reserve-your-place") }</p>
            </div>
            <div className="col-5 col-md-3 text-end m-auto px-0">
                <div className="d-flex justify-content-end">
                    { actionButton(actionButtonDisable) }
                </div>
            </div>
        </div>

        <div className="px-1 my-4">
            <div className="col card border-2" style={ { alignSelf: "center" } }></div>
        </div>

        { render() }

        <div
            className="modal fade"
            data-bs-keyboard="false"
            id="ask-modal"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h5 className="modal-title">{ i18n.t("common.finalizing-registration") }</h5>
                        <button
                            className="btn-close"
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={ reinitModalOrder }
                        ></button>
                    </div>
                    <div className="modal-body overflow-x-hidden px-0 py-0">
                        <ul className="list-group list-group-flush">
                            <div className="row row-gap-2">
                                <li className="list-group-item py-3 px-4" hidden={ !game.shouldValidateLevel }>
                                    <div>
                                        <select
                                            className="form-select"
                                            aria-label="level-select-from"
                                            value={ level }
                                            onChange={ (e) => setLevel(e.target.value) }
                                        >
                                            <option defaultValue value={ "CHOOSE" }>{ i18n.t("common.team-level") }</option>
                                            { levels(levelFrom, levelTo) }
                                        </select>
                                    </div>
                                </li>
                                <li className="list-group-item pb-3 px-4" hidden={ !game.shouldAskPlayerLicensed }>
                                    <div className="border rounded p-4">
                                        <div className="form-check">
                                            <input
                                                id="only-licensed-player-checkbox"
                                                className="form-check-input"
                                                type="checkbox"
                                                value={ isOnlyLicensedPlayer }
                                                onChange={ () => setIsOnlyLicensedPlayer(!isOnlyLicensedPlayer) }
                                            />
                                            <label
                                                className="form-check-label small"
                                                htmlFor="only-licensed-player-checkbox"
                                            >
                                                { i18n.t("common.license-ask") }
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                    <div className="modal-footer p-0"></div>
                    <button
                        className="btn btn-primary rounded-top-0 w-100 h-100"
                        onClick={ showModal }
                        disabled={
                            (game.shouldValidateLevel && level === "CHOOSE") || (game.shouldAskPlayerLicensed && !isOnlyLicensedPlayer)
                        }
                    >
                        { i18n.t("common.continue") }
                    </button>
                </div>
            </div>
        </div>

        <div
            id="pay-modal"
            className="modal fade"
            data-bs-keyboard="false"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ i18n.t("common.payment-via-helloasso") }</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={ reinitModalOrder }
                        ></button>
                    </div>
                    <div className="modal-body">
                        <span className="small">{ i18n.t("common.helloass-business-model") }.</span>
                    </div>
                    <div className="modal-footer p-0"></div>
                    <ButtonHelloAssoPayment onClick={ showModal }/>
                </div>
            </div>
        </div>

        <MailModal
            mail={ email }
            scopes={ guestScope }
            setAuthId={ setAuthId }
            nextAction={ showModal }
            close={ reinitModalOrder }
        />
        <AuthModal
            isTimerOn={ isTimerOn }
            setIsTimerOn={ setIsTimerOn }
            nextAction={ createTeam }
            close={ reinitModalOrder }
        />
    </div>
}