import React, { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from "../const/context"
import { disconnect, isAdmin, isNotConnected } from "../const/utils"
import Login from "./login"
import Games from "./games"
import i18n from "i18next"
import { mediaService } from "../service/media-service"
import moment from "moment"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    const state = location.state

    if (isNotConnected(context)) return <Login/>
    if (!isAdmin(context)) return <Games/>

    const isEmptyState = state == null

    if (isEmptyState || state.game == null) return <Games/>

    const game = state.game

    return <SocialNetwork
        { ...props }
        navigate={ navigate }
        location={ location }
        context={ context }
        game={ game }
    />
}

class SocialNetwork extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            medias: [],
            content: "",
            isFBSelected: true,
            isIGSelected: true
        }

        this.gameId = this.props.game.id

        this.navigate = props.navigate

        this.getAll = this.getAll.bind(this)

        this.onHandleContent = this.onHandleContent.bind(this)
        this.onHandleFacebook = this.onHandleFacebook.bind(this)
        this.onHandleInstagram = this.onHandleInstagram.bind(this)

        this.send = this.send.bind(this)
        this.sendAsUserAdmin = this.sendAsUserAdmin.bind(this)
        this.sendAsDefaultUser = this.sendAsDefaultUser.bind(this)
    }

    componentDidMount() { this.getAll() }

    getAll() {
        mediaService.getAll(
            "event",
            this.props.game.id
        ).then(
            res => {
                if (res.status === 401) return disconnect(this.navigate, this.props.context, "/games")

                this.setState((state, _) => ({ medias: res }))
            }
        )
    }

    onHandleContent(e) {
        this.setState((state, _) => ({ content: e.target.id }))
    }

    onHandleFacebook(e) {
        this.setState((state, _) => ({ isFBSelected: !state.isFBSelected }))
    }

    onHandleInstagram(e) {
        this.setState((state, _) => ({ isIGSelected: !state.isIGSelected }))
    }

    async send() {
        if (this.props.context.user.isAdmin) return this.sendAsUserAdmin()

        return this.sendAsDefaultUser()
    }

    async sendAsUserAdmin() {
        const isFBSelected = this.state.isFBSelected
        const isIGSelected = this.state.isIGSelected

        const content = this.state.content

        if (isFBSelected && isIGSelected) return await this.sendBy(["FACEBOOK", "INSTAGRAM"], content)
        if (isFBSelected) return await this.sendBy(["FACEBOOK"], content)
        if (isIGSelected) return await this.sendBy(["INSTAGRAM"], content)
    }

    async sendAsDefaultUser() {
        const content = this.state.content

        const selectedContent = this.state.medias.filter(it => it.content === content)

        const isFBSelected = this.state.isFBSelected && selectedContent.filter(it => it.networks.includes("FACEBOOK")).length === 0
        const isIGSelected = this.state.isIGSelected && selectedContent.filter(it => it.networks.includes("INSTAGRAM")).length === 0

        if (isFBSelected && isIGSelected) return await this.sendBy(["FACEBOOK", "INSTAGRAM"], content)
        if (isFBSelected) return await this.sendBy(["FACEBOOK"], content)
        if (isIGSelected) return await this.sendBy(["INSTAGRAM"], content)
    }

    async sendBy(networks, content) {
        const res = await mediaService.create("event", this.gameId, networks, content)

        if (res.status === 401) return disconnect(this.navigate, this.props.context, "/games")

        if (res.error != null || res.httpCode != null) return

        const medias = this.state.medias

        medias.push(res)

        this.setState((state, _) => ({ medias: medias }))
    }

    isVisible(content) {
        const mediaByContent = this.state.medias.filter(it => it.content === content)

        const anyMediaContainsAll = mediaByContent.filter(
            it => it.networks.includes("FACEBOOK") && it.networks.includes("INSTAGRAM")
        )

        if (0 < anyMediaContainsAll.length) return false

        const mediaContainsFB = mediaByContent.filter(it => it.networks.includes("FACEBOOK")) !== 0
        const mediaContainsIG = mediaByContent.filter(it => it.networks.includes("INSTAGRAM")) !== 0

        return mediaContainsFB && mediaContainsIG
    }

    icons(media) {
        return media.networks.sort().map(
            it => <React.Fragment key={ it }>{ this.icon(it) }</React.Fragment>
        )
    }

    icon(network) {
        if (network === "FACEBOOK") return <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16" height="16"
            fill="currentColor"
            className="bi bi-facebook mx-2 mt-1" viewBox="0 0 16 16"
        >
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
        </svg>
        else if (network === "INSTAGRAM") return <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17" height="17"
            fill="currentColor"
            className="bi bi-instagram mx-2 mt-1" viewBox="0 0 16 16"
        >
            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
        </svg>
        else return <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16" height="16"
                fill="currentColor"
                className="bi bi-threads mx-2 mt-1" viewBox="0 0 16 16"
            >
                <path d="M6.321 6.016c-.27-.18-1.166-.802-1.166-.802.756-1.081 1.753-1.502 3.132-1.502.975 0 1.803.327 2.394.948s.928 1.509 1.005 2.644q.492.207.905.484c1.109.745 1.719 1.86 1.719 3.137 0 2.716-2.226 5.075-6.256 5.075C4.594 16 1 13.987 1 7.994 1 2.034 4.482 0 8.044 0 9.69 0 13.55.243 15 5.036l-1.36.353C12.516 1.974 10.163 1.43 8.006 1.43c-3.565 0-5.582 2.171-5.582 6.79 0 4.143 2.254 6.343 5.63 6.343 2.777 0 4.847-1.443 4.847-3.556 0-1.438-1.208-2.127-1.27-2.127-.236 1.234-.868 3.31-3.644 3.31-1.618 0-3.013-1.118-3.013-2.582 0-2.09 1.984-2.847 3.55-2.847.586 0 1.294.04 1.663.114 0-.637-.54-1.728-1.9-1.728-1.25 0-1.566.405-1.967.868ZM8.716 8.19c-2.04 0-2.304.87-2.304 1.416 0 .878 1.043 1.168 1.6 1.168 1.02 0 2.067-.282 2.232-2.423a6.2 6.2 0 0 0-1.528-.161"/>
        </svg>
    }

    medias() {
        return this.state.medias.sort(
            (first, second) => new Date(second.createdDate) - new Date(first.createdDate)
        ).map(it =>
            <div className="modal-content rounded-2 border shadow-sm fw-medium p-4 my-4" key={ it.id }>
                <div className="row row-gap-3 p-0">
                    <div className="row m-auto p-0">
                        <div className="col-6 text-start">
                            <div className="d-flex flex-row">
                                <div>{ i18n.t("social-network.network") }</div>
                                <div className="d-flex flex-row ms-2">{ this.icons(it) }</div>
                            </div>
                        </div>
                        <div className="col-6 text-end">
                            { moment(it.createdDate).locale(i18n.language).format("YYYY-MM-DD HH:mm") }
                        </div>
                    </div>
                    <div className="col-12">{ i18n.t("social-network." + it.content.toLowerCase()) }</div>
                </div>
            </div>
        )
    }

    noticeChoose() {
        const noticeChoose = [
            "DEFAULT",
            "REMINDER",
            "FULL",
            "CAPACITY"
        ].filter(
            it => this.props.context.user.isAdmin || this.isVisible(it)
        ).map(it => <React.Fragment key={ it }>
            <input
                type="radio"
                className="btn-check"
                name="options-base"
                id={ it }
                autoComplete="off"
                checked={ this.state.content === it }
                onChange={ this.onHandleContent }
            />
            <label
                className="btn btn-sm m-2"
                htmlFor={ it }
            >
                { i18n.t("social-network." + it.toLowerCase()) }
            </label>
        </React.Fragment>)

        if (noticeChoose.length === 0) return <div className="col-12 text-center">
            <span className="fs-5">
                { i18n.t("social-network.too-many-publication") }
            </span>
        </div>

        return noticeChoose
    }

    render() {
        return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto p-0 mb-3">
            <div className="row border shadow-sm rounded px-3 py-2 mx-0 mb-3">
                <div className="col-6 col-md-9 px-0">
                    <p className="fs-2 m-0">{ i18n.t("social-network.title") }</p>
                </div>
                <div className="col-6 col-md-3 text-end m-auto px-0">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-warning shadow-sm"
                            onClick={ () => { this.navigate(-1) } }
                        >
                            <div className="row">
                                <div className="col-1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17" height="17"
                                        fill="currentColor"
                                        className="bi bi-arrow-left-circle"
                                        viewBox="-2 0 20 15"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                        />
                                    </svg>
                                </div>
                                <div className="col px-1">{ i18n.t("button.back") }</div>
                            </div>
                        </button>
                        <button
                            className="btn btn-primary shadow-sm ms-2 px-2"
                            data-bs-toggle="modal"
                            data-bs-target="#socialNetworkModal"
                            data-bs-whatever="@mdo"
                            disabled={ this.props.game.status !== "ENABLE" }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17" height="17"
                                fill="currentColor"
                                className="bi bi-plus-circle"
                                viewBox="-2 0 20 15"
                            >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div className="px-1 my-4">
                <div className="col card border-2" style={ { alignSelf: "center" } }></div>
            </div>

            { this.medias() }

            <div
                id="socialNetworkModal"
                className="modal fade"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="row row-gap-4">

                            <div className="col-12 px-4 pt-3">

                                { this.noticeChoose() }

                                {/*<input*/ }
                                {/*    type="radio"*/ }
                                {/*    className="btn-check"*/ }
                                {/*    name="options-base"*/ }
                                {/*    id="MESSAGE"*/ }
                                {/*    autoComplete="off"*/ }
                                {/*    checked={ this.state.type === "MESSAGE" }*/ }
                                {/*    onChange={ this.onHandleType }*/ }
                                {/*    disabled*/ }
                                {/*/>*/ }
                                {/*<label className="btn btn-sm" htmlFor="MESSAGE">Custom message</label>*/ }
                            </div>

                            <div className="col-12 px-4" hidden={ !isAdmin(this.props.context) }>
                                <div className="row">
                                    <div className="col-6">
                                        <button
                                            className={ "btn col-12 " + (this.state.isIGSelected ? "btn-danger" : "active") }
                                            onClick={ this.onHandleInstagram }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16" height="16"
                                                fill="currentColor"
                                                className="bi bi-instagram ms-2"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                                            </svg>{ "\t" }Instagram
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button
                                            className={ "btn col-12 " + (this.state.isFBSelected ? "btn-primary" : "active") }
                                            onClick={ this.onHandleFacebook }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16" height="16"
                                                fill="currentColor"
                                                className="bi bi-facebook"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                                            </svg>{ "\t" }Facebook
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="row mx-0">
                                    <div className="col-6 mx-0 px-0">
                                        <button
                                            type="button"
                                            className="btn btn-warning rounded-top-0 rounded-end-0 col-12"
                                            data-bs-dismiss="modal"
                                        >{ i18n.t("button.cancel") }</button>
                                    </div>
                                    <div className="col-6 mx-0 px-0">
                                        <button
                                            type="button"
                                            className="btn btn-secondary rounded-top-0 rounded-start-0 col-12"
                                            data-bs-dismiss="modal"
                                            onClick={ this.send }
                                            disabled={ this.state.content === "" || (!this.state.isFBSelected && !this.state.isIGSelected) }
                                        >{ i18n.t("button.send") }</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}