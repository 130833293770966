import "../css/imput-game-edition.scss"
import i18n from "i18next"

import React from "react"
import moment from "moment"
import {
    COUNTRY,
    EMAIL_REGEX,
    PHONE_REGEX,
    REGION,
    country,
    regionByCountry,
    WHAT_S_APP_GROUP_LINK_REGEX
} from "../const/common"
import InputCalendar from "./input-calendar"
import {
    notEmptyFieldValidator,
    emptyableMailValidator,
    emptyablePhoneNbValidator,
    emptyableWhatsAppGroupLink
} from "../const/utils"
import { geoLocService } from "../service/geo-loc-service"
import { InputTime } from "./input-time"
import Input from "./input"

export class InputGameEdition extends React.Component {

    constructor(props) {
        super(props);

        const country = props.region !== "CHOOSE" ? REGION.filter(it => it.key === props.region)[0].country : "CHOOSE"

        this.state = {
            name: props.name,
            address: {
                isValid: true,
                value: props.address
            },
            country: country,
            region: { isValid: true, value: props.region },
            price: props.price,
            phoneNumber: props.phoneNumber,
            mail: props.mail,
            whatsAppGroupLink: props.whatsAppGroupLink != null ? props.whatsAppGroupLink : "",
            startDate: { isValid: true, value: props.startDate },
            startTime: props.startTime,
            endTime: props.endTime,

            geoLocFeatures: []
        }

        this.onHandleCountryFieldDelete = this.onHandleCountryFieldDelete.bind(this)
        this.onHandleRegionFieldDelete = this.onHandleRegionFieldDelete.bind(this)

        this.onHandleAddressDelete = this.onHandleAddressDelete.bind(this)
        this.onHandleAddress = this.onHandleAddress.bind(this)
        this.onSelectAddress = this.onSelectAddress.bind(this)

        this.onHandleCountry = this.onHandleCountry.bind(this)
        this.onHandleRegion = this.onHandleRegion.bind(this)

        this.onDateChange = this.onDateChange.bind(this)
        this.onStartTimeChange = this.onStartTimeChange.bind(this)
        this.onEndTimeChange = this.onEndTimeChange.bind(this)

        this.onHandleInput = this.onHandleInput.bind(this)

        this.onHandleTextInput = this.onHandleTextInput.bind(this)
    }

    isValid(key) {
        if (this.state[key].isValid) return ""
        else return "is-invalid"
    }

    onHandleInput() {
        const name = this.state.name
        const address = this.state.address.value
        const region = this.state.region.value
        const price = this.state.price != null && this.state.price !== "" ? this.state.price : ""
        const phoneNumber = this.state.phoneNumber
        const mail = this.state.mail
        const whatsAppGroupLink = this.state.whatsAppGroupLink
        const startDate = this.state.startDate.value
        const startTime = this.state.startTime
        const endTime = this.state.endTime

        const isValidName = name !== ""
        const isValidAddress = address !== ""
        const isValidRegion = region != null && region !== "CHOOSE"
        const isValidPrice = price !== ""
        const isValidPhone = phoneNumber === undefined || phoneNumber === "" || phoneNumber.replace(/\s/g, '').match(PHONE_REGEX)
        const isValidMail = mail === undefined || mail === "" || mail.toLowerCase().match(EMAIL_REGEX)
        const isValidWhatsAppGroupLink = whatsAppGroupLink === undefined || whatsAppGroupLink === "" || whatsAppGroupLink.match(WHAT_S_APP_GROUP_LINK_REGEX)
        const isValidStartDate = startDate != null

        const isValid = isValidName && isValidAddress && isValidRegion && isValidPrice && isValidPhone && isValidMail && isValidWhatsAppGroupLink && isValidStartDate

        this.props.onHandleInput({
            name: name,
            address: address,
            region: region,
            price: price,
            phoneNumber: phoneNumber,
            mail: mail,
            whatsAppGroupLink: whatsAppGroupLink === "" ? null : whatsAppGroupLink,
            startDate: startDate,
            startTime: startTime,
            endTime: endTime,
            isValid: isValid
        })
    }

    onHandleCountryFieldDelete(_) {
        this.setState(
            (state, _) => ( { country: "CHOOSE", region: { isValid: false, value: "CHOOSE" } } ),
            () => this.onHandleInput()
        )
    }

    onHandleRegionFieldDelete(_) {
        this.setState(
            (state, _) => ( { region: { isValid: false, value: "CHOOSE" } } ),
            () => this.onHandleInput()
        )
    }

    onHandleAddressDelete(_) {
        this.setState(
            (state, _) => ( { address: { isValid: false, value: "" } } ),
            () => this.onHandleInput()
        )
    }

    onHandleAddress(e) {
        const keyword = e.target.value

        this.setState(
            (state, _) => ({ address: { isValid: notEmptyFieldValidator(keyword), value: keyword } }),
            async () => {
                const res = keyword.trim().length < 3 ? [] : await geoLocService.search(keyword)

                const geoLocFeatures = res != null && res.features != null ? res.features : []

                this.setState(
                    (state, _) => ({ geoLocFeatures: geoLocFeatures }),
                    () => this.onHandleInput()
                )
            }
        )
    }

    onSelectAddress(
        name,
        postCode,
        city,
        context
    ) {
        const regionValue = context.split(",")[2].slice().substring(1).normalize("NFD").replace(
            /\p{Diacritic}/gu, ""
        ).replaceAll(
            " ", "_"
        ).replaceAll(
            "'", "_"
        ).replaceAll(
            "-", "_"
        ).toUpperCase()

        const region = REGION.filter(it => it.key === regionValue)[0]

        const isValid = region != null

        const country = isValid ? region.country : "CHOOSE"

        const address = name + ", " + postCode + " " + city

        this.setState(
            (state, _) => (
                {
                    address: { isValid: notEmptyFieldValidator(address), value: address },
                    country: country, region: { isValid: isValid, value: regionValue }
                }
            ),
            () => this.onHandleInput()
        )
    }

    onHandleCountry(e) {
        const value = e.target.value

        const isValid = COUNTRY.filter(it => it.key === value).length !== 0

        const region = isValid ? REGION.filter(
            it => this.state.region === it.key && value === it.country
        )[0] : "CHOOSE"

        this.setState(
            (state, _) => ( { country: value, region: { isValid: isValid, value: region } } ),
            () => this.onHandleInput()
        )
    }

    onHandleRegion(e) {
        const value = e.target.value

        const region = REGION.filter(it => it.key === value)[0]

        const isValid = region != null

        const country = isValid ? region.country : "CHOOSE"

        this.setState(
            (state, _) => ( { country: country, region: { isValid: isValid, value: value } } ),
            () => this.onHandleInput()
        )
    }

    onDateChange(date) {
        const isValidDate = date !== undefined && date != null

        this.setState(
            (state, _) => ( { startDate: { isValid: isValidDate, value: date } } ),
            () => this.onHandleInput()
        )
    }

    onStartTimeChange(time) {
        this.setState(
            (state, _) => ( { startTime: time } ),
            () => this.onHandleInput()
        )
    }

    onEndTimeChange(time) {
        this.setState(
            (state, _) => ( { endTime: time } ),
            () => this.onHandleInput()
        )
    }

    onHandleTextInput(id, value) {
        this.setState(
            (state, _) => ( { [id]: value } ),
            () => this.onHandleInput()
        )
    }

    datePickup() {
        const numberOfMonths = innerWidth < 992 ? 1 : 2

        return <div style={ { whiteSpace: "pre-wrap" } }>
            <InputCalendar
                numberOfMonths={ numberOfMonths }
                isOutsideRange={ day => (day < moment()) }
                initialDate={ this.state.startDate.value }
                onDateChange={ this.onDateChange }
            />
        </div>
    }

    featuresSuggestion() {
        return this.state.geoLocFeatures.map(it => {
            const properties = it.properties
            const id = properties.id
            const name = properties.name
            const postCode = properties.postcode
            const city = properties.city
            const context = properties.context

            return <button
                key={ id }
                className="btn btn btn-outline-secondary col-12"
                onClick={ () => this.onSelectAddress(name, postCode, city, context) }
                data-bs-dismiss="modal"
            >
                <div className="d-flex">{ name }</div>
                <div
                    className="d-flex fst-italic"
                    style={ { fontSize: "smaller" } }
                >
                    { postCode }, { city }
                </div>
            </button>
        })
    }

    render() {
        return <div className="row row-gap-3">

            <div className="row row-gap-3 m-0 p-0">
                <div className="col-lg-8 col-md-12 col-sm-12 pe-lg-0">
                    <Input
                        id="name"
                        type="text"
                        iconName="name"
                        placeholder={ i18n.t("gameEdition.name") + "*" }
                        validator={ notEmptyFieldValidator }
                        onHandleInput={ this.onHandleTextInput }
                        value={ this.state.name }
                    />
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <Input
                        id="price"
                        type="number"
                        iconName="price"
                        placeholder={ i18n.t("gameEdition.price") + " / " + i18n.t("gameEdition.player") + "*" }
                        validator={ notEmptyFieldValidator }
                        onHandleInput={ this.onHandleTextInput }
                        value={ this.state.price }
                        disabled={ this.props.onRegistration }
                    />
                </div>
            </div>

            <div className="row row-gap-3 m-0 p-0">
                <div className="col-lg-5 col-md-12 col-sm-12">
                    <Input
                        id="phoneNumber"
                        type="text"
                        iconName="phone"
                        placeholder={ i18n.t("gameEdition.phoneNumber") }
                        validator={ emptyablePhoneNbValidator }
                        onHandleInput={ this.onHandleTextInput }
                        value={ this.state.phoneNumber }
                    />
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12 ps-lg-0">
                    <Input
                        id="mail"
                        type="email"
                        iconName="mail"
                        placeholder={ i18n.t("gameEdition.mail") }
                        validator={ emptyableMailValidator }
                        onHandleInput={ this.onHandleTextInput }
                        value={ this.state.mail }
                    />
                </div>
            </div>

            <div className="col-md-12 col-sm-12">
                <Input
                    id="whatsAppGroupLink"
                    type="text"
                    iconName="whatsapp"
                    placeholder={ i18n.t("common.whats-app-group-link") }
                    validator={ emptyableWhatsAppGroupLink }
                    onHandleInput={ this.onHandleTextInput }
                    value={ this.state.whatsAppGroupLink }
                />
            </div>

            <div className="col-12">
                <div className="input-group shadow-sm rounded">
                    <div className="form-floating">
                        <input
                            id="address"
                            type="text"
                            className={ "form-control " + this.isValid("address") }
                            placeholder={ i18n.t("gameEdition.address") + "*" }
                            value={ this.state.address.value }
                            onChange={ () => {} }
                            data-bs-toggle="modal" data-bs-target="#address-modal"
                            autoComplete="off"
                        />
                        <label htmlFor="floatingInput">{ i18n.t("gameEdition.address") + "*" }</label>
                    </div>
                    <div className="input-group-text p-0">
                        <button
                            type="button"
                            className="btn rounded-start-0 p-1"
                            style={ { width: "32px", height: "56px" } }
                            onClick={ this.onHandleAddressDelete }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-x"
                                viewBox="0 0 16 16"
                                style={ { pointerEvents: "none" } }
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row row-gap-3 m-0 p-0">
                <div className="col-lg-5 col-md-12 col-sm-12">
                    <div className="input-group shadow-sm rounded">
                        <div className="input-group">
                            <label className="input-group-text ps-2 pe-2" htmlFor="inputGroupSelect01">
                                { i18n.t("common.country") }
                            </label>
                            <select
                                id="regionInputGroup"
                                className={ "form-select form-select" }
                                value={ this.state.country }
                                style={ { height: "58px" } }
                                onChange={ this.onHandleCountry }
                            >
                                { country() }
                            </select>
                            <div className="input-group-text p-0">
                                <button
                                    id="deleteField-country"
                                    type="button"
                                    className="btn rounded-start-0 p-1"
                                    style={ { width: "32px", height: "56px" } }
                                    onClick={ this.onHandleCountryFieldDelete }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-x"
                                        viewBox="0 0 16 16"
                                        style={ { pointerEvents: "none" } }
                                    >
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12 ps-lg-0">
                    <div className="input-group shadow-sm rounded">
                        <label className="input-group-text ps-2 pe-2" htmlFor="inputGroupSelect01">
                            { i18n.t("gameEdition.region") }*
                        </label>
                        <select
                            id="regionInputGroup"
                            className={ "form-select form-select " + this.isValid("region") }
                            value={ this.state.region.value }
                            style={ { height: "58px" } }
                            onChange={ this.onHandleRegion }
                        >
                            { regionByCountry(this.state.country) }
                        </select>
                        <div className="input-group-text p-0">
                            <button
                                id="deleteField-region"
                                type="button"
                                className="btn rounded-start-0 p-1"
                                style={ { width: "32px", height: "56px" } }
                                onClick={ this.onHandleRegionFieldDelete }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-x"
                                    viewBox="0 0 16 16"
                                    style={ { pointerEvents: "none" } }
                                >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-center">
                { this.datePickup() }
            </div>

            <div className="row p-0 m-0">
                <div className="col-6 pe-md-1">
                    <InputTime
                        label={ i18n.t("gameEdition.start-at") }
                        initialValue={ this.state.startTime }
                        minValue={ 1 }
                        onChange={ this.onStartTimeChange }
                    />
                </div>
                <div className="col-6 ps-md-1">
                    <InputTime
                        label={ i18n.t("gameEdition.end-at") }
                        initialValue={ this.state.endTime }
                        minValue={ 1 }
                        onChange={ this.onEndTimeChange }
                    />
                </div>
            </div>

            <div className="modal fade" id="address-modal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="input-group shadow-sm rounded">
                                <div className="form-floating">
                                    <input
                                        id="address"
                                        type="text"
                                        className={ "form-control " + this.isValid("address") }
                                        placeholder={ i18n.t("gameEdition.address") + "*" }
                                        value={ this.state.address.value }
                                        onChange={ this.onHandleAddress }
                                        autoComplete="off"
                                    />
                                    <label htmlFor="floatingInput">{ i18n.t("gameEdition.address") + "*" }</label>
                                </div>
                                <div className="input-group-text p-0">
                                    <button
                                        type="button"
                                        className="btn rounded-start-0 p-1"
                                        style={ { width: "32px", height: "56px" } }
                                        onClick={ this.onHandleAddressDelete }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-x"
                                            viewBox="0 0 16 16"
                                            style={ { pointerEvents: "none" } }
                                        >
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body" style={ { height: "250px" } }>
                            <div className="row row-gap-3 m-0">
                                { this.featuresSuggestion() }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-warning shadow-sm col-12 m-auto"
                                data-bs-dismiss="modal"
                            >{ i18n.t("button.save") }</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}