import indoor from "../../public/indoor.png"
import parc from "../../public/parc.png"
import beachVolley from "../../public/beach-volley.png"
import sea from "../../public/sea.png"
import mountain from "../../public/mountain.png"
import france from "../../public/france.png"
import belgium from "../../public/belgium.png"

import i18n from "i18next"
import React from "react"
import man from "../../public/man.png"
import woman from "../../public/woman.png"
import captainMale from "../../public/captainMale.png"
import captainFemale from "../../public/captainFemale.png"
import organization from "../../public/organization.png"
import transgender from "../../public/transgender.png"
import { disconnect, infoToast } from "./utils"
import captainAmerica from "../../public/captain-america.png"
import copy from "copy-to-clipboard"

export const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i
export const PHONE_REGEX = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i
export const WHAT_S_APP_GROUP_LINK_REGEX = /^(https?:\/\/)?chat\.whatsapp\.com\/(?:invite\/)?([a-zA-Z0-9_-]{22})$/i

export const DATE_FORMAT = "YYYY-MM-DD"

export const NESTED_NAV_LINK_STYLE = {
    paddingRight: "var(--bs-navbar-nav-link-padding-x)",
    paddingLeft: "var(--bs-navbar-nav-link-padding-x)"
}

export const INVERSE_COLOR = {
    primary: "warning",
    warning: "primary",
    success: "warning",
    danger: "primary"
}

export const GROUND = [
    {
        name: "INDOOR",
        key: "INDOOR-VOLLEY",
        value: "Indoor",
        image: indoor
    },
    {
        name: "GREEN",
        key: "GREEN-VOLLEY",
        value: "Green",
        image: parc
    },
    {
        name: "BEACH",
        key: "BEACH-VOLLEY",
        value: "Beach",
        image: beachVolley
    },
    {
        name: "WATER",
        key: "WATER-VOLLEY",
        value: "Water",
        image: sea
    },
    {
        name: "SNOW",
        key: "SNOW-VOLLEY",
        value: "Snow",
        image: mountain
    }
]

export const LEVEL = {
    OUT_OF_COMPETITION: {
        name: "OUT_OF_COMPETITION",
        key: "OUT_OF_COMPETITION",
        value: "leisure",
        position: 0
    },
    LOW: {
        name: "LOW",
        key: "LOW",
        value: "dep",
        position: 1
    },
    MIDDLE: {
        name: "MIDDLE",
        key: "MIDDLE",
        value: "reg",
        position: 2
    },
    HIGHER: {
        name: "HIGHER",
        key: "HIGHER",
        value: "nat",
        position: 3
    },
    ELITE: {
        name: "ELITE",
        key: "ELITE",
        value: "elite",
        position: 4
    },
    PRO: {
        name: "PRO",
        key: "PRO",
        value: "pro",
        position: 5
    },
    LEGEND: {
        name: "LEGEND",
        key: "LEGEND",
        value: "legend",
        position: 6
    },
}

export const FLAG_BY_COUNTRY = {
    "FRANCE": france,
    "BELGIUM": belgium
}

export const COUNTRY_PAIRS = {
    "FRANCE": "FR",
    "BELGIUM": "BE"
}

export const COUNTRY = [
    {
        key: "FRANCE",
        value: "France",
    },
    {
        key: "BELGIUM",
        value: "Belgique",
    },
]

export const REGION = [
    {
        key: "AUVERGNE_RHONE_ALPES",
        value: "Auvergne-Rhône-Alpes",
        country: "FRANCE"
    },
    {
        key: "BOURGOGNE_FRANCHE_COMTE",
        value: "Bourgogne-Franche-Comté",
        country: "FRANCE"
    },
    {
        key: "BRETAGNE",
        value: "Bretagne",
        country: "FRANCE"
    },
    {
        key: "CENTRE_VAL_DE_LOIRE",
        value: "Centre-Val de Loire",
        country: "FRANCE"
    },
    {
        key: "CORSE",
        value: "Corse",
        country: "FRANCE"
    },
    {
        key: "GRAND_EST",
        value: "Grand Est",
        country: "FRANCE"
    },
    {
        key: "HAUTS_DE_FRANCE",
        value: "Hauts-de-France",
        country: "FRANCE"
    },
    {
        key: "ILE_DE_FRANCE",
        value: "Ile-de-France",
        country: "FRANCE"
    },
    {
        key: "NORMANDIE",
        value: "Normandie",
        country: "FRANCE"
    },
    {
        key: "NOUVELLE_AQUITAINE",
        value: "Nouvelle-Aquitaine",
        country: "FRANCE"
    },
    {
        key: "OCCITANIE",
        value: "Occitanie",
        country: "FRANCE"
    },
    {
        key: "PAYS_DE_LA_LOIRE",
        value: "Pays de la Loire",
        country: "FRANCE"
    },
    {
        key: "PROVENCE_ALPES_COTE_D_AZUR",
        value: "Provence Alpes Côte d’Azur",
        country: "FRANCE"
    },
    {
        key: "GUADELOUPE",
        value: "Guadeloupe",
        country: "FRANCE"
    },
    {
        key: "GUYANE",
        value: "Guyane",
        country: "FRANCE"
    },
    {
        key: "MARTINIQUE",
        value: "Martinique",
        country: "FRANCE"
    },
    {
        key: "MAYOTTE",
        value: "Mayotte",
        country: "FRANCE"
    },
    {
        key: "REUNION",
        value: "Réunion",
        country: "FRANCE"
    },
    {
        key: "ANTWERP",
        value: "Antwerp",
        country: "BELGIUM"
    },
    {
        key: "EAST_FLANDERS",
        value: "East Flanders",
        country: "BELGIUM"
    },
    {
        key: "FLEMISH_BRABANT",
        value: "Flemish Brabant",
        country: "BELGIUM"
    },
    {
        key: "LIMBURG",
        value: "Limburg",
        country: "BELGIUM"
    },
    {
        key: "WEST_FLANDERS",
        value: "West Flanders",
        country: "BELGIUM"
    },
    {
        key: "HAINAUT",
        value: "Hainaut",
        country: "BELGIUM"
    },
    {
        key: "LIEGE",
        value: "Liège",
        country: "BELGIUM"
    },
    {
        key: "LUXEMBOURG",
        value: "Luxembourg",
        country: "BELGIUM"
    },
    {
        key: "NAMUR",
        value: "Namur",
        country: "BELGIUM"
    },
    {
        key: "WALLOON_BRABANT",
        value: "Walloon Brabant",
        country: "BELGIUM"
    },
    {
        key: "BRUSSELS_CAPITAL_REGION",
        value: "Brussels Capital Region",
        country: "BELGIUM"
    },
]

export const SPACE = " "

export function levelName(position) {
    switch (parseInt(position)) {
        case LEVEL.OUT_OF_COMPETITION.position: return LEVEL.OUT_OF_COMPETITION.name
        case LEVEL.LOW.position: return LEVEL.LOW.name
        case LEVEL.MIDDLE.position: return LEVEL.MIDDLE.name
        case LEVEL.HIGHER.position: return LEVEL.HIGHER.name
        case LEVEL.ELITE.position: return LEVEL.ELITE.name
        case LEVEL.PRO.position: return LEVEL.PRO.name
        case LEVEL.LEGEND.position: return LEVEL.LEGEND.name
    }
}

export function levelPosition(value) {
    switch (value) {
        case LEVEL.OUT_OF_COMPETITION.name: return LEVEL.OUT_OF_COMPETITION.position
        case LEVEL.LOW.name: return LEVEL.LOW.position
        case LEVEL.MIDDLE.name: return LEVEL.MIDDLE.position
        case LEVEL.HIGHER.name: return LEVEL.HIGHER.position
        case LEVEL.ELITE.name: return LEVEL.ELITE.position
        case LEVEL.PRO.name: return LEVEL.PRO.position
        case LEVEL.LEGEND.name: return LEVEL.LEGEND.position
    }
}

export function levelFromEnum(value) {
    if (value == null) return ""

    return LEVEL[value].value
}

export function parseJwt(token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        window.atob(base64).split('').map(
            function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }
        ).join('')
    )
    return JSON.parse(jsonPayload)
}

export function capitalizeFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function monthFormat(value) {
    return capitalizeFirstLetter(value)
}

export function removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    arr.splice(objWithIdIndex, 1);
    return arr;
}

export function openModal(modalName) {
    const modalToOpen = new bootstrap.Modal(document.getElementById(modalName), {})

    return modalToOpen.show()
}

export function closeModal(modalName) {
    const modal = bootstrap.Modal.getInstance(document.getElementById(modalName), {})

    if (modal == null) return

    return modal.hide()
}

export function country() {
    return <React.Fragment>
        <option key="CHOOSE" value="CHOOSE" defaultValue>{ i18n.t("common.choose") }...</option>
        {
            COUNTRY.map(
                it => <option key={ it.key } value={ it.key }>{ it.value }</option>
            )
        }
    </React.Fragment>
}

export function regionByCountry(country) {
    const isCountrySelected = country !== "" && country !== "CHOOSE"

    const regions = isCountrySelected ? REGION.filter(it => it.country === country) : REGION

    return <React.Fragment>
        <option key="CHOOSE" value="CHOOSE" defaultValue>{ i18n.t("common.choose") }...</option>
        {
            regions.map(
                it => {
                    return <option key={ it.key } value={ it.key }>
                        { regionTranslation(it) } { !isCountrySelected ? "[" + COUNTRY_PAIRS[it.country] + "]" : "" }
                    </option>
                }
            )
        }
    </React.Fragment>
}

export function gender(game) {
    const nbOfMember = game.nbOfMember
    const minMaleInTeam = game.minMaleInTeam
    const minFemaleInTeam = game.minFemaleInTeam

    return genderWith(nbOfMember, minMaleInTeam, minFemaleInTeam)
}

export function genderWith(nbOfMember, minMaleInTeam, minFemaleInTeam) {
    if (minMaleInTeam === nbOfMember) return i18n.t("home.gender.onlyMale")
    else if (minFemaleInTeam === nbOfMember) return i18n.t("home.gender.onlyFemale")
    else if (minMaleInTeam === 0 && minFemaleInTeam === 0) return i18n.t("home.gender.unknown")
    else if (minMaleInTeam === 0 && minFemaleInTeam > 0) return i18n.t("home.gender.mixed") + ", " + minFemaleInTeam + " " + i18n.t("home.gender.female") + " " + i18n.t("home.gender.min")
    else if (minFemaleInTeam === 0 && minMaleInTeam > 0) return i18n.t("home.gender.mixed") + ", " + minMaleInTeam + " " + i18n.t("home.gender.male") + " " + i18n.t("home.gender.min")
    else if (minMaleInTeam > 0 && minFemaleInTeam > 0) return i18n.t("home.gender.mixed") + ", " + minMaleInTeam + " " + i18n.t("home.gender.male") + " " + i18n.t("common.and") + " " + minFemaleInTeam + " " + i18n.t("home.gender.female") + " " + i18n.t("home.gender.min")
}

export function level(game) {
    const levelFrom = game.levelFrom
    const levelTo = game.levelTo

    return levelWith(levelFrom, levelTo)
}

export function levelWith(levelFrom, levelTo) {
    if (levelFrom === levelTo) return i18n.t("level." + LEVEL[levelFrom].value)

    return i18n.t("level." + LEVEL[levelFrom].value) + " - " + i18n.t("level." + LEVEL[levelTo].value)
}

export function regionFinder(value) {
    return REGION.filter(it => it.key === value)[0]
}

export function regions(search) {
    const region = search.region
    const country = search.country

    const isRegionSelected = region !== undefined && region !== "CHOOSE"
    const isCountrySelected = country !== undefined && country !== "CHOOSE"

    if (isRegionSelected) return [ region ]
    if (isCountrySelected) return REGION.filter(it => it.country === country).map(it => it.key)

    return []
}

export function regionTranslation(region) {
    const key = "region." + region.key

    const translation = i18n.t(key)

    if (translation !== key) return translation

    return region.value
}

export function actionButton(
    game,
    user,
    context,
    onHandleStatusChange,
    btnColor
) {
    const status = game.status
    const isCertifiedUser = user.isCertified

    if (status === "ENABLE") return <button
        type="button"
        className={ "btn btn-sm btn-warning col-12 " + btnColor }
        onClick={ () => onHandleStatusChange(game, "DISABLE") }
    >
        { i18n.t("button.disable") }
    </button>
    else if (status === "CREATED" || status === "UPDATED" || status === "RE_OPEN") return <button
        type="button"
        className={ "btn btn-sm col-12 " + btnColor }
        disabled={ !context.user.isAdmin && !isCertifiedUser }
        onClick={ () => onHandleStatusChange(game, "ENABLE") }
    >
        { i18n.t("button.enable") }
    </button>
    else if (status === "DISABLE") return <button
        type="button"
        className={ "btn btn-sm col-12 " + btnColor }
        onClick={ () => onHandleStatusChange(game, "UPDATED") }
    >
        { i18n.t("button.reOpen") }
    </button>
}

export function statusFromGame(status) {
    switch (status) {
        case "CREATED": return <button
            type="button"
            className="btn btn-outline-info btn-sm active col-12"
        >
            { i18n.t("button.waitingForValidation") }
        </button>
        case "UPDATED": return <button
            type="button"
            className="btn btn-outline-info btn-sm active col-12"
        >
            { i18n.t("button.waitingForValidation") }
        </button>
        case "ENABLE": return <button
            type="button"
            className="btn btn-outline-success btn-sm active col-12"
        >
            { i18n.t("button.activate") }
        </button>
        case "DISABLE": return <button
            type="button"
            className="btn btn-outline-warning btn-sm active col-12"
        >
            { i18n.t("button.disabled") }
        </button>
    }
}

export function pictureByGender(user) {
    const gender = user.gender
    const partner = user.partner
    const logo = user.logo

    if (gender === "MALE") return man
    else if (gender === "FEMALE") return woman
    else if (partner === "HELLO_ASSO" && logo !== "") return logo
    else if (partner === "HELLO_ASSO") return organization
    else return transgender
}

export function captainPicture(user) {
    if (user == null) return ""

    const gender = user.gender

    if (gender === "MALE") return captainMale
    else if (gender === "FEMALE") return captainFemale

    return transgender
}

export const levels = (levelFrom, levelTo) => {
    return [
        LEVEL.OUT_OF_COMPETITION,
        LEVEL.LOW,
        LEVEL.MIDDLE,
        LEVEL.HIGHER,
        LEVEL.ELITE,
        LEVEL.PRO,
        LEVEL.LEGEND
    ].filter(
        it => it.position >= levelFrom.position && it.position <= levelTo.position
    ).map(
        it => <option key={ it.key } value={ it.key }>{ i18n.t("level." + it.value) }</option>
    )
}

export const onClipboard = (
    target,
    data
) => {
    const message = target + SPACE + i18n.t("common.hasBeen") + SPACE + i18n.t("common.copy").toLowerCase()

    copy(data)
    infoToast(message)
}

export const teamBcColor = (team) => {
    if (team.status === "REGISTERED") return "border-success"
    if (team.status === "REGISTERING") return "border-warning"
    if (team.status === "WAITING_LIST") return "border-info"
    if (team.status === "CANCELED") return "border-danger"
}

export function teamGuestInfo(team, users) {

    const captain = team.captain != null && team.captain.username !== "" ? users.find(it => it.username === team.captain.username) : null

    const captainInfo = !!captain ? <div className="d-flex justify-content-between px-0">
        <div>
            { captain.firstname } <span className="text-uppercase">{ captain.lastname }</span>
        </div>
        <div className="text-end" style={ { textTransform: "uppercase" } }>

        </div>
    </div> : <React.Fragment></React.Fragment>

    return <div className={ "card shadow-sm " + teamBcColor(team) }>
        <div className="card-body px-1">
            <div className="row row-gap-2 px-0 mx-0">
                <div className="d-flex justify-content-between px-0">
                    <div>{ team.name }</div>
                    <div hidden={ team.level == null }>{ i18n.t("level." + levelFromEnum(team.level)) }</div>
                </div>
                { captainInfo }
            </div>
        </div>
    </div>
}

export function userInfo(users) {
    if (users == null || users.length === 0) return <React.Fragment></React.Fragment>

    return users.map(
        it => <div className="d-flex justify-content-between px-0" key={ it.id }>
            <div>
                { it.firstname } <span className="text-uppercase">{ it.lastname }</span>
            </div>
            <div className="text-end" style={ { textTransform: "uppercase" } }>

            </div>
        </div>
    )
}

export function teamInfo(team, users) {

    if (team.type === "TEAM_GUEST") return teamGuestInfo(team, users)

    const teamUserIds = team.members.map(it => it.userId)

    const filteredUser = users.filter(it => teamUserIds.includes(it.id))

    return <div className={ "card shadow-sm " + teamBcColor(team) }>
        <div className="card-body px-1">
            <div className="row row-gap-2 px-0 mx-0">
                <div className="d-flex justify-content-between px-0">
                    <div>{ team.name }</div>
                    <div hidden={ team.level == null }>{ i18n.t("level." + levelFromEnum(team.level)) }</div>
                </div>

                <div className="row row-gap-2 px-0 mx-0" hidden={ filteredUser.length === 0 }>
                    { userInfo(filteredUser) }
                </div>
            </div>
        </div>
    </div>
}

export function teamsInfo(game, users) {
    return game.teams.map(it => <React.Fragment key={ it.id }>{ teamInfo(it, users) }</React.Fragment>)
}

export function captainCard(
    user,
    shadow = "shadow-sm",
    phoneNumber = "",
) {
    if (user == null) return <React.Fragment></React.Fragment>

    const phone = !!phoneNumber ? phoneNumber : user.phoneNumber

    return <div className={ "card emergence px-3 " + shadow }>
        <div className="card-body px-0">
            <div className="d-flex justify-content-between">
                <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-11">
                    <div className="d-flex h-100">
                        <div className="my-auto">
                            <img
                                src={ captainPicture(user) }
                                className="img-thumbnail border-0 bg-transparent p-0"
                                alt="user_image"
                                style={ { maxWidth: "90px" } }
                            />
                        </div>
                        <div className="d-flex justify-content-between flex-column ps-sm-1">
                            { userCardDetail(user, phone) }
                        </div>
                    </div>
                </div>
                <div className="col" style={ { textAlign: "-webkit-right" } }>
                    <div className="row row-gap-2 mx-0" style={ { width: "20px" } }>
                        <img src={ captainAmerica } className="img-fluid bg-transparent px-0" alt="captain_image"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export const usersCard = (users, actionButton) => {
    return users.sort((a,b) => alphabetSort(a.lastname, b.lastname)).map((user) => <React.Fragment key={ user.id }>{ userCard(user, actionButton) }</React.Fragment>)
}

export function userCard(user, rightButton, shadow = "shadow-sm", phoneNumber) {

    if (rightButton == null) return <div className={ "card emergence " + shadow }>
        <div className="card-body ps-0 pe-2">
            <div className="d-flex justify-content-between">
                <div className="col-12">
                    <div className="d-flex h-100">
                        <div className="my-auto">
                            <img
                                src={ pictureByGender(user) }
                                className="img-thumbnail border-0 bg-transparent p-0"
                                alt="user_image"
                                style={ { maxWidth: "80px" } }
                            />
                        </div>
                        <div className="d-flex justify-content-between flex-column ps-sm-1">
                            { userCardDetail(user, phoneNumber) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    return <div className={ "card emergence " + shadow }>
        <div className="card-body px-0">
            <div className="d-flex justify-content-between">
                <div className="col-xxl-10 col-xl-7 col-lg-8 col-md-9 col-8">
                    <div className="d-flex h-100">
                        <div className="my-auto">
                            <img
                                src={ pictureByGender(user) }
                                className="img-thumbnail border-0 bg-transparent p-0"
                                alt="user_image"
                                style={ { maxWidth: "80px" } }
                            />
                        </div>
                        <div className="d-flex justify-content-between flex-column ps-sm-1">
                            { userCardDetail(user, phoneNumber) }
                        </div>
                    </div>
                </div>
                <div className="col-xl-1 col-lg-2 col-md-2 col-2">
                    <div className="row row-gap-2 h-100 mx-0">{ rightButton(user) }</div>
                </div>
            </div>
        </div>
    </div>
}

const userCardDetail = (user, phoneNumber) => <React.Fragment>
    <div className="small text-break">
        { i18n.t("common.firstname") }:<span className="fst-italic fw-semibold ps-1">{ user.firstname }</span>
    </div>
    <div className="small text-break">
        { i18n.t("common.name") }:<span className="fst-italic fw-semibold ps-1">{ user.lastname }</span>
    </div>
    <div className="small text-break">
        { i18n.t("common.user-tag") }:<span className="fst-italic fw-semibold ps-1">{ user.userTag }</span>
    </div>
    <div
        className="small text-break"
        hidden={ user.username === "" }
    >
        { i18n.t("common.mail") }:<span className="fst-italic fw-semibold ps-1">{ user.username }</span>
    </div>
    <div className="small text-break" hidden={ user.level == null }>
        { i18n.t("common.level") }:<span className="fst-italic fw-semibold ps-1">{ user.level }</span>
    </div>
    <div className="small text-break" hidden={ phoneNumber == null }>
        { i18n.t("common.phone-number") }:<span className="fst-italic fw-semibold ps-1">{ phoneNumber }</span>
    </div>
</React.Fragment>

export const reactiveReader = async (
    request,
    setter,
    navigate, location, context,
    transformers = (values) => values, transformer = (value) => value,
) => {
    const processStream = async () => {

        const reader = request.body.getReader()

        while (true) {

            const { done, value } = await reader.read()

            if (done) break;

            const parsedItem = reactiveParser(new TextDecoder().decode(value))

            if (parsedItem !== undefined) {

                if (parsedItem.status === 401) return disconnect(navigate, context, location.pathname + location.search)
                if (parsedItem.error != null || parsedItem.status >= 401 || parsedItem.httpCode != null) return

                setter((prevItems) => {
                    if (parsedItem != null && parsedItem.length != null) return transformers(parsedItem)

                    return [ ...prevItems, transformer(parsedItem) ]
                })
            }
        }
    }

    await processStream()
}

function reactiveParser(partialData) {
    try {
        return JSON.parse(partialData)
    }  catch (error) {
        if (error instanceof SyntaxError) {
            const array = Array.from(partialData)
            if (array[0] === "[" || array[0] === ",") return reactiveParser(partialData.substring(1))
            if (array[array.length - 1] === "]") return reactiveParser(partialData.substring(0, partialData.length - 1))
        }
    }
}

export const timeParser = (time) => {
    const sliceSuffix = time.length === 8 ? -3 : time.length

    return time.substring(0, 1) === "0" ? time.slice(1, sliceSuffix) : time.slice(0, sliceSuffix)
}

export const timesParser = (startTime, endTime) => timeParser(startTime) + " - " + timeParser(endTime)

export const alphabetSort = (a, b) => {
    if (a < b) return -1
    if (a > b) return 1
    return 0
}

export const registerTeam = (game) => game.teams.filter(it => it.status === "REGISTERING" || it.status === "REGISTERED")

export const i118nTPlayerWith = (nbOfTicketToPay) => {
    if (nbOfTicketToPay <= 1) return i18n.t("common.player-with")

    return i18n.t("common.players-with")
}