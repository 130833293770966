import { APPLICATION_JSON, DELETE, POST, PUT } from "../const/request"
import { refreshToken, resParser } from "../const/utils"
import { store } from "../repository/store"

class TeamService {

    async createAsGuest(
        authId,
        authCode,
        gameId,
        teamName,
        email,
        firstname,
        lastname,
        phoneNumber,
        nbOfExtraMember,
        level,
        backUrl,
        errorUrl,
        returnUrl,
    ) {
        const path = process.env.BASE_URL + "/api/v1/events/" + gameId + "/teams/as-guest"

        const headers = {
            "Content-Type": APPLICATION_JSON,
            "Authorization-Id": authId,
            "Authorization-Code": authCode,
        }

        const body = JSON.stringify({
            name: teamName,
            email: email,
            firstname: firstname,
            lastname: lastname,
            phoneNumber: phoneNumber,
            nbOfExtraMember: nbOfExtraMember,
            level: level,
            backUrl: process.env.BASE_URL + backUrl,
            errorUrl: process.env.BASE_URL + errorUrl,
            returnUrl: process.env.BASE_URL + returnUrl,
        })

        const response = await fetch(path, {
            method: POST,
            headers: headers,
            body: body
        })

        return await resParser(response)
    }

    async create(
        gameId,
        teamName,
        phoneNumber,
        nbOfTicket,
        members,
        level,
        backUrl, errorUrl, returnUrl
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/events/" + gameId + "/teams"

        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }

        const body = JSON.stringify({
            name: teamName,
            phoneNumber: phoneNumber,
            nbOfTicket: nbOfTicket,
            level: level,
            members: members,
            backUrl: process.env.BASE_URL + backUrl,
            errorUrl: process.env.BASE_URL + errorUrl,
            returnUrl: process.env.BASE_URL + returnUrl,
        })

        const response = await fetch(path, {
            method: POST,
            headers: headers,
            body: body
        })

        return await resParser(response)
    }

    async update(
        gameId,
        teamId,
        membersToRemove,
        requestMembersToAdd,
        requestMembersToRemove
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/events/" + gameId + "/teams/" + teamId

        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }

        const body = JSON.stringify({
            membersToRemove: membersToRemove,
            requestMembersToAdd: requestMembersToAdd,
            requestMembersToRemove: requestMembersToRemove
        })

        const response = await fetch(path, {
            method: PUT,
            headers: headers,
            body: body
        })

        return await resParser(response)
    }

    async cancel(
        gameId,
        teamId
    ){
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/events/" + gameId + "/teams/" + teamId + "/cancel"

        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }

        const response = await fetch(path, {
            method: PUT,
            headers: headers
        })

        return await resParser(response)
    }

    async updateMember(
        gameId,
        teamId,
        username,
        visibility
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/events/" + gameId + "/teams/" + teamId + "/members/" + username

        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }

        const body = JSON.stringify({
            visibility: visibility
        })

        const response = await fetch(path, {
            method: PUT,
            headers: headers,
            body: body
        })

        return await resParser(response)
    }

    async deleteMember(
        gameId,
        teamId,
        username
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/events/" + gameId + "/teams/" + teamId + "/members/" + username

        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }

        const response = await fetch(path, {
            method: DELETE,
            headers: headers
        })

        return await resParser(response)
    }

    async updateRequestMember(
        gameId,
        teamId,
        action
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/events/" + gameId + "/teams/" + teamId + "/request-members"

        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }

        const body = JSON.stringify({
            action: action
        })

        const response = await fetch(path, {
            method: PUT,
            headers: headers,
            body: body
        })

        return await resParser(response)
    }
}

export const teamService = new TeamService()