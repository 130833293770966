export async function generatePKCE() {
    const codeVerifier = generateRandomString(128)
    const codeChallenge = await generateCodeChallenge(codeVerifier)

    return { codeVerifier, codeChallenge }
}

function generateRandomString(length) {
    let text = ""
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text;
}

async function generateCodeChallenge(codeVerifier) {
    const data = new TextEncoder().encode(codeVerifier)
    const digest = await window.crypto.subtle.digest("SHA-256", data)

    return btoa(
        String.fromCharCode(...new Uint8Array(digest))
    ).replace(
        /=/g,
        ''
    ).replace(/\+/g, '-').replace(/\//g, '_')
}
