import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import i18n from "i18next"
import { recordService } from "../service/record-service"
import ShowcasePicture from "../component/showcase-picture"
import {
    actionButton,
    capitalizeFirstLetter,
    gender, level, LEVEL,
    regionFinder,
    regionTranslation,
    statusFromGame,
} from "../const/common"
import { disconnect, formatPrice, handleTheme, isAdmin, isNotConnected, refreshToken, startDate } from "../const/utils"
import { ButtonHelloAsso } from "../component/button-hello-asso"
import Linkify from "react-linkify"
import { eventTransformer, gameService } from "../service/game-service"
import { Context } from "../const/context"
import Login from "./login"
import { userService } from "../service/user-service"
import helloassologo from "../../public/hello_asso_logo.svg"
import { notifierService } from "../service/notifier-service"
import InputGameAdvanced from "../component/input-game-advanced"
import { store } from "../repository/store"
import { mediaService } from "../service/media-service"

export default function Game() {

    const { id } = useParams()
    const [ searchParams] = useSearchParams()

    const context = useContext(Context)

    const location = useLocation()
    const navigate = useNavigate()

    const haPath = process.env.BASE_HA_REDIRECT_URL + location.pathname /*+ location.search*/ /* TODO VERIFY FOR HELLO_ASSO */

    const color = handleTheme() === "dark" ? "white" : "black"

    const userId = context.user.userId

    const [ emittedTime, setEmittedTime ] = useState(null)

    const [ user, setUser ] = useState(null)
    const [ game, setGame ] = useState(null)
    const [ record, setRecord ] = useState("")
    const [ modalUrl, setModalUrl ] = useState("")
    const [ medias, setMedias ] = useState([])

    const [ visibility, setVisibility ] = useState("PUBLIC")

    const [ canManageRegistration, setCanManageRegistration ] = useState(false)
    const [ canUseWaitingList, setCanUseWaitingList ] = useState(false)
    const [ shouldValidateLevel, setShouldValidateLevel ] = useState(false)
    const [ shouldAskPlayerLicensed, setShouldAskPlayerLicensed ] = useState(false)

    const [ nbOfExtraMember, setNbOfExtraMember ] = useState(0)
    const [ deadlineOfIndividualPayment, setDeadlineOfIndividualPayment ] = useState(1)

    const [ dayOfEventPlayerPrice, setDayOfEventPlayerPrice ] = useState(null)

    const [detailDivHeight, setDetailDivHeight] = useState(500)

    const refActionDiv = useRef(null)
    const refInfoDiv = useRef(null)

    useEffect(() => {
        async function fetchGame() {
            const res = await gameService.getAll(id)

            if (res == null) return navigate("/games")
            if (res.status === 401) return disconnect(navigate, context, location.pathname)
            if (res.length === 0) return navigate("/games")
            else {
                const game = res[0]
                setGame(game)
                setNbOfExtraMember(game.nbOfExtraMember)
            }
        }

        if (game == null && !isNotConnected(context)) fetchGame().then()
    }, [ context.user.isConnected ])

    useEffect( () => {
        async function fetchRecord() {
            const res = await recordService.search([ id ], [])

            if (res == null) return
            if (res.status === 401) return disconnect(navigate, context, location.pathname)
            if (res.length === 0) return

            setRecord(res[0].url)
        }

        if (!isNotConnected(context)) fetchRecord().then()
    }, [  ])

    useEffect(() => {
        async function fetchUser() {
            const res = await userService.getById(userId)

            if (res.status === 401) return disconnect(navigate, context, location.pathname)

            setUser(res)
        }

        if (user == null && !isNotConnected(context)) fetchUser().then()
        if (isNotConnected(context)) setUser(null)
    }, [ isNotConnected(context) ])

    useEffect(() => {
        const findMedias = async () => {
            const res = await mediaService.getAll("event", game.id)

            if (res.status === 401) return disconnect(navigate, context, location.pathname)

            setMedias(res)
        }

        if (game != null && !isNotConnected(context)) findMedias().then()
    }, [ game ])

    useEffect(() => {
        const haCode = searchParams.get("code")
        const codeVerifier = store.data.o2Auth.ha.codeVerifier

        if (isNotConnected(context)) return
        if (haCode == null || codeVerifier == null) return
        if (game == null || game.isPaymentAllow) return

        const updatePaymentProvider = async () => {
            const res = await gameService.paymentProviderByConnection(game.id, haCode, codeVerifier, haPath)

            if (res.error != null || res.httpCode != null) return

            setGame(res)

            store.data.o2Auth.ha.codeVerifier = null
            store.save()
        }

        updatePaymentProvider().then()
    }, [ game ])

    useEffect(() => {

        const fetchNotifierTime = async () => {
            const res = await notifierService.notifierTime()

            setEmittedTime(res.data)
        }

        const fetchData = async () => {
            if (isNotConnected(context)) return

            const res = await notifierService.notifier(emittedTime)

            const isUnAuthorized = res.status === 401 || res.status === 403 || res.httpCode === 403

            if (isUnAuthorized) return disconnect(navigate, context, location.pathname)
            else if (res.status > 500) return

            res.map(it => {
                setEmittedTime(it.emittedTime)

                const gameNotifier = it.data
                const type = it.type

                if (type !== "GAME_UPDATED") return

                if (game != null && game.id === gameNotifier.id) setGame(eventTransformer(gameNotifier))
            })
        }

        if (emittedTime == null) fetchNotifierTime().then()

        const timer = setInterval( () => { if (emittedTime != null) fetchData().then() }, 5000)

        return () => clearInterval(timer)
    }, [ context, emittedTime ])

    useEffect(() => {
        if (game == null) return
        if (refActionDiv == null || refActionDiv.current == null) return
        if (refInfoDiv == null || refInfoDiv.current == null) return

        setTimeout(() => setDetailDivHeight(refActionDiv.current.clientHeight - refInfoDiv.current.clientHeight), 300)

    }, [ game, record, refActionDiv.current, refInfoDiv.current ])

    const onClickShare = (event) => { if (game.status !== "ENABLE" || !game.canManageRegistration) event.preventDefault() }

    function onHandleUpdate(game, record) { navigate("/game-edition", { state: { game: game, file: record } }) }

    function onHandleManager(game) { navigate("/managers", { state: { game: game } }) }

    async function onHandleSocialNetwork(game){ navigate("/social-network", { state: { game: game } }) }

    async function onHandleSocialNetworkPublish(game) {
        const socialNetworkModal = new bootstrap.Modal(document.getElementById("socialNetworkModal"), {})

        const shouldShowModal = !game.canManageRegistration || !game.isPaymentAllow

        if (shouldShowModal) return socialNetworkModal.show()

        await publishToSocialNetwork(game)
    }

    async function publishToSocialNetwork(game) {
        const res = await mediaService.create("event", game.id, ["FACEBOOK", "INSTAGRAM"], "DEFAULT")

        if (res.status === 401) return disconnect(this.navigate, this.props.context, location.pathname)

        if (res.error != null || res.httpCode != null) return

        setMedias([ res ])
    }

    async function onHandleStatusChange(game, status) {
        const res = await gameService.updateStatus(game.id, status)

        if (res.status === 401) return disconnect(navigate, context, location.pathname)

        if (res.error != null || res.httpCode != null) return

        setGame(res)
    }

    async function onHandleDelete(id) {
        const { status } = await gameService.delete(id)

        if (status === 401) return disconnect(navigate, context, location.pathname)
        if (status === 403) return navigate("/home")

        if (status === 204) navigate("/games")
    }

    const onHandleTeamsView = () => navigate("/game-teams", { state: { eventId: game.id } })

    async function updatePaymentProvider() {
        const rnaNumber = user.rnaNumber

        const updatedGame = await gameService.paymentProviderByOrganisation(game.id, rnaNumber)

        setGame(updatedGame)
    }

    async function deletePaymentProvider() {
        const res = await gameService.deletePaymentProvider(game.id)

        if (res.error != null || res.httpCode != null) return

        setGame(res)
    }

    function activateRegistry() {
        const registryModal = new bootstrap.Modal(document.getElementById("registryModal"), {})

        if (!game.canManageRegistration) return registryModal.show()
    }

    function onHandleAdvanceSettings(
        visibility,
        canManageRegistration,
        canUseWaitingList,
        shouldValidateLevel,
        shouldAskPlayerLicensed,
        nbOfExtraMember,
        deadlineOfIndividualPayment,
        dayOfEventPlayerPrice
    ) {
        setVisibility(visibility)

        setCanManageRegistration(canManageRegistration)
        setCanUseWaitingList(canUseWaitingList)
        setShouldValidateLevel(shouldValidateLevel)
        setShouldAskPlayerLicensed(shouldAskPlayerLicensed)

        setNbOfExtraMember(nbOfExtraMember)
        setDeadlineOfIndividualPayment(deadlineOfIndividualPayment)

        setDayOfEventPlayerPrice(dayOfEventPlayerPrice)
    }

    async function onHandleSaveAdvanceSettings() {

        const res = await gameService.updateRegistry(
            game.id,

            visibility,

            dayOfEventPlayerPrice,

            canManageRegistration,
            canUseWaitingList,
            shouldValidateLevel,
            shouldAskPlayerLicensed,

            nbOfExtraMember,
            deadlineOfIndividualPayment,

            game.phoneNumber,
            game.mail,
            game.whatsAppGroupLink,

            game.detail,
        )

        setGame(res)
    }

    if (isNotConnected(context)) return <Login/>

    if (game == null || user == null) return <React.Fragment></React.Fragment>

    const region = regionFinder(game.region)

    function statusActionButton(it) {
        const status = it.status
        const isNotEnable = status !== "DISABLE" && status !== "ENABLE"

        const leftButton = isNotEnable ? <button
            type="button"
            className="btn btn-warning btn-sm col-12"
            onClick={ () => onHandleStatusChange(it, "DISABLE") }
            disabled={ status === "CREATED" }
        >
            { i18n.t("button.disable") }
        </button> : ""

        const rightButton = actionButton(it, user, context, onHandleStatusChange, "btn-secondary")

        return <div className="pe-md-0 px-0">
            <div className="row m-auto px-0">
                <div className="col-6 ps-0 pe-1">{ leftButton }</div>
                <div className={ isNotEnable ? "col-6 ps-1 pe-0" : "col-12 p-0" }>{ rightButton }</div>
            </div>
        </div>
    }

    function haCard() {
        if (game == null) return <React.Fragment></React.Fragment>

        if (!game.canManageRegistration && game.isPaymentAllow) return <div className="card px-0 shadow-sm border-ha">
            <div className="card-body">
                <blockquote className="blockquote">
                    <div className="d-flex justify-content-between">
                        <div>
                            <img
                                src={ helloassologo } alt="hello_asso"
                                className="pe-2"
                                style={ { height: "24.77px" } }
                            />
                            <span className="align-text-top">HelloAsso</span>
                        </div>
                        <div>
                            <span className="align-text-top">
                                { i18n.t("game.connected") }
                                <span className="dots" style={ { color: "#49D38A" } }>
                                    <span>.</span>
                                    <span>.</span>
                                    <span>.</span>
                                </span>
                            </span>
                        </div>
                    </div>
                </blockquote>
                <div className="row row-gap-3 mx-0">
                    <div className="small px-0">
                        { i18n.t("game.helloasso-message") }
                    </div>
                    <div className="px-0">
                        <button
                            className="HaAuthorizeButton"
                            onClick={ activateRegistry }
                        >
                            <span className="HaAuthorizeButtonTitle">{ i18n.t("game.activate-registration") }</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        if (game.isPaymentAllow) return <div className="card px-0 shadow-sm border-ha">
            <div className="card-body">
                <blockquote className="blockquote">
                    <div className="d-flex justify-content-between">
                        <div>
                            <img
                                src={ helloassologo } alt="hello_asso"
                                className="pe-2"
                                style={ { height: "24.77px" } }
                            />
                            <span className="align-text-top">HelloAsso</span>
                        </div>
                        <div>
                            <span className="align-text-top">
                                { i18n.t("game.connected") }
                                <span className="dots" style={ { color: "#49D38A" } }>
                                    <span>.</span>
                                    <span>.</span>
                                    <span>.</span>
                                </span>
                            </span>
                        </div>
                    </div>
                </blockquote>
                <div className="row row-gap-3 mx-0">
                    <div className="small px-0">
                        { i18n.t("game.helloasso-message") }
                    </div>
                    <div className="px-0">
                        <button
                            className="HaAuthorizeButtonInvert"
                            onClick={ deletePaymentProvider }
                        >
                            <span className="HaAuthorizeButtonInvertTitle">
                                { i18n.t("game.delete-payment-link") }
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        return <div className="card shadow-sm border-ha px-0">
            <div className="card-body">
                <blockquote className="blockquote">
                    { i18n.t("game.use-helloasso") }
                </blockquote>

                <div className="row row-gap-3 mx-0">
                    <div className="small px-0">
                        { i18n.t("game.helloasso-message") }
                    </div>
                    <div className="px-0" hidden={ user.partner !== "HELLO_ASSO" }>
                        <button
                            className="HaAuthorizeButton"
                            onClick={ updatePaymentProvider }
                        >
                            <img
                                src={ helloassologo } alt="hello_asso"
                                className="HaAuthorizeButtonLogo"
                                style={ { width: "50px" } }
                            />
                            <span className="HaAuthorizeButtonTitle">{ i18n.t("game.link-helloasso") }</span>
                        </button>
                    </div>
                    <div className="px-0" style={ { width: "fit-content" } } hidden={ user.partner === "HELLO_ASSO" }>
                        <ButtonHelloAsso text={ i18n.t("game.connect-helloasso") } redirectUri={ haPath }/>
                    </div>
                </div>

            </div>
        </div>
    }

    const registeredTeams = () => game.teams.filter(it => it.status === "REGISTERED")
    const registeringTeams = () => game.teams.filter(it => it.status === "REGISTERING")
    const waitingListTeams = () => game.teams.filter(it => it.status === "WAITING_LIST")

    const nbOfRegisteredTeams = () => registeredTeams().length
    const nbOfRegisteringTeams = () => registeringTeams().length
    const nbOfWaitingListTeams = () => waitingListTeams().length

    const disableCheckTeamButton = () => nbOfRegisteredTeams() === 0 && nbOfRegisteringTeams() === 0 && nbOfWaitingListTeams() === 0

    const teamsLevel = () => game.level != null ? LEVEL[game.level].value : ""

    return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto pb-3">

        <div className="row border shadow-sm rounded px-3 py-2 mx-0 mb-3">

            <div className="col-8 col-md-10 px-0 my-auto" hidden={ game.visibility === "PUBLIC" }>
                <p className="fs-2 m-0">{ game.name }</p>
            </div>

            <div className="col-12 px-0 my-auto" hidden={ game.visibility === "PRIVATE" }>
                <p className="fs-2 m-0">{ game.name }</p>
            </div>

            <div className="col-4 col-md-2 text-end m-auto ps-3 pe-0" hidden={ game.visibility === "PUBLIC" }>
                <div className="d-flex justify-content-end">
                    <div className="row row-gap-2">
                        <div>
                            <div className="d-flex justify-content-end">
                                <button className="btn border-danger active w-100">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16" height="16"
                                        fill="currentColor"
                                        className="bi bi-incognito"
                                        viewBox="1 2 14 12"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="m4.736 1.968-.892 3.269-.014.058C2.113 5.568 1 6.006 1 6.5 1 7.328 4.134 8 8 8s7-.672 7-1.5c0-.494-1.113-.932-2.83-1.205l-.014-.058-.892-3.27c-.146-.533-.698-.849-1.239-.734C9.411 1.363 8.62 1.5 8 1.5s-1.411-.136-2.025-.267c-.541-.115-1.093.2-1.239.735m.015 3.867a.25.25 0 0 1 .274-.224c.9.092 1.91.143 2.975.143a30 30 0 0 0 2.975-.143.25.25 0 0 1 .05.498c-.918.093-1.944.145-3.025.145s-2.107-.052-3.025-.145a.25.25 0 0 1-.224-.274M3.5 10h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5m-1.5.5q.001-.264.085-.5H2a.5.5 0 0 1 0-1h3.5a1.5 1.5 0 0 1 1.488 1.312 3.5 3.5 0 0 1 2.024 0A1.5 1.5 0 0 1 10.5 9H14a.5.5 0 0 1 0 1h-.085q.084.236.085.5v1a2.5 2.5 0 0 1-5 0v-.14l-.21-.07a2.5 2.5 0 0 0-1.58 0l-.21.07v.14a2.5 2.5 0 0 1-5 0zm8.5-.5h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5"
                                        />
                                    </svg>{ "\t" }{ i18n.t("game." + game.visibility.toLowerCase()) }
                                </button>
                            </div>
                        </div>

                        <div>
                            <Link
                                className={ "btn w-100 " + (game.status !== "ENABLE" || !game.canManageRegistration ? "btn-secondary active" : "btn-primary") }
                                to={ "/" + "game-registry" + "/" + game.id }
                                target="_blank"
                                onClick={ onClickShare }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-send-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"/>
                                </svg>{ "\t" }{ i18n.t("game.share") }
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div className="px-1 my-4">
            <div className="col card border-2" style={ { alignSelf: "center" } }></div>
        </div>

        <div className="container px-0 ">
            <div className="row row-gap-4" data-masonry={ "percentPosition: true" }>

                <div className="col-md-6" ref={ refActionDiv }>
                    <div className="row row-gap-4 mx-0 px-0">

                        <div className="card px-0 shadow-sm">
                            <ShowcasePicture url={ record } onHandleModalUrl={ setModalUrl } roundedBottom={ false }/>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-10">
                                        { statusFromGame(game.status) }
                                    </div>
                                    <div className="col-2 ps-0">
                                        <button
                                            className="btn btn-danger btn-sm col-12 h-100"
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalGameDelete"
                                            data-bs-whatever="@mdo"
                                            disabled={ game.nbOfRegisterTeam > 0 }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="row row-gap-3 mt-3">
                                    <div>
                                        <button
                                            className="btn btn-sm btn-secondary col-12"
                                            onClick={ () => onHandleUpdate(game, record) }
                                        >{ i18n.t("button.update") }
                                        </button>
                                    </div>
                                    <div className="row row-gap-3 px-0 mx-0">
                                        <div className="col-12">
                                            { statusActionButton(game) }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card px-0 shadow-sm border border-primary">
                            <div className="card-body">
                                <blockquote className="blockquote">
                                    { i18n.t("game.organizer-management") }
                                </blockquote>
                                <button className="btn btn-primary" onClick={ () => onHandleManager(game) }>
                                    { i18n.t("managers.title") }
                                </button>
                            </div>
                        </div>

                        { haCard() }

                        <div className="card px-0 shadow-sm border-custom">
                            <div className="card-body">
                                <blockquote className="blockquote">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            { i18n.t("game.network-publish") }
                                        </div>
                                        <div>
                                            <button
                                                className="btn btn-bd-outline-custom"
                                                hidden={ !isAdmin(context) }
                                                onClick={ () => onHandleSocialNetwork(game) }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16"
                                                    fill="currentColor"
                                                    className="bi bi-gear"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </blockquote>
                                <button
                                    className="btn btn-bd-custom"
                                    onClick={ () => onHandleSocialNetworkPublish(game) }
                                    disabled={ medias.length > 0 || game.status !== "ENABLE" || game.visibility === "PRIVATE" }
                                >
                                    { i18n.t("game.publish") }
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-md-6">

                    <div className="row row-gap-4 mx-0 pb-4 px-0" ref={ refInfoDiv }>
                        <div className="card px-0 shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        { i18n.t("home.ground") }
                                    </div>
                                    <div>
                                        { capitalizeFirstLetter(game.ground.toLowerCase()) }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card px-0 shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        Date
                                    </div>
                                    <div>
                                        { startDate(game.startDate) }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card px-0 shadow-sm">
                            <div className="card-body">
                                { game.address }, { regionTranslation(region) }, { capitalizeFirstLetter(region.country) }
                            </div>
                        </div>

                        <div className="card px-0 shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div className="text-nowrap">
                                        { game.nbOfMember } { i18n.t("home.players") }
                                    </div>
                                    <div className="text-end">
                                        { gender(game) }
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between" hidden={ game.nbOfExtraMember === 0 }>
                                    <div hidden={ game.nbOfExtraMember !== 1 }>
                                        { i18n.t("common.additional-player") }
                                    </div>
                                    <div hidden={ game.nbOfExtraMember !== 1 }>
                                        { game.nbOfExtraMember } { i18n.t("common.accepted") }
                                    </div>

                                    <div hidden={ game.nbOfExtraMember < 2 }>
                                        { i18n.t("common.additional-players") }
                                    </div>
                                    <div hidden={ game.nbOfExtraMember < 2 }>
                                        { game.nbOfExtraMember } { i18n.t("common.accepteds") }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card px-0 shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        { i18n.t("home.price-player") }
                                    </div>
                                    <div className="my-auto" style={ { minWidth: "fit-content" } }>
                                        { formatPrice(game.playerPrice) }
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between" hidden={ game.nbOfExtraMember === 0 }>
                                    <div hidden={ game.nbOfExtraMember === 0 }>
                                        { i18n.t("home.price-additional-player") }
                                    </div>
                                    <div
                                        className="my-auto"
                                        hidden={ game.nbOfExtraMember === 0 }
                                        style={ { minWidth: "fit-content" } }
                                    >
                                        { formatPrice(game.playerPrice) }
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between"
                                     hidden={ game.dayOfEventPlayerPrice == null }>
                                    <div hidden={ game.dayOfEventPlayerPrice == null }>
                                        { i18n.t("home.price-at-day-of-event") }
                                    </div>
                                    <div
                                        className="my-auto"
                                        hidden={ game.dayOfEventPlayerPrice == null }
                                        style={ { minWidth: "fit-content" } }
                                    >
                                        { formatPrice(game.dayOfEventPlayerPrice) }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card px-0 shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        { i18n.t("level.name") }
                                    </div>
                                    <div>
                                        { level(game) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="card shadow-sm"
                        style={ { maxHeight: detailDivHeight } }
                        hidden={ game.detail == null || game.detail === "" }
                    >
                        <div className="card-header">{ i18n.t("common.detail") }</div>
                        <div className="card-body overflow-y-auto" style={ { maxHeight: detailDivHeight } }>
                            <Linkify properties={ { target: '_blank', style: { color: color, maxHeight: detailDivHeight } } }>
                                <div style={
                                    {
                                        whiteSpace: "pre-wrap",
                                        overflowWrap: "break-word"
                                    }
                                }>{ game.detail }</div>
                            </Linkify>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="px-1 my-4" hidden={ !game.canManageRegistration }>
            <div className="col card border-2" style={ { alignSelf: "center" } }></div>
        </div>

        <div className="row row-gap-4 mx-0 px-0" hidden={ !game.canManageRegistration }>

            <div className="card shadow-sm px-0">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <div>
                            { i18n.t("common.teams") }
                        </div>
                        <div>
                            { nbOfRegisteredTeams() }{ "\t" }/{ "\t" }{ game.nbOfTeam }
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row row-gap-3 mx-0">
                        <button
                            className="btn btn-primary"
                            style={ { width: "fit-content" } }
                            onClick={ onHandleTeamsView }
                            disabled={ disableCheckTeamButton() }
                        >
                            { i18n.t("common.check-teams") }
                        </button>
                        <div className="d-flex">
                            <div className="px-0">
                                <button
                                    className="btn btn-sm border-0"
                                    style={ { verticalAlign: "sub" } }
                                    disabled
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16" height="16"
                                        fill="currentColor"
                                        className="bi bi-arrow-return-right"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div className="row row-gap-3 mx-0 px-0">
                                <button
                                    className="btn btn-sm border"
                                    style={ { width: "fit-content" } }
                                >{ nbOfRegisteredTeams() } { i18n.t("common.validated-teams") }</button>
                                <div hidden={ game.level == null }>
                                    <button
                                        className="btn btn-sm border-0"
                                        style={ { verticalAlign: "sub" } }
                                        disabled
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16"
                                            fill="currentColor"
                                            className="bi bi-arrow-return-right"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                            />
                                        </svg>
                                    </button>
                                    <button className="btn btn-sm border">
                                        { i18n.t("level." + teamsLevel()) }
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="px-0">
                                <button
                                    className="btn btn-sm border-0"
                                    style={ { verticalAlign: "sub" } }
                                    disabled
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16" height="16"
                                        fill="currentColor"
                                        className="bi bi-arrow-return-right"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <button className="btn btn-sm border">
                                { nbOfRegisteringTeams() } { i18n.t("common.teams-being-registered") }
                            </button>
                        </div>
                        <div className={ "d-flex " + (game.canUseWaitingList ? "" : "visually-hidden") }>
                            <div className="px-0">
                                <button
                                    className="btn btn-sm border-0"
                                    style={ { verticalAlign: "sub" } }
                                    disabled
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16" height="16"
                                        fill="currentColor"
                                        className="bi bi-arrow-return-right"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <button className="btn btn-sm border">
                                { nbOfWaitingListTeams() } { i18n.t("common.waitlisted-teams") }
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div
            id="gameModal"
            className="modal fade"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="col">
                        <div className="z-0">
                            <img
                                src={ modalUrl }
                                className="card-img-top"
                                alt="tounament_image"
                            />
                        </div>
                        <div style={ { position: "absolute", top: "8px", right: "8px" } }>
                            <button
                                type="button"
                                className="z-1 btn btn-lg btn-danger shadow-sm"
                                data-bs-dismiss="modal"
                                style={ { paddingBottom: "9px", paddingTop: "5px" } }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-x m-auto"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="modalGameDelete"
            className="modal fade"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header p-4">
                            <span className="modal-title fs-5 me-4" id="deleteGameModalToggleLabel">
                                { i18n.t("home.sureToDeleteGame") } ?
                            </span>
                        <button
                            type="button"
                            className="btn-close ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body p-0 m-0">
                        <button
                            className="btn btn-danger col-12 rounded-top-0"
                            data-bs-dismiss="modal"
                            onClick={ () => onHandleDelete(game.id) }
                        >{ i18n.t("button.delete") }</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="socialNetworkModal"
            className="modal fade"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header pt-4">
                        <span className="modal-title fs-5 me-4" id="socialNetworkModalToggleLabel">
                            { i18n.t("game.tip-before-publish") }...
                        </span>
                        <button
                            type="button"
                            className="btn-close pe-4"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body py-0 px-0">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item py-3" hidden={ game.canManageRegistration }>
                                <h6>{ i18n.t("game.why-use-aot-for-registration") }?</h6>
                                <ul className="px-0 small lh-lg" style={ { listStyleType: "none" } }>
                                    <li>{ i18n.t("game.inform-registration-on-aot") }</li>
                                    <li>{ i18n.t("game.have-waiting-list") }</li>
                                    <li>{ i18n.t("game.have-early-access-registration") }</li>
                                    <li>{ i18n.t("game.have-player-level-validation") }</li>
                                    <li>{ i18n.t("game.accept-only-licensed-player") }</li>
                                    <li>{ i18n.t("game.addtional-player-management") }</li>
                                    <li>{ i18n.t("game.add-payment=with-helloasso") }</li>
                                </ul>
                            </li>
                            <li className="list-group-item py-3" hidden={ game.isPaymentAllow }>
                                <h6>{ i18n.t("game.if-you-use-helloasso") }...</h6>
                                <div className="small lh-lg">{ i18n.t("game.helloasso-connect-tips") }</div>
                            </li>
                            <li className="list-group-item pt-3 pb-2 text-center" hidden={ game.isPaymentAllow }>
                                <h6>{ i18n.t("game.warning-one-publication") }</h6>
                            </li>
                        </ul>
                    </div>
                    <div className="modal-footer p-0">
                        <button
                            className="btn btn-warning col-12 rounded-top-0 m-auto"
                            data-bs-dismiss="modal"
                            onClick={ () => publishToSocialNetwork(game) }
                        >{ i18n.t("game.publiah-anyway") }</button>
                    </div>
                </div>
            </div>
        </div>

        <InputGameAdvanced
            visibility={ game.visibility }
            isPaymentAllow={ game.isPaymentAllow }

            collapseRegistration={ game.canManageRegistration ? "collapse show" : "collapse hide" }

            canManageRegistration={ game.canManageRegistration }
            canUseWaitingList={ game.canUseWaitingList }
            shouldValidateLevel={ game.shouldValidateLevel }
            shouldAskPlayerLicensed={ game.shouldAskPlayerLicensed }

            nbOfExtraMember={ game.nbOfExtraMember }
            deadlineOfIndividualPayment={ game.deadlineOfIndividualPayment }

            dayOfEventPlayerPrice={ game.dayOfEventPlayerPrice != null ? game.dayOfEventPlayerPrice : "" }
            onHandleAdvanceSettings={ onHandleAdvanceSettings }
            onSave={ onHandleSaveAdvanceSettings }
        />
    </div>
}