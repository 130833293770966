import "dotenv/config"
import { APPLICATION_JSON, GET } from "../const/request"
import { refreshToken, resParser } from "../const/utils"
import { store } from "../repository/store"

class SessionService {

    async getAll(
        userId,
        page, size
    ){
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/sessions?id=" + userId

        const response = await fetch(path, {
            method: GET,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
        })

        return await resParser(response)
    }

}

export const sessionService = new SessionService()