{
  "navBar": {
    "home": "Accueil",
    "submitTournament": "Publiez un tournoi",
    "my-tournaments": "Mes tournois",
    "all-tournaments": "Tous les Tournois",
    "gameToValidate": "Jeux à valider",
    "contact": "Contact",
    "games": "Tournois",
    "users": "Utilisateurs",
    "login": "Connexion",
    "account": {
      "name": "Compte",
      "my-registrations": "Mes inscriptions",
      "my-payments": "Mes paiements",
      "setting": "Paramètre",
      "logout": "Déconnection"
    }
  },
  "sideBar": {
    "title": "Filtres",
    "keyword": "Mot clés",
    "region": {
      "name": "Région",
      "choose": "Choisir"
    },
    "date": {
      "startDate": "Date de début",
      "endDate": "Date de fin"
    },
    "search": "Rechercher"
  },
  "record": {
    "dragNDrop": "Glisser-déposer, ou cliquez pour sélectionner une image",
    "limit": "Limite",
    "picture-too-heavy": "Image trop lourde"
  },
  "common": {
    "hello-asso": {
      "payment-with": "Paiment via HelloAsso",
      "payment-disclaimer": "Le modèle solidaire de HelloAsso garantit que 100% de votre paiement sera versé à l’association choisie. Vous pouvez soutenir l’aide qu’ils apportent aux associations en laissant une contribution volontaire à HelloAsso au moment de votre paiement.",
      "payment-fail": "Votre paiment à échoué"
    },
    "yes": "Oui",
    "no": "Non",
    "to": "a",
    "at": "à",
    "of": {
      "default": "of",
      "d": "d'"
    },
    "and": "et",
    "new": "nouveau",
    "or": "ou",
    "more": "Plus",
    "choose": "Choisir",
    "hasBeen": "à été",
    "open": "Ouvrir",
    "register": "S'inscrire",
    "close": "Fermer",
    "copy": "Copié",
    "identifier": "Identifiant",
    "confirm": "Valider",
    "address": "Adresse",
    "phoneNumber": "Numéro de téléphone",
    "mail": "Email",
    "details": "Détails",
    "country": "Pays",
    "username": "Nom d'utilisateur",
    "user-tag": "Usertag",
    "organization-slug": "Organisation slug",
    "rna-number": "Numéro RNA",
    "password": {
      "name": "Mot de passe",
      "rule": {
        "1SpecialCharacter": "Un caractère spécial",
        "1UppercaseCharacter": "Une lettre en majuscule",
        "1LowercaseCharacter": "Une lettre en minuscule",
        "moreThan8Characters": "Plus de 8 lettres"
      }
    },
    "name": "Nom",
    "firstname": "Prénom",
    "lastname": "Nom de famille",
    "male": "Garçon",
    "female": "Fille",
    "other": "Autre",
    "view": "Visualiser",
    "team": "Équipe",
    "search": "Rechercher",
    "level": "Niveau",
    "captain": "Captaine",
    "team-name": "Nom d'équipe",
    "phone-number-verification": "Confirmer votre numéro de téléphone",
    "phone-number": "Numéro de téléphone",
    "finalizing-registration": "Finalisation de l'inscription",
    "license-ask": "En cochant cette case vous validez que tous les joueurs possedent une licence",
    "book-for": "Réserver pour",
    "payments": "Paiements",
    "payment-of": "Paiement du",
    "event": "Événement",
    "members": "Membres",
    "hourly": "Horaire",
    "settings": "Paramètres",
    "private": "Privé",
    "public": "Public",
    "filter": "Filtre",
    "no-result-found": "Aucun résultat trouvé",
    "mixed": "Mixte",
    "girl": "Fille",
    "boy": "Garçon",
    "delete": "Supprimer",
    "only-member-looking-for-a-team": "Uniquement les joueurs à la recherche d'une équipe",
    "looking-for-a-team": "À la recherche d'une équipe",
    "teams": "Équipes",
    "detail": "Détail",
    "whats-app-group-link": "Lien groupe What's App",
    "join-whats-app-group": "Rejoignez le groupe What's App",
    "send-message-to-captain": "Envoyer un message au captain",
    "nb-of-extra-member": "Nombre de membres supplémentaire",
    "nb-of-day-before": "Nombre de jour avant",
    "nb-of-day-before-definition": "Définir le nombre de jour avant l'événement pour la fini du paiement indivuduelle",
    "additional-player": "Joueur.se supplémentaire",
    "additional-players": "Joueurs.ses supplémentaires",
    "accepted": "accepté",
    "accepteds": "acceptés",
    "register-in-waiting-list": "S'inscrire sur liste d'attente",
    "waiting-list": "List d'attente",
    "auth-key-received-by-email": "Clé authorization reçu par mail",
    "on-request": "En attente de réponse",
    "on-requested": "En attente de votre réponse",
    "player-with": "joueur avec",
    "players-with": "joueurs avec",
    "check-teams": "Voir les équipes",
    "validated-teams": "Équipes validées",
    "teams-being-registered": "Équipes en cours d'enregistrement",
    "waitlisted-teams": "Équipes en liste d'attente",
    "your-payment-failed": "Votre paiement à échoué",
    "reserve-your-place": "Réserver votre place",
    "basket": "Panier",
    "team-registration": "Inscription équipe",
    "players": "Joueurs",
    "continue": "Continez",
    "payment-via-helloasso": "Paiement via HelloAsso",
    "helloass-business-model": "Le modèle solidaire de HelloAsso garantit que 100% de votre paiement sera versé à l’association choisie. Vous pouvez soutenir l’aide qu’ils apportent aux associations en laissant une contribution volontaire à HelloAsso au moment de votre paiement",
    "send-authorization-key": "Envoyer la clé d'autorisation",
    "registrations": "Inscriptions",
    "organization": "Organisation",
    "no-registrations-yet": "Aucune inscription trouvée",
    "no-payment-yet": "Aucun paiement trouvé",
    "team-level": "Niveau de l'équipe",
    "born-date": "Date de naissance",
    "gender": "Genre",
    "no-games-founded": "Aucun tournoi trouvé",
    "archive": "Archivé",
    "certified": "Certifié",
    "not-certified": "Non certifié"
  },
  "region": {
    "ANTWERP": "Anvers",
    "EAST_FLANDERS": "Flandre Orientale",
    "FLEMISH_BRABANT": "Brabant Flamand",
    "LIMBURG": "Limbourg",
    "WEST_FLANDERS": "Flandre Occidentale",
    "HAINAUT": "Hainaut",
    "LIEGE": "Liège",
    "LUXEMBOURG": "Luxembourg",
    "NAMUR": "Namur",
    "WALLOON_BRABANT": "Brabant Wallon",
    "BRUSSELS_CAPITAL_REGION": "Région de Bruxelles-Capitale"
  },
  "button": {
    "enable": "Valider",
    "activate": "Activé",
    "activated": "Activé",
    "disable": "Désactiver",
    "disabled": "Désactivé",
    "reOpen": "Reouvrir",
    "waitingForValidation": "En attente de validation",
    "add": "Ajouter",
    "update": "Modifier",
    "cancel": "Annuler",
    "delete": "Supprimer",
    "back": "Retour",
    "save": "Enregistrer",
    "send": "Envoyer",
    "advanced-settings": "Paramètre avancés",
    "register": "Réserver",
    "next": "Continuez"
  },
  "toast": {
    "error": {
      "NOT_FOUND": "Pas trouvé",
      "USER_NOT_FOUND": "L'utilisateur n'existe pas",
      "SESSION_NOT_FOUND": "La session n'existe pas",
      "GAME_NOT_FOUND": "Le tournoi n'existe pas",
      "AUTH_NOT_FOUND": "L'accréditation n'existe pas",
      "MEDIA_NOT_FOUND": "La publication n'existe pas",

      "INVALID_MAIL": "Le mail n'est pas valide",
      "MAIL_VALIDATION_FAIL": "Échec de la validation",
      "AUTHENTICATION_DENIED": "Accès refusé",
      "USER_VERIFICATION_FAILED": "Utilisateur pas encore vérifié",
      "INCORRECT_REASON": "Erreur sur le processus",
      "INVALID_GAME_STATUS": "Le tournoi est en statut non valide",
      "INVALID_UPDATE_IN_GAME_PAST": "Le tournoi est dans un statut passé",
      "INVALID_ROOT_USER_DISABLING": "L'utilisateur root ne peut pas être désactivé",
      "USER_VERIFIED": "Compte déjà vérifié",
      "USER_NOT_ENABLE": "L'utilisateur est désactivé",
      "CODE_NOT_FOUND": "Réessayez avec un autre code ou régénérez-en un",
      "USER_DUPLICATION": "Utilisateur déjà existant",
      "CREDENTIAL_DENIED": "Autorisation refusée",
      "FILE_SIZE": "Taille de fichier non valide",
      "USERNAME_ALREADY_EXIST": "Nom d'utilisateur déjà pris",
      "TEAM_NOT_FOUND": "L'équipe n'a pas été trouvée",
      "MEMBER_NOT_FOUND": "Le membre de l'équipe n'a pas été trouvé",
      "CHECKOUT_NOT_FOUND": "Le paiement n'a pas été trouvé",
      "INVALID_HA_ORGANIZATION": "L'organisation n'est pas valide",
      "HA_REFRESH_TOKEN_EXPIRED": "Les identifiants HelloAsso ont expirés",
      "REGISTRATION_DENIED": "La réservation n'est pas autorisée",
      "PAYMENT_PROVIDER_NOT_FOUND": "Lien HelloAsso rompu",
      "EXCEEDED_NB_OF_REGISTRATION": "Le nombre maximum d'inscrits est atteint",
      "FULLY_REGISTERED_WL_NOT_ALLOW": "Le nombre maximum d'inscrits a été atteint et la liste d'attente n'est pas autorisée",
      "EXCEEDED_NB_MALE_MEMBER": "Le nombre maximum de garçon(s) atteint",
      "EXCEEDED_NB_FEMALE_MEMBER": "Le nombre maximum de fille(s) atteint",
      "INVALID_NB_OF_EXTRA_MEMBER": "Le nombre de joueur supplémentaire n'est pas valide",
      "INVALID_LEVEL": "Le niveau n'est pas valide",
      "USER_ALREADY_IN_TEAM": "Le joueur fait déjà partie d'une équipe",
      "TEAM_NAME_ALREADY_TAKEN": "Le nom d'équipe est déjà pris",
      "CANCEL_NOT_ALLOW_PSA": "Annulation impossible, paiement validé ou en cours",
      "DELETE_NOT_ALLOW_PSA": "Suppression impossible, paiement validé ou en cours",
      "DELETE_NOT_ALLOW_MEMBER_PROCEED_PAYMENT": "Suppression impossible du joueur, paiement toujours en cours",
      "NB_OF_TEAM_PAYMENT_EXCEEDED": "Mise à jour impossible, le nombre d'équipes inscrites ou en cours d'inscription est supérieur au nouveau nombre d'équipes",
      "UPDATE_NOT_ALLOW_TEAM_REGISTERED": "Mise à jour impossible, les équipes sont inscrites ou en cours d'inscription",
      "REQUEST_MEMBER_NOT_FOUND": "Le joueur n'a pas été trouvé",
      "NO_NEW_TICKET_NEEDED": "Pas besoin d'autre réservation",
      "TOO_MUCH_TICKET_TO_REGISTER": "Le nombre de reservation supérieur au nombre de joueur dans l'équipe",
      "INVALID_GENDER_TO_REGISTER": "Genre non valide pour s'inscrire",
      "INVALID_USER_GENDER_TO_REGISTER": "Pour la réservation votre genre doit être soit féminin ou masculin",
      "CAPTAIN_NOT_FOUND": "Le captaine n'a pas été trouvé",
      "NOT_ALLOW_TO_REMOVE_PP": "Suppression impossible, les équipes sont inscrites ou en cours d'inscription",

      "server-error": "Une erreur de serveur inattendue s'est produit"
    },
    "success": {
      "user-created": "Utilisateur créé avec succès",
      "connected": "Connecté",
      "new-code-generated": "Nouveau code généré sur votre boîte mail",
      "password-update": "Mot de passe mis à jour"
    },
    "info": {
      "event-waiting-validate": "Événement en attente validation"
    }
  },
  "level": {
    "name": "Niveau",
    "leisure": "Loisir",
    "dep": "Départementale",
    "reg": "Régional",
    "nat": "National",
    "elite": "Élite",
    "pro": "Pro",
    "legend": "Légende"
  },
  "success": {
    "title": "Félicitation",
    "default-message": "Votre action a été validé",
    "team-created": "Votre équipe à été crée avec succes"
  },
  "error": {
    "message": "Désolé, une erreur inattendue s'est produite."
  },
  "advanced-settings": {
    "name": "Paramètre avancés",
    "public": "Public",
    "private": "Privé",
    "use-registration": "Utiliser Accrootournois pour les inscriptions",
    "waiting-list": "Liste d'attente",
    "level-validation": "Valider le niveau des joueurs à l'inscription",
    "accept-only-licensed-player": "Accepter uniquement les joueurs sous licence",
    "price-additional-player": "Prix du joueur supplémentaire",
    "price-at-day-of-event": "Prix du joueur le jour de l'événement"
  },
  "settings": {
    "name": "Paramètres",
    "theme": {
      "auto": "Auto",
      "light": "Éclairé",
      "dark": "Sombre"
    },
    "language": {
      "name": "Langue",
      "french": "Français",
      "english": "Anglais"
    }
  },
  "home": {
    "noGameFound": "Aucun jeu trouvé",
    "recently-added": "Nouveau",
    "weekOf": "Semaine du",
    "ground": "Terrain",
    "team-registered": "Nombre d'équipes inscritent",
    "players": "Joueurs",
    "price-player": "Prix par joueur",
    "price-additional-player": "Prix par joueur supplémentaire",
    "price-at-day-of-event": "Prix par joueur le jour de l'événement\n",
    "gender": {
      "onlyMale": "Uniquement garçon",
      "onlyFemale": "Uniqument fille",
      "unknown": "Choix multiples",
      "mixed": "Mixte",
      "male": "garçon",
      "female": "fille",
      "min": "minimum"
    },
    "deleteFilter": "Supprimer les filtres",
    "sureToDeleteGame": "Êtes-vous sûr de vouloir supprimer ce tournoi"
  },
  "login": {
    "title": "Veuillez vous connecter",
    "rememberMe": "Se souvenir",
    "signIn": "Se connecter",
    "continue-as-guest": "Continuez en tant qu'invité",
    "createAccount": "créer un compte",
    "passwordForgotten": "mot de passe oublié",
    "validAccount": "valider votre compte",
    "log-with-ha": "Se connecter via HelloAsso"
  },
  "registry": {
    "title": "Veuillez vous inscrire",
    "mailAddress": "Adresse e-mail",
    "passwordConfirmation": "Confirmer le mot de passe",
    "bornDate": "Date de naissance",
    "bornDateInput": "Date de naissance - ex: 1970-02-30",
    "registry": "Enregistrement",
    "rule": {
      "bornDate": {
        "userShouldBeOlderThan18": "L'utilisateur doit être âgé de plus de 18 ans"
      }
    }
  },
  "identifier": {
    "title": "Ajouter votre identifiant",
    "next": "Suivant"
  },
  "password": {
    "title": "Réinitialisation",
    "newPassword": "Nouveau mot de passe",
    "code": "code",
    "reset": "Réinitialiser",
    "newCode": "Nouveau code"
  },
  "code": {
    "title": "Verification",
    "code": "Code",
    "send": "Envoyer",
    "newCode": "Nouveau code"
  },
  "gameCardEdition": {
    "title": "Mes tournois",
    "newEvent": "Nouveau"
  },
  "games-all": {
    "title": "Tous les tournois"
  },
  "game": {
    "public": "Publique",
    "private": "Privé",
    "share": "Partager",
    "organizer-management": "Gerer les organisateurs",
    "connected": "Connecté",
    "use-helloasso": "Utiliser HelloAsso pour le paiment",
    "connect-helloasso": "Se connecter à HelloAsso",
    "link-helloasso": "Lier à votre compte HelloAsso",
    "helloasso-message": "HelloAsso aide les associations à collecter des paiements en ligne et propose ses services gratuitement. Elle prend à sa charge tous les frais de transaction pour que vous puissiez bénéficier de la totalité des sommes versées par vos publics, sans frais. Les contributions volontaires laissées par ces derniers sont leur unique source de revenus.",
    "activate-registration": "Activer l'inscription",
    "delete-payment-link": "Supprimer le lien de paiment",
    "network-publish": "Publier sur FaceBook et Instagram d'Accrootournois",
    "publish": "Publier",
    "tip-before-publish": "Astuces avant de publier sur les réseaux sociaux d'Accrootournois",
    "why-use-aot-for-registration": "Pourquoi utiliser Accrootournois pour la gestion d'inscription",
    "inform-registration-on-aot": "Informer la communauté des inscriptions sur Accrootournois",
    "have-waiting-list": "Avoir une liste d'attente",
    "have-early-access-registration": "Gerer des inscriptions anticipées",
    "have-player-level-validation": "Valider le niveau des joueurs à l'inscription",
    "accept-only-licensed-player": "Accepter uniquement les joueurs sous licence",
    "addtional-player-management": "Gestion de joueur supplémentaire",
    "add-payment=with-helloasso": "Ajout d'un paiment via HelloAsso",
    "if-you-use-helloasso": "Si vous souhaitez utiliser HelloAsso pour le paiement",
    "helloasso-connect-tips": "Il est préferable de vous connecter a HelloAsso avant la publication sur les réseaux sociaux, ainsi Accrootournois pourra informer les futurs joueurs comment proceder à l'inscription et paiment.",
    "warning-one-publication": "Attention une seule publication est possible",
    "publiah-anyway": "Publier quand même"
  },
  "gameEdition": {
    "title": "Organisez votre événement",
    "name": "Nom de l'événement",
    "address": "Adresse",
    "region": "Région",
    "start-at": "Début à",
    "end-at": "Fin à",
    "price": "Prix",
    "player": "Joueur",
    "phoneNumber": "Numero de téléphone",
    "mail": "Mail",
    "file": {
      "name": "Choisir une image",
      "noFile": "Pas d'image choisit"
    },
    "addImage": "Ajouter une image",
    "deleteImage": "Supprimer l'image",
    "nbOfTeam": "Nombre d'équipe",
    "nbOfGround": "Nombre de terrain",
    "nbOfPlayerByTeam": "Nombre de joueur par équipe",
    "minOfMale": "Nombre min de garçon",
    "minOfFemale": "Nombre min de fille",
    "detail": "Détail",
    "default-message": "Message par défaut",
    "email-sending-code": "Mail d'envoie du code",
    "code-send-by-mail": "Code envoyé par mail",
    "congrats":"Votre tournoi à bien été crée, il est en attente de validation"
  },
  "managers": {
    "title": "Organisateurs"
  },
  "gameToValidate": {
    "title": "Jeux en attentes de validation"
  },
  "user-settings": {
    "title": "Compte",
    "past-connexion": "Connexion",
    "connexion-card": {
      "date-of-connexion": "Date de connexion",
      "browser": "Navigateur",
      "device": "Appareil",
      "authentication-successful": "Authentification réussie"
    },
    "authorization": "Authorization"
  },
  "sessions": {
    "title": "Connexion"
  },
  "users":{
    "title": "Utilisateurs",
    "expired": "Expiré",
    "blocked": "Bloqué"
  },
  "social-network": {
    "title": "Média",
    "default": "Publication par défaut",
    "reminder": "Rappel",
    "full": "Complet",
    "capacity": "Places limitées",
    "network": "Réseau",
    "too-many-publication": "Pour effectuer plus de publication contacter l'administrateur d'accrootournois.fr"
  },
  "contact": {
    "title": "Contact",
    "submit": "Envoyer un mail",
    "emailAddress": "Adresse email"
  },
  "order-state": {
    "TO_PAY_ONLINE": "En attente du paiement",
    "PROCESSED": "Paiement effectué",
    "POA_PROCESSED": "Paiement sur place",
    "CANCELED": "Paiement annulé",
    "REFUNDED": "Paiement remboursé"
  },
  "team-status": {
    "payment-in-validation": "Paiement en validation",
    "remains-to-be-reserved": "Reste à réserver",
    "currently-booking": "En cours de réservation",
    "to-pay-on-arrival": "Réservé - A payer à l'arrivée",
    "REGISTERED": "Réservé",
    "WAITING_LIST": "En list d'attente",
    "CANCELED": "Réservation annulée"
  }
}