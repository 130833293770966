import 'react-toastify/dist/ReactToastify.css'
import '../css/root.css'

import Navbar from "../navbar/navbar"
import { Outlet } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { ErrorBoundary } from "./error-boudary"

export default function RootLayout() {

    return (
        <div className="root-layout">
            <ErrorBoundary>
                <header>
                    <Navbar/>
                </header>
                <main>
                    <Outlet/>
                </main>
            </ErrorBoundary>
            <ToastContainer
                position="bottom-right"
                autoClose={ 5000 }
                limit={ 3 }
                hideProgressBar={ false }
                newestOnTop
                closeOnClick
                rtl={ false }
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}