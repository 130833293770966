import translationFR from "../../public/locales/fr/translation.json"
import translationEN from "../../public/locales/en/translation.json"
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { handleUserLanguage } from "../const/utils"

const resources = {
    en: { translation: translationEN },
    fr: { translation: translationFR }
}

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: resources,
        debug: false,
        fallbackLng: handleUserLanguage(),

        ns: [ 'translation' ],
        defaultNS: 'translation',

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    }).then()


export default i18n