import i18n from "i18next"
import React from "react"
import { orderCard } from "./order"

const ORDER_STATUS = {
    "TO_PAY_ONLINE": 1,
    "PROCESSED": 2,
    "REFUNDED": 3,
    "CANCELED": 4
}

export default function PaymentsOffcanvas(props) {

    const id = props.id
    const users = !!props.users ? props.users : []

    const nameOfTheUser = (username) => {
        const user = users.find(user => user.username === username)

        return !!user ? user.firstname + " " + user.lastname : null
    }

    const orders = () => {
        if (props.orders.length === 0) return <React.Fragment></React.Fragment>

        return props.orders.sort(
            (o1, o2) => ORDER_STATUS[o1.payment.state] - ORDER_STATUS[o2.payment.state]
        ).map(
            it => <React.Fragment key={ it.id }>{ orderCard(it, nameOfTheUser(it.username), "shadow-sm") }</React.Fragment>
        )
    }

    return <div
        id={ id }
        className="offcanvas offcanvas-end"
        data-bs-scroll="false"
        tabIndex="-1"
    >
        <div className="offcanvas-header border-bottom shadow">
            <div className="row col-12 mx-0" style={ { height: "48px" } }>
                <div className="col-6 ps-0">
                    <div className="card shadow-sm py-0">
                        <div
                            className="card-body fs-6 fw-semibold py-0 px-1 text-center"
                            style={ { alignContent: "center", height: "46px" } }
                        >
                            { i18n.t("common.payments") }
                        </div>
                    </div>
                </div>
                <div className="col-6 pe-0">
                    <button
                        type="button"
                        className="btn btn-warning shadow-sm h-100 w-100"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16"
                            fill="currentColor"
                            className="bi bi-x-lg" viewBox="0 0 16 16"
                        >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div className="offcanvas-body">
            <div className="row row-gap-3 px-0 mx-0">{ orders() }</div>
        </div>
    </div>
}