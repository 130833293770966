import helloassopay from "../../public/hello-asso-pay-dark.svg"

import React from "react"

export default function ButtonHelloAssoPayment(props) {

    return <button
        className="btn btn-primary w-100 h-100 pt-3 rounded-top-0"
        onClick={ props.onClick }
        disabled={ props.disabled }
    >
        <img
            src={ helloassopay }
            alt="hello_asso"
        />
    </button>

}