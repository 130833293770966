import "dotenv/config"
import { APPLICATION_JSON, POST } from "../const/request"
import { resParser } from "../const/utils"

class TokenService {

    async token(
        authId,
        authCode,
        username,
        password
    ) {

        const headers = {
            "Content-Type": APPLICATION_JSON,
            "Authorization-Id": authId,
            "Authorization-Code": authCode,
        }

        const body = JSON.stringify({
            identifier: username,
            password: password
        })

        const path = process.env.BASE_URL + "/api/v1/auth/token"

        const response = await fetch(path, {
            method: POST,
            headers: headers,
            body,
        })

        return await resParser(response)
    }

    async createO2AuthToken(
        partner,
        code,
        codeVerifier,
        redirectUri
    ) {
        const body = JSON.stringify({
            partner: partner,
            code: code,
            codeVerifier: codeVerifier,
            redirectUri: redirectUri
        })

        const path = process.env.BASE_URL + "/api/v1/o2auth/token"

        const response = await fetch(path, {
            method: POST,
            headers: { "Content-Type": APPLICATION_JSON },
            body,
        })

        return await resParser(response)
    }

    async refreshToken(token) {
        const body = JSON.stringify({ value: token })

        const path = process.env.BASE_URL + "/api/v1/auth/refresh"

        const response = await fetch(path, {
            method: POST,
            headers: { "Content-Type": APPLICATION_JSON },
            body,
        })

        return await resParser(response)
    }
}

export const tokenService = new TokenService()