import "dotenv/config"
import helloassologo from "../../public/hello_asso_logo.svg"

import React from "react"
import { generatePKCE } from "../const/pkce"
import { store } from "../repository/store"

export class ButtonHelloAsso extends React.Component {

    constructor(props) {
        super(props)

        this.onHandleClick = this.onHandleClick.bind(this)

        this.stateParam = props.stateParam != null ? props.stateParam : ""
        this.redirectUri = props.redirectUri != null ? props.redirectUri : process.env.BASE_HA_REDIRECT_URL
        this.shadow = !!props.shadow ? " shadow-sm " : ""
    }

    async onHandleClick(_) {

        const clientId = process.env.HA_CLIENT_ID
        const path = process.env.HA_URL

        const { codeVerifier, codeChallenge } = await generatePKCE()

        /* TODO TO REMOVE START */
        store.data.o2Auth = {}
        store.data.o2Auth.ha = {}
        /* TODO TO REMOVE END */

        store.data.o2Auth.ha.codeVerifier = codeVerifier

        store.save()

        const clientIdParam = "client_id=" + clientId
        const redirectUriParam = "redirect_uri=" + this.redirectUri
        const codeChallengeParam = "code_challenge=" + codeChallenge
        const codeChallengeMParam = "code_challenge_method=S256"

        const stateParam = "state=" + this.stateParam

        const param = clientIdParam + "&" + redirectUriParam + "&" + codeChallengeParam + "&" + codeChallengeMParam
        const url = path + "?" + param + "&" + stateParam

        const windowFeatures = "top=100,width=500px,height=650px"

        window.open(url, '_parent', windowFeatures)
    }

    label() {
        return this.props.text != null ? this.props.text : "HelloAsso"
    }

    render() {
        return <button
            className={ "HaAuthorizeButton w-100" + this.shadow }
            onClick={ this.onHandleClick }
        >
            <img
                src={ helloassologo } alt="hello_asso"
                className="HaAuthorizeButtonLogo"
                style={ { width: "50px" } }
            />
            <span className="HaAuthorizeButtonTitle">{ this.label() }</span>
        </button>
    }
}