import i18n from "i18next"
import { usersCard } from "../const/common"
import React, { useContext, useEffect, useState } from "react"
import { Context } from "../const/context"

export const initGender = (event) => {
    const isOnlyMale = event.nbOfMember === event.minMaleInTeam
    const isOnlyFemale = event.nbOfMember === event.minFemaleInTeam
    const isMixed = !isOnlyMale && !isOnlyFemale

    if (isMixed) return "MIXED"
    if (isOnlyMale) return "MALE"
    if (isOnlyFemale) return "FEMALE"
}

export default function MembersSuggestion(props) {

    const context = useContext(Context)

    const event = props.event

    const filters = !!props.filters ? props.filters : {
        onlyInterestedMembers: false,
        gender: initGender(event)
    }

    const isOnlyMale = event.nbOfMember === event.minMaleInTeam
    const isOnlyFemale = event.nbOfMember === event.minFemaleInTeam

    const [ requestMembers, setRequestMembers ] = useState(props.requestMembers)
    const [ userSearch, setUserSearch ] = useState(props.userSearch)

    const [ onlyInterestedMembers, setOnlyInterestedMembers ] = useState(filters.onlyInterestedMembers)
    const [ gender, setGender ] = useState(filters.gender)

    useEffect(() => setRequestMembers(props.requestMembers), [ props.requestMembers ])
    useEffect(() => setUserSearch(props.userSearch), [ props.userSearch ])

    useEffect(() => props.onHandleFilter(
        {
            onlyInterestedMembers: onlyInterestedMembers,
            gender: gender
        }
    ), [ gender, onlyInterestedMembers ])

    return <div className="row row-gap-3 overflow-x-hidden mx-0 px-0">

        <div className="d-flex justify-content-between px-0">
            <input
                className="form-control border-primary rounded-end-0 shadow-sm"
                type="search"
                placeholder={
                    i18n.t("common.search") + ": " +
                    i18n.t("common.user-tag") + ", " +
                    i18n.t("common.name") + ", " +
                    i18n.t("common.mail")
                }
                aria-label="Search"
                value={ props.keyword }
                onChange={ (e) => props.setKeyword(e.target.value) }
            />
            <button
                className="btn btn-sm btn-primary rounded-start-0 shadow"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-filter"
                aria-expanded="false"
                aria-controls="collapse-filter"
            >
                <div className="d-flex h-100">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16" height="16"
                        fill="currentColor" className="bi bi-filter mx-1 h-100"
                        viewBox="0 0 16 16"
                        style={ { minWidth: "20px" } }
                    >
                        <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                    </svg>
                    <div className="mx-1 my-auto">
                        { i18n.t("common.filter") }
                    </div>
                </div>

            </button>

        </div>

        <div id="collapse-filter" className="collapse overflow-x-hidden rounded shadow-sm px-0">
            <div className="card card-body">
                <div className="row row-gap-3 mx-0 px-0">

                    <div className="d-flex border rounded p-2">
                        <div className="form-check form-switch">
                            <input
                                id="onlyInterestedMember"
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={ onlyInterestedMembers }
                                onChange={ () => setOnlyInterestedMembers(!onlyInterestedMembers) }
                                disabled={
                                    event.membersSearchingTeam.length === 0 ||
                                    (event.membersSearchingTeam.length === 1 && event.membersSearchingTeam.includes(context.user.userId))
                                }
                            />
                            <label
                                htmlFor="onlyInterestedMember"
                                className="form-check-label w-100"
                            >
                                { i18n.t("common.only-member-looking-for-a-team") }
                            </label>
                        </div>
                    </div>

                    <div className="d-flex border rounded p-2">
                        <div className="form-check form-check-inline">
                            <input
                                id="filter-mixte"
                                className="form-check-input"
                                type="radio"
                                name="filter-gender"
                                value="MIXTE"
                                checked={ gender === "MIXED" }
                                onChange={ () => setGender("MIXED") }
                                disabled={ isOnlyMale || isOnlyFemale }
                            />
                            <label className="form-check-label" htmlFor="filter-mixte">
                                { i18n.t("common.mixed") }
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                id="filter-girl"
                                className="form-check-input"
                                type="radio"
                                name="filter-gender"
                                value="FEMALE"
                                checked={ gender === "FEMALE" }
                                onChange={ () => setGender("FEMALE") }
                                disabled={ isOnlyMale }
                            />
                            <label className="form-check-label" htmlFor="filter-girl">
                                { i18n.t("common.girl") }
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                id="filter-boy"
                                className="form-check-input"
                                type="radio"
                                name="filter-gender"
                                value="MALE"
                                checked={ gender === "MALE" }
                                onChange={ () => setGender("MALE") }
                                disabled={ isOnlyFemale }
                            />
                            <label className="form-check-label" htmlFor="filter-boy">
                                { i18n.t("common.boy") }
                            </label>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        { usersCard(requestMembers, props.removeButton) }
        { usersCard(userSearch, props.addButton) }

        <div className="card shadow-sm emergence" hidden={ requestMembers.length > 0 || userSearch.length > 0 }>
            <div className="card-body text-center py-4">
                { i18n.t("common.no-result-found") }
            </div>
        </div>
    </div>
}