import { store } from "../repository/store"
import { APPLICATION_JSON, GET, POST } from "../const/request"
import { refreshToken, resParser } from "../const/utils"
import { ordersTransformers, orderTransformer } from "./order-service"

class TeamOrderService {

    async create(
        gameId,
        teamId,
        nbOfTicket,
        backUrl, errorUrl, returnUrl
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/events/" + gameId + "/teams/" + teamId + "/orders"

        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }

        const body = JSON.stringify({
            nbOfTicket: nbOfTicket,
            backUrl: process.env.BASE_URL + backUrl,
            errorUrl: process.env.BASE_URL + errorUrl,
            returnUrl: process.env.BASE_URL + returnUrl
        })

        const response = await fetch(path, {
            method: POST,
            headers: headers,
            body: body
        })

        return orderTransformer(await resParser(response))
    }

    async getTeamOrders(
        gameId,
        teamId
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/events/" + gameId + "/teams/" + teamId + "/orders"

        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }

        const response = await fetch(path, {
            method: GET,
            headers: headers,
        })

        return ordersTransformers(await resParser(response))
    }
}

export const teamOrderService = new TeamOrderService()