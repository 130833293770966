import i18n from "i18next"

import React, { useContext } from "react"
import { disconnect, isNotConnected } from "../const/utils"
import { userService } from "../service/user-service"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from "../const/context"
import Login from "./login"
import { capitalizeFirstLetter, pictureByGender } from "../const/common"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    if (isNotConnected(context)) return <Login/>

    return <Users { ...props } navigate={ navigate } location={ location } context={ context }/>
}

class Users extends React.Component {

    constructor(props) {
        super(props)

        this.navigate = this.props.navigate

        this.state = {
            keyword: "",
            users: []
        }

        this.getAll = this.getAll.bind(this)

        this.onHandleKeyword = this.onHandleKeyword.bind(this)
        this.onHandleDeleteKeyword = this.onHandleDeleteKeyword.bind(this)
        this.onHandleDisable = this.onHandleDisable.bind(this)
    }

    componentDidMount() {
        this.getAll()
    }

    getAll(keyword) {
        userService.getAll(keyword).then(it => {

            if (it.status === 401) return disconnect(this.navigate, this.props.context, this.props.location.pathname)
            if (it.status === 403) return this.navigate("/home")

            this.setState((state, _) => ({ users: it }))
        })
    }

    onHandleKeyword(e) {
        const keyword = e.target.value
        this.setState(
            (state, _) => ({ keyword: keyword }),
            () => { this.getAll(keyword) }
        )
    }

    onHandleDeleteKeyword(_) {
        const currentKeyword = this.state.keyword

        this.setState(
            (state, _) => ({ keyword: "" }),
            () => {
                if (this.state.keyword !== currentKeyword) this.getAll()
            }
        )
    }

    async onHandleDisable(user) {
        const res = await userService.updateLock(user.id, !user.accountLocked)

        if (res.status === 401) return disconnect(this.navigate, this.props.context, this.props.location.pathname)
        else if (res.status === 403) return this.navigate("/home")
        else if (res.error != null || res.httpCode != null) return

        const users = this.state.users

        const index = users.findIndex(it => it.id === res.id)

        users[index] = res

        this.setState(() => ({ users: users }))
    }

    users() {
        return this.state.users.reverse().sort(
            it => it.username
        ).map(
            it => <React.Fragment key={ it.username }>{ this.userDetail(it) }</React.Fragment>
        )
    }

    yesOrNot(value) {
        return value ? "Oui" : "Non"
    }

    status = (isEnable) => {
        if (isEnable) return <button
            type="button"
            className="btn btn-outline-success btn-sm active col-12 rounded-bottom-0 border-0"
        >
            { i18n.t("button.activate") }
        </button>

        return <button
            type="button"
            className="btn btn-outline-warning btn-sm active col-12 rounded-bottom-0 border-0"
        >
            { i18n.t("button.disabled") }
        </button>
    }

    lockButton(user) {
        if (user.accountLocked) return <button
            className="btn btn-success btn-sm col-12"
            onClick={ () => this.onHandleDisable(user) }
        >
            { i18n.t("button.activate") }
        </button>

        return <button
            className="btn btn-warning btn-sm col-12 px-0"
            onClick={ () => this.onHandleDisable(user) }
        >
            { i18n.t("button.disable") }
        </button>
    }

    async onHandleNavigateAccount(id) {
        const user = await userService.getById(id)

        this.navigate("/user-settings", { state: { user: user } })
    }

    userDetail(user) {
        return <div className="col-12 col-md-4 emergence">
            <div className="modal-content border rounded-2 shadow-sm fw-medium h-100">
                <div className="row row-gap-3">
                    <div className="col-12">
                        <div className="row row-gap-3 m-auto">
                            { this.status(user.isEnable) }
                            <div className="rounded text-center">
                                <img
                                    src={ pictureByGender(user) }
                                    className="img-thumbnail border-0 bg-transparent w-50"
                                    alt="user_image"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pb-2">
                        <div className="row row-gap-3 px-0 mx-0">
                            <div className="row row-gap-3 row-cols-3 m-0 p-0">
                                <div className="col-12 col-xl-6">
                                    <button
                                        className="btn btn-secondary btn-sm col-12"
                                        onClick={ () => this.onHandleNavigateAccount(user.id) }
                                    >
                                        { i18n.t("button.update") }
                                    </button>
                                </div>
                                <div className="col-12 col-xl-6 ps-xl-0">
                                    <div className="row">
                                        <div className="col-9">
                                            { this.lockButton(user) }
                                        </div>
                                        <div className="col-3 ps-0">
                                            <button
                                                className="btn btn-danger btn-sm col-12 px-0"
                                                data-bs-toggle="modal"
                                                // data-bs-target={ "#modalGameDelete" + it.id }
                                                // data-bs-whatever="@mdo"
                                                disabled
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-trash3"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { this.user(user) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    user(user) {
        if (user.type === "O2AUTH" && user.partner === "HELLO_ASSO") return this.helloAsso(user)

        return this.defaultUser(user)
    }

    helloAsso(user) {
        return <div className="row row-gap-1 text-break m-0 p-0">
            <div>
                <div className="row row-gap-1 small">
                    <div>
                        { capitalizeFirstLetter(user.username) }
                    </div>
                    <div>
                        { user.rnaNumber }
                    </div>
                </div>
                <div className="col card mt-2" style={ { alignSelf: "center" } }></div>
            </div>
            <div className="row row-gap-1 m-auto px-0 small">
                <div className="d-flex justify-content-between">
                    <div>
                        { user.userTag }
                    </div>
                    <div>
                        { user.bornDate }
                    </div>
                </div>
                <div className="row row-gap-1 m-0 p-0">
                    <div>
                        <a
                            className="link-offset-2 link-underline link-underline-opacity-0"
                            target="_blank"
                            href={ user.haUrl }
                        >
                            HelloAsso { i18n.t("common.organization") }
                        </a>
                    </div>
                    <div>
                        { i18n.t("users.expired") }: { this.yesOrNot(user.accountExpired) }
                    </div>
                    <div>
                        { i18n.t("users.blocked") }: { this.yesOrNot(user.accountLocked) }
                    </div>
                    <div>
                        { i18n.t("common.certified") }: { this.yesOrNot(user.isCertified) }
                    </div>
                </div>
            </div>
        </div>
    }

    defaultUser(user) {
        return <div className="row row-gap-1 text-break m-0 p-0">
            <div>
                <div className="row row-gap-1 small">
                    <div>
                        { user.firstname } { user.lastname }
                    </div>
                    <div>
                        { user.username }
                    </div>
                </div>
                <div className="col card mt-2 mb-1" style={ { alignSelf: "center" } }></div>
            </div>
            <div className="row row-gap-1 m-0 p-0 small">
                <div>
                    UserTag: { user.userTag }
                </div>
                <div>
                    { i18n.t("common.born-date") }: { user.bornDate }
                </div>
                <div>
                    { i18n.t("common.gender") }: { capitalizeFirstLetter(user.gender.toLowerCase()) }
                </div>
                <div>
                    { i18n.t("users.expired") }: { this.yesOrNot(user.accountExpired) }
                </div>
                <div>
                    { i18n.t("users.blocked") }: { this.yesOrNot(user.accountLocked) }
                </div>
            </div>
        </div>
    }

    render() {
        return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto">

            <p className="fs-2 m-0 border rounded shadow-sm rounded px-3 py-2">{ i18n.t("users.title") }</p>

            <div className="px-1 my-4">
                <div className="col card border-2" style={ { alignSelf: "center" } }></div>
            </div>

            <div className="input-group shadow-sm rounded col-12">
                <input
                    className="form-control"
                    type="text"
                    placeholder={ i18n.t("sideBar.keyword") }
                    value={ this.state.keyword }
                    onChange={ this.onHandleKeyword }
                />
                <button
                    id="deleteField-keyword"
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={ this.onHandleDeleteKeyword }
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-x-lg"
                        viewBox="0 0 16 16"
                        style={ { pointerEvents: "none" } }
                    >
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </button>
            </div>

            <div className="row row-gap-3 py-3">
                { this.users() }
            </div>

        </div>
    }
}