import "../css/home.css"
import i18n from "i18next"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"

import React, { useContext, useEffect, useState } from "react"
import { Context } from "../const/context"
import GameCard from "../component/game-card"
import { disconnect, isNotConnected, searchByParams } from "../const/utils"
import moment from "moment"
import "moment/locale/fr"
import {
    DATE_FORMAT,
    LEVEL,
    monthFormat,
    removeObjectWithId,
    SPACE
} from "../const/common"
import { store } from "../repository/store"
import { notifierService } from "../service/notifier-service"
import { useLocation, useNavigate } from "react-router-dom"
import { recordService } from "../service/record-service"
import { PActionButton } from "../component/action-button"
import { eventTransformer, gameService } from "../service/game-service"
import GameBodyOffcanvas from "../component/game-body-offcanvas"
import { userService } from "../service/user-service"

function date(from, to) {
    const month = window.mobileCheck ? "MMM" : "MMMM"

    const fromDate = from.locale(i18n.language)
    const fromDay = fromDate.format("D")
    const fromMonth = monthFormat(fromDate.format(month))
    const fromYear = fromDate.format("YYYY")

    const toDate = to.locale(i18n.language)
    const toDay = toDate.format("D")
    const toMonth = monthFormat(toDate.format(month))
    const toYear = toDate.format("YYYY")

    const fromFormatted = fromDay + SPACE + fromMonth + SPACE + fromYear
    const toFormatted = toDay + SPACE + toMonth + SPACE + toYear

    return fromFormatted + SPACE + " - " + toFormatted
}

function dateWithoutYear(from, to) {
    const month = window.mobileCheck ? "MMM" : "MMMM"

    const fromDate = from.locale(i18n.language)
    const fromDay = fromDate.format("D")
    const fromMonth = monthFormat(fromDate.format(month))

    const toDate = to.locale(i18n.language)
    const toDay = toDate.format("D")
    const toMonth = monthFormat(toDate.format(month))

    const fromFormatted = fromDay + SPACE + fromMonth
    const toFormatted = toDay + SPACE + toMonth

    return fromFormatted + " - " + toFormatted + SPACE + from.format("YYYY")
}

function dateWithoutMonthAndYear(from, to) {
    const month = window.mobileCheck ? "MMM" : "MMMM"

    const fromDate = from.locale(i18n.language)
    const fromDay = fromDate.format("D")
    const fromMonth = monthFormat(fromDate.format(month))
    const fromYear = fromDate.format("YYYY")

    const toDay = to.locale(i18n.language).format(" D")

    return fromDay + SPACE + " - " + toDay + SPACE + fromMonth + SPACE + fromYear
}

export default function Home() {

    const href = window.location.search
    const search = href.split("?")[1]
    const params = new URLSearchParams(search)

    const sort = {
        createdDate: params.get("createdDate")
    }

    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    const [ emittedTime, setEmittedTime ] = useState(null)

    const [ requestTime, setRequestTime ] = useState(null)

    const [ games, setGames ] = useState([])
    const [ records, setRecords ] = useState([])

    const [ modalUrl, setModalUrl ] = useState(null)
    const [ offCanvasGame, setOffCanvasGame ] = useState(null)

    const [ users, setUsers ] = useState([])

    const updateRecords = async (game) => {
        const newItemsRecords = await recordService.search([ game.id ], [])

        if (newItemsRecords.length > 0) {

            const record = newItemsRecords[0]

            context.search.records.push(record)

            setRecords([ ...context.search.records ])
        }
    }

    const updateGames = async (game) => {
        const isSameGame = (it) => it.id === game.id

        const indexOfUpdatedGame = context.search.games.findIndex(isSameGame)

        if (indexOfUpdatedGame === -1) {
            const search = store.data.search
            const dates = search.dates

            const startDate = dates.startDate != null ? moment(dates.startDate, DATE_FORMAT).format(DATE_FORMAT) : moment()
            const endDate = dates.endDate != null ? moment(dates.endDate, DATE_FORMAT).format(DATE_FORMAT) : moment()

            const gameStartDate = moment(game.startDate, DATE_FORMAT)
            const gameEndDate = moment(game.endDate, DATE_FORMAT)

            if (gameStartDate.isBefore(startDate) && gameEndDate.isBefore(endDate)) return

            context.search.games.push(game)
            await updateRecords(game)
        } else context.search.games[indexOfUpdatedGame] = game

        setGames([ ...context.search.games ])
    }

    const updateOffCanvasGame = (game) => {
        if (offCanvasGame == null || game.id !== offCanvasGame.id) return

        setOffCanvasGame(game)
    }

    useEffect( () => {
        async function fetchData() {

            const contextGames = context.search.games
            const contextRecords = context.search.records
            const contextRequestTime = context.search.requestTime

            if (requestTime == null) {
                const newRequestTime = moment().toDate()
                /* FIRST CHANGE REQUEST TIME TO STOP UNLESS REFRESH */
                setRequestTime(newRequestTime)

                const res = await searchByParams()

                const gamesRes = res.games
                const recordsRes = res.records

                context.search.games = gamesRes
                context.search.records = recordsRes
                context.search.requestTime = newRequestTime

                setGames([...gamesRes])
                setRecords([...recordsRes])
                setOffCanvasGame(gamesRes[0])
            } else if (moment(requestTime).isBefore(contextRequestTime)) {
                setRequestTime(contextRequestTime)
                setGames([...contextGames])
                setRecords([...contextRecords])
                setOffCanvasGame(contextGames[0])
            }
        }

        /* FIRST FETCH */
        fetchData().then()
        /* THEN FETCH EACH TIME */
        const timer = setInterval( () => { fetchData().then() }, 100)

        return () => clearInterval(timer)
    }, [ requestTime, games, records ])

    useEffect(() => {
        if (games.length === 0) return

        async function fetchData() {
            const userIdsToSearch = games.flatMap(
                it => it.teams
            ).filter(
                it => it.type === "TEAM_DEFAULT"
            ).flatMap(
                it => it.members
            ).flatMap(
                it => it.userId
            )

            const res = userIdsToSearch.length > 0 ? await userService.search(
                userIdsToSearch,
                null,
                null,
                null,
                "USER_DEFAULT",
                null,
                [],
            ) : []

            setUsers(res)
        }

        fetchData().then()
    }, [ games ])

    useEffect(() => {

        const fetchNotifierTime = async () => {
            const res = await notifierService.notifierTime()

            setEmittedTime(res.data)
        }

        const fetchData = async () => {
            const res = await notifierService.notifier(emittedTime)

            const isUnauthorized = res.status === 401 || res.status === 403 || res.httpCode === 403

            if (isUnauthorized) return disconnect(navigate, context, location.pathname)
            else if (res.status > 500) return

            res.map(it => {
                setEmittedTime(it.emittedTime)

                const content = it.data
                const type = it.type

                if (type !== "GAME_UPDATED" || content.status !== "ENABLE") return

                const game = eventTransformer(content)
                updateGames(game)
                updateOffCanvasGame(game)
            })
        }

        if (emittedTime == null) fetchNotifierTime().then()

        const timer = setInterval(
            () => { if (emittedTime != null) fetchData().then() },
            5000
        )

        return () => clearInterval(timer)
    }, [ emittedTime, games, offCanvasGame ])

    const onClickDeleteFilter = async (_) => {
        store.data.search = {
            keyword: "",
            region: "CHOOSE",
            dates: {
                startDate: moment(),
                endDate: null
            },
            level: {
                min: LEVEL.OUT_OF_COMPETITION,
                max: LEVEL.LEGEND
            },
            groundSelected: {
                indoor: true,
                beach: true,
                green: true,
                water: true,
                snow: true
            }
        }

        store.save()

        window.location.reload()
    }

    const onHandleRegistry = (game) => navigate("/game-registry/" + game.id)

    const memberSearchingForTeam = async (
        game,
        isSearching
    ) => {
        const action = isSearching ? "ADD" : "DELETE"

        const res = await gameService.memberSearchingTeam(game.id, action)

        if (res.status === 401) return disconnect(navigate, context, location.pathname)
        else if (res.error != null || res.httpCode != null) return

        updateOffCanvasGame(res)
        await updateGames(res)
    }

    const isDisableRegisterButton = (event) => {
        const nbOfMember = event.nbOfMember
        const minMaleInTeam = event.minMaleInTeam
        const minFemaleInTeam = event.minFemaleInTeam

        const isOnlyMale = nbOfMember === minMaleInTeam
        const isOnlyFemale = nbOfMember === minFemaleInTeam

        const currentType = context.user.type
        const currentUserGender = context.user.gender

        const isNotUserDefault = currentType != null && currentType !== "DEFAULT"
        const isOnlyMaleAndIAMFemale = isOnlyMale && currentUserGender === "FEMALE"
        const onlyFemaleAndIAMMale = isOnlyFemale && currentUserGender === "MALE"

        return isOnlyMaleAndIAMFemale || onlyFemaleAndIAMMale || isNotUserDefault
    }

    const offCanvas = () => {

        if (offCanvasGame == null) return <React.Fragment></React.Fragment>

        const isSearchForTeam = offCanvasGame.membersSearchingTeam.includes(context.user.userId)

        const isFull = offCanvasGame.teams.length === offCanvasGame.nbOfTeam

        const canManageRegistration = offCanvasGame.canManageRegistration
        const isWaitingListAllowed = offCanvasGame.canUseWaitingList

        const isVisibleRegisterButton = canManageRegistration && !isFull
        const isVisibleWaitingListButton = canManageRegistration && isFull && isWaitingListAllowed
        const isVisibleDetailButton = !isVisibleRegisterButton && !isVisibleWaitingListButton

        return <div
            id="offcanvas"
            className="offcanvas offcanvas-end small"
            data-bs-scroll="false"
            tabIndex="-1"
        >
            <div className="offcanvas-header border-bottom shadow">
                <div className="row row-gap-3 col-12 mx-0 px-0">
                    <div className="row col-12 mx-0 px-0" style={ { height: "48px" } }>
                        <div className="col-6 py-0 my-0 ps-0" hidden={ !isVisibleRegisterButton }>
                            <PActionButton
                                clazzname="w-100 h-100 fw-semibold"
                                onClick={ () => onHandleRegistry(offCanvasGame) }
                                dataBsDismiss="offcanvas"
                                ariaLabel="Close"
                                style={ { borderRadius: "0.375rem" } }
                                disabled={ isDisableRegisterButton(offCanvasGame) }
                            >
                                { i18n.t("common.register") }
                            </PActionButton>
                        </div>
                        <div className="col-6 py-0 my-0 ps-0" hidden={ !isVisibleWaitingListButton }>
                            <button
                                className="btn btn-primary shadow-sm w-100 h-100"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                onClick={ () => onHandleRegistry(offCanvasGame) }
                            >
                                { i18n.t("common.waiting-list") }
                            </button>
                        </div>
                        <div className="col-6 ps-0" hidden={ !isVisibleDetailButton }>
                            <div className="card shadow-sm py-0">
                                <div
                                    className="card-body fs-6 fw-semibold py-0 px-1 text-center"
                                    style={ { alignContent: "center", height: "46px" } }
                                >
                                    { i18n.t("common.details") }
                                </div>
                            </div>
                        </div>
                        <div className="col-6 pe-0">
                            <button
                                type="button"
                                className="btn btn-warning shadow-sm h-100 w-100"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-x-lg" viewBox="0 0 16 16"
                                >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="col-12 px-0"
                         hidden={ isNotConnected(context) || !offCanvasGame.canManageRegistration }>
                        <div className="card">
                            <div className="card-body py-2">
                                <div
                                    className="form-check form-switch form-check my-0"
                                    style={ { alignContent: "center" } }
                                >
                                    <input
                                        id="membersSearchingTeam"
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={ isSearchForTeam }
                                        onChange={ () => memberSearchingForTeam(offCanvasGame, !isSearchForTeam) }
                                        disabled={ isDisableRegisterButton(offCanvasGame) }
                                    />
                                    <label className="form-check-label w-100" htmlFor="membersSearchingTeam">
                                        { i18n.t("common.looking-for-a-team") }
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GameBodyOffcanvas game={ offCanvasGame } users={ users }/>
        </div>
    }

    function gamesSorted() {
        const sortedGames = games.sort((first, second) => new Date(second.createdDate) - new Date(first.createdDate))

        return oddGamesOfWeek(sortedGames)
    }

    const gamesByWeeks = () => groupGamesByWeek(
        games.sort((first, second) => new Date(second.startDate) - new Date(first.startDate)),
        context.user.userId,
        context.user.isAdmin
    ).map(it => {
        const dates = it.id.split("-")

        const from = moment(dates[0], "YYYY/MM/DD")
        const to = moment(dates[1], "YYYY/MM/DD")

        const isSameMonth = from.month() === to.month()
        const isSameYear = from.year() === to.year()

        const dateRes = isSameMonth ? dateWithoutMonthAndYear(from, to) : isSameYear ? dateWithoutYear(from, to) : date(from, to)

        const sortedGames = it.value.sort((first, second) => moment(first.startDate) - moment(second.startDate))

        return <React.Fragment key={ it.id }>

            <div className="row mb-3 mx-1 emergence">
                <div className="col card" style={ { alignSelf: "center" } }></div>

                <div className="text-center small text-secondary px-2 pb-1" style={ { maxWidth: "fit-content" } }>
                    { i18n.t("home.weekOf") } { dateRes }
                </div>

                <div className="col card" style={ { alignSelf: "center" } }></div>
            </div>

            { gamesOfWeek(sortedGames) }
        </React.Fragment>
    })

    function groupGamesByWeek(
        games,
        userId,
        isAdmin
    ) {
        let gamesByWeek = []

        games.filter(it => {
            return (it.status === "ENABLE" || it.managers.includes(userId)) || isAdmin
        }).forEach(game => {
            const date = new Date(game.startDate)

            const day = date.getDay()

            const from = date.getDate() - day + (day === 0 ? -6 : 1)

            const firstDay = new Date(date.setDate(from))
            const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + 6);

            const index = moment(firstDay).format("YYYY/MM/DD") + "-" + moment(lastDay).format("YYYY/MM/DD")

            const object = gamesByWeek.filter(it => it.id === index)[0]

            if (object != null) removeObjectWithId(gamesByWeek, index)

            const value = object == null ? [ game ] : [].concat(object.value, game)

            gamesByWeek.unshift({ id: index, value: value })
        })

        return gamesByWeek
    }

    function gamesOfWeek(games) {
        if (games.length === 1) return <React.Fragment> { gamesRender(games) } </React.Fragment>

        if (games.length % 2 === 0) return oddGamesOfWeek(games)

        const lastGame = games.pop()

        const splitGames = gamesSplitter(games)

        const first = splitGames.first
        const second = splitGames.second

        return <React.Fragment>
            <div className="row row-cols-1 row-cols-md-2">
                <div className="col-12">
                    { gamesRender(first) }
                </div>
                <div className="col-12">
                    { gamesRender(second) }
                </div>
            </div>
            <div className="col-12">
                { gameRender(lastGame) }
            </div>
        </React.Fragment>
    }

    function gamesSplitter(games) {

        if (window.mobileCheck) return splitByMiddle(games)

        const even = []
        const odd = [];

        games.forEach((it, i, _) => {
            if ((i + 2) % 2 === 0) even.push(it)
            else odd.push(it)
        })

        return { first: even, second: odd }
    }

    function splitByMiddle(games) {
        const midIndex = Math.floor(games.length / 2)
        const [ first, ...second ] = [ games.slice(0, midIndex), ...games.slice(midIndex) ];
        return { first: first, second: second }
    }

    function oddGamesOfWeek(games) {

        const splitGames = gamesSplitter(games)

        const first = splitGames.first
        const second = splitGames.second

        return <React.Fragment>
            <div className="row row-cols-1 row-cols-md-2">
                <div className="col-12">
                    { gamesRender(first) }
                </div>
                <div className="col-12">
                    { gamesRender(second) }
                </div>
            </div>
        </React.Fragment>
    }

    const gamesRender = (value) => value.map(it => gameRender(it))

    function gameRender(game) {
        const id = game.id

        const record = records.filter(it => it.mapperId === id)

        const url = record.length === 1 ? record[0].url : null

        return <div className="mb-4" key={ id }>
            <GameCard
                game={ game }
                url={ url }
                onHandleModalUrl={ setModalUrl }
                onHandleOffCanvasGame={ setOffCanvasGame }
            />
        </div>
    }

    const isNoGame = games == null || games.length === 0

    if (isNoGame) return <React.Fragment>
        <div className="text-center">
            <div
                className="col-xl-7 col-lg-7 col-md-9 col-sm-12 shadow-sm m-auto border py-4 rounded text-center fs-5 fw-semibold emergence">
                { i18n.t("home.noGameFound") }
            </div>
            <div className="col-xl-7 col-lg-7 col-md-9 col-sm-12 mx-auto emergence">
                <button
                    className="btn btn-danger mt-4 col-12 shadow-sm"
                    type="button"
                    onClick={ onClickDeleteFilter }
                >{ i18n.t("home.deleteFilter") }</button>
            </div>
        </div>
    </React.Fragment>

    const gamesToDisplay = sort.createdDate === "desc" ? gamesSorted() : gamesByWeeks()

    return <div className="col-xl-7 col-lg-7 col-md-9 col-sm-12 mx-auto pb-1">
        <div>{ gamesToDisplay }</div>
        <div
            id="gameModal"
            className="modal fade"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="col">
                        <div className="z-0 shine">
                            <img
                                src={ modalUrl }
                                className="card-img-top"
                                alt="tounament_image"
                            />
                        </div>
                        <div style={ { position: "absolute", top: "8px", right: "8px" } }>
                            <button
                                type="button"
                                className="z-1 btn btn-lg btn-danger shadow-sm"
                                data-bs-dismiss="modal"
                                style={ { paddingBottom: "9px", paddingTop: "5px" } }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-x m-auto"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        { offCanvas() }
    </div>
}