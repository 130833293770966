import { store } from "../repository/store"
import { GET } from "../const/request"
import { refreshToken, resParser } from "../const/utils"

class NotifierService {

    async notifier(
        emittedTime
    ) {
        await refreshToken()

        const auth = store.data.token.access.value !== null ? "Bearer " + store.data.token.access.value : null

        const headers = auth === null ? { } : { "Authorization": auth }

        const path = process.env.BASE_URL + "/api/v1/notifiers?emittedTime=" + emittedTime

        const response = await fetch(path, {
            method: GET,
            headers: headers
        })

        return await resParser(response)
    }

    async notifierTime() {
        const path = process.env.BASE_URL + "/api/v1/notifiers/time"

        const response = await fetch(path, { method: GET })

        return await resParser(response)
    }
}

export const notifierService = new NotifierService()