import React from "react"
import { randId } from "../const/utils"

export class InputTime extends React.Component {

    constructor(props) {
        super(props)

        const initialValue = props.initialValue
        const hour = initialValue.split(':')[0].trim()
        const minute = initialValue.split(':')[1].trim()

        this.state = {
            hour: hour,
            minute: minute
        }

        this.label = props.label
        this.id = randId()

        this.onClickMinMinute = this.onClickMinMinute.bind(this)
        this.onClickPlusMinute = this.onClickPlusMinute.bind(this)

        this.onClickMinHour = this.onClickMinHour.bind(this)
        this.onClickPlusHour = this.onClickPlusHour.bind(this)
    }

    onClickMinMinute(_) {
        this.setState(
            (state, _) => {

                if (Number(state.minute) === 0) return { minute: 55 }

                return { minute: ( Number(state.minute) - 5 ) }
            },
            () => {
                const hour = String(this.state.hour).padStart(2, "0")
                const min = String(this.state.minute).padStart(2, "0")
                this.props.onChange(hour + ":" + min)
            }
        )
    }

    onClickPlusMinute(_) {
        this.setState(
            (state, _) => {

                if (Number(state.minute) === 55) return { minute: 0 }

                return { minute: ( Number(state.minute) + 5 ) }
            },
            () => {
                const hour = String(this.state.hour).padStart(2, "0")
                const min = String(this.state.minute).padStart(2, "0")
                this.props.onChange(hour + ":" + min)
            }
        )
    }

    onClickMinHour(_) {
        this.setState(
            (state, _) => {

                if (Number(state.hour) === 0) return { hour: 23 }

                return { hour: ( Number(state.hour) - 1 ) }
            },
            () => {
                const hour = String(this.state.hour).padStart(2, "0")
                const min = String(this.state.minute).padStart(2, "0")
                this.props.onChange(hour + ":" + min)
            }
        )
    }

    onClickPlusHour(_) {
        this.setState(
            (state, _) => {

                if (Number(state.hour) === 23) return { hour: 0 }

                return { hour: ( Number(state.hour) + 1 ) }
            },
            () => {
                const hour = String(this.state.hour).padStart(2, "0")
                const min = String(this.state.minute).padStart(2, "0")
                this.props.onChange(hour + ":" + min)
            }
        )
    }

    render() {
        return <div className="shadow-sm border rounded">

            <button className="btn col-12 py-2" data-bs-toggle="modal" data-bs-target={ "#" + this.id }>
                <div className="d-flex justify-content-center">
                    <div className="pe-1">{ this.label }</div>
                    <div>{ String(this.state.hour).padStart(2, "0") + ":" + String(this.state.minute).padStart(2, "0") }</div>
                </div>
            </button>

            <div className="modal fade" id={ this.id } tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{ this.label }</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body row row-gap-3 px-0">

                            <div className="row m-auto text-center">

                                <div className="col-5">
                                    <button className="btn btn-lg" onClick={ this.onClickPlusHour }>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16"
                                            fill="currentColor"
                                            className="bi bi-chevron-compact-up"
                                            viewBox="0 0 16 16"
                                        >
                                            <path fillRule="evenodd" d="M7.776 5.553a.5.5 0 0 1 .448 0l6 3a.5.5 0 1 1-.448.894L8 6.56 2.224 9.447a.5.5 0 1 1-.448-.894z"/>
                                        </svg>
                                    </button>
                                    <div>
                                        <button className="btn btn-lg active border-0" disabled>
                                            { String(this.state.hour).padStart(2, "0") }
                                        </button>
                                    </div>
                                    <button className="btn btn-lg" onClick={ this.onClickMinHour }>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16"
                                            fill="currentColor"
                                            className="bi bi-chevron-compact-down"
                                            viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"/>
                                        </svg>
                                    </button>
                                </div>

                                <div className="col-2 my-auto">
                                    <div>
                                        <button className="btn btn-lg active border-0" disabled>
                                            :
                                        </button>
                                    </div>
                                </div>

                                <div className="col-5">
                                    <button className="btn btn-lg" onClick={ this.onClickPlusMinute }>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16"
                                            fill="currentColor"
                                            className="bi bi-chevron-compact-up"
                                            viewBox="0 0 16 16"
                                        >
                                            <path fillRule="evenodd" d="M7.776 5.553a.5.5 0 0 1 .448 0l6 3a.5.5 0 1 1-.448.894L8 6.56 2.224 9.447a.5.5 0 1 1-.448-.894z"/>
                                        </svg>
                                    </button>
                                    <div>
                                        <button className="btn btn-lg active border-0" disabled>
                                            { String(this.state.minute).padStart(2, "0") }
                                        </button>
                                    </div>
                                    <button className="btn btn-lg" onClick={ this.onClickMinMinute }>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16"
                                            fill="currentColor"
                                            className="bi bi-chevron-compact-down"
                                            viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}