import i18n from 'i18next'
import React, { useContext } from "react"
import { Level } from "../component/level"
import { Ground } from "../component/ground"
import { InputGameEdition } from "../component/input-game-edition"
import { gameService } from "../service/game-service"
import { useLocation, useNavigate } from "react-router-dom"
import { recordService } from "../service/record-service"
import { closeModal, DATE_FORMAT, genderWith, GROUND, LEVEL, levelWith, openModal, timesParser } from "../const/common"
import moment from "moment"
import Login from "./login"
import { Context } from "../const/context"
import Record from "../component/record"
import { InputNumber } from "../component/input-number"
import { disconnect, isNotConnected, fullDate, formatPrice } from "../const/utils"
import InputGameAdvanced from "../component/input-game-advanced"
import { PActionButton } from "../component/action-button"
import MailModal from "../component/mail-modal"
import AuthModal from "../component/auth-modal"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    const state = location.state

    const isNotEmptyState = state != null

    const guestMode = isNotEmptyState ? state.guestMode : false

    if (isNotConnected(context) && !guestMode) return <Login guestMode={ true } previousPath="/game-edition"/>

    const game = isNotEmptyState ? state.game : null
    const file = isNotEmptyState ? state.file : null

    return <GameEdition
        { ...props }
        guestMode={ guestMode }
        navigate={ navigate }
        location={ location }
        context={ context }
        game={ game }
        file={ file }
    />
}

class GameEdition extends React.Component {

    constructor(props) {
        super(props);

        const game = props.game
        const file = props.file != null ? { name: game.id, preview: props.file } : null

        const isGameNotNull = game != null

        const id = isGameNotNull && game.id != null ? game.id : null
        const isPaymentAllow = isGameNotNull && game.isPaymentAllow != null ? game.isPaymentAllow : false
        const visibility = isGameNotNull && game.visibility != null ? game.visibility : "PUBLIC"
        const name = isGameNotNull && game.name != null ? game.name : ""

        const address = isGameNotNull && game.address != null ? game.address : ""
        const region = isGameNotNull && game.region != null ? game.region : "CHOOSE"

        const price = isGameNotNull && game.playerPrice != null ? game.playerPrice : ""
        const dayOfEventPlayerPrice = isGameNotNull && game.dayOfEventPlayerPrice != null ? game.dayOfEventPlayerPrice : ""

        const canManageRegistration = isGameNotNull && game.canManageRegistration != null ? game.canManageRegistration : false
        const canUseWaitingList = isGameNotNull && game.canUseWaitingList != null ? game.canUseWaitingList : false
        const shouldValidateLevel = isGameNotNull && game.shouldValidateLevel != null ? game.shouldValidateLevel : false
        const shouldAskPlayerLicensed = isGameNotNull && game.shouldAskPlayerLicensed != null ? game.shouldAskPlayerLicensed : false

        const nbOfExtraMember = isGameNotNull && game.nbOfExtraMember != null ? game.nbOfExtraMember : 0
        const deadlineOfIndividualPayment = isGameNotNull && game.deadlineOfIndividualPayment != null ? game.deadlineOfIndividualPayment : 3

        const phoneNumber = isGameNotNull && game.phoneNumber != null ? game.phoneNumber : ""
        const mail = isGameNotNull && game.mail != null ? game.mail : ""
        const whatsAppGroupLink = isGameNotNull && game.whatsAppGroupLink != null ? game.whatsAppGroupLink : null

        const startDate = (isGameNotNull && game.startDate != null ? moment(game.startDate) : moment()).locale(i18n.language)
        const startTime = isGameNotNull && game.startTime != null ? game.startTime : "09:00"
        const endTime = isGameNotNull && game.endTime != null ? game.endTime : "20:00"
        const ground = isGameNotNull && game.ground != null ? game.ground + "-VOLLEY" : "INDOOR-VOLLEY"

        const nbOfTeam = isGameNotNull && game.nbOfTeam != null ? game.nbOfTeam : 18
        const nbOfGround = isGameNotNull && game.nbOfGround != null ? game.nbOfGround : 3
        const nbOfMember = isGameNotNull && game.nbOfMember != null ? game.nbOfMember : 4

        const minOfMale = isGameNotNull && game.minMaleInTeam != null ? game.minMaleInTeam : 0
        const minOfFemale = isGameNotNull && game.minFemaleInTeam != null ? game.minFemaleInTeam : 1

        const levelFrom = isGameNotNull && game.levelFrom != null ? LEVEL[game.levelFrom] : LEVEL.OUT_OF_COMPETITION
        const levelTo = isGameNotNull && game.levelTo != null ? LEVEL[game.levelTo] : LEVEL.PRO

        const detail = isGameNotNull && game.detail != null ? game.detail : ""

        this.state = {
            id: id,

            visibility: visibility,

            isPaymentAllow: isPaymentAllow,

            name: name,
            address: address,
            region: region,
            phoneNumber: phoneNumber,
            mail: mail,
            whatsAppGroupLink: whatsAppGroupLink,

            price: price,
            dayOfEventPlayerPrice: dayOfEventPlayerPrice,

            canManageRegistration: canManageRegistration,
            canUseWaitingList: canUseWaitingList,
            shouldValidateLevel: shouldValidateLevel,
            shouldAskPlayerLicensed: shouldAskPlayerLicensed,

            nbOfExtraMember: nbOfExtraMember,
            deadlineOfIndividualPayment: deadlineOfIndividualPayment,

            startDate: startDate,
            startTime: startTime,
            endTime: endTime,

            file: file,
            isUpdatedFile: false,

            inputIsValid: id != null,

            ground: ground,

            nbOfTeam: nbOfTeam,
            nbOfGround: nbOfGround,
            nbOfMember: nbOfMember,

            minOfMale: minOfMale,
            minOfFemale: minOfFemale,

            level: {
                min: levelFrom,
                max: levelTo
            },
            detail: detail,

            authorizationId: null,
            authorizationCode: null,

            authId: null,
            isAuthTimerOn: false,
        }

        this.navigate = this.props.navigate
        this.collapseRegistration = this.state.canManageRegistration ? "collapse show" : "collapse hide"

        this.onHandleInput = this.onHandleInput.bind(this)

        this.onHandleFile = this.onHandleFile.bind(this)

        this.onHandleNbOfTeam = this.onHandleNbOfTeam.bind(this)
        this.onHandleNbOfGround = this.onHandleNbOfGround.bind(this)
        this.onHandleNbOfPlayer = this.onHandleNbOfPlayer.bind(this)

        this.onHandleMinOfMale = this.onHandleMinOfMale.bind(this)
        this.onHandleMinOfFemale = this.onHandleMinOfFemale.bind(this)

        this.onHandleGround = this.onHandleGround.bind(this)

        this.onHandleLevel = this.onHandleLevel.bind(this)

        this.onHandleDefaultMessage = this.onHandleDefaultMessage.bind(this)

        this.onHandleDetail = this.onHandleDetail.bind(this)
        this.onHandleTextInput = this.onHandleTextInput.bind(this)
        this.onHandleAdvanceSettings = this.onHandleAdvanceSettings.bind(this)

        this.setAuthId = this.setAuthId.bind(this)
        this.openAuthModal = this.openAuthModal.bind(this)
        this.closeMailModal = this.closeMailModal.bind(this)
        this.setTimerOn = this.setTimerOn.bind(this)
        this.resetGuestSaver = this.resetGuestSaver.bind(this)

        this.saveButton = this.saveButton.bind(this)

        this.saveAsGuest = this.saveAsGuest.bind(this)
        this.save = this.save.bind(this)
    }

    records() {
        const file = this.state.file

        if (file != null) return [ file ]

        return null
    }

    onHandleInput(childState) {
        this.setState((state, _) => ({
            name: childState.name,
            address: childState.address,
            region: childState.region,
            price: childState.price,
            phoneNumber: childState.phoneNumber,
            mail: childState.mail,
            whatsAppGroupLink: childState.whatsAppGroupLink,
            startDate: childState.startDate,
            startTime: childState.startTime,
            endTime: childState.endTime,
            inputIsValid: childState.isValid,
        }))
    }

    async onHandleFile(file) {
        this.setState((state, _) => ({ file: file, isUpdatedFile: true }))
    }

    onHandleNbOfTeam(value) {
        this.setState((state, _) => ({ nbOfTeam: value }))
    }

    onHandleNbOfGround(value) {
        this.setState((state, _) => ({ nbOfGround: value }))
    }

    onHandleNbOfPlayer(e) {
        this.setState(
            (state, _) => ({ nbOfMember: parseInt(e.target.value) }),
            () => {
                const nbOfMember = parseInt(this.state.nbOfMember)

                const minOfMale = parseInt(this.state.minOfMale)
                const minOfFemale = parseInt(this.state.minOfFemale)

                const isInconstancy = (minOfMale + minOfFemale) > nbOfMember

                const newMinOfMale = minOfMale > 0 ? minOfMale - 1 : minOfMale
                const newMinOfFemale = minOfFemale > 0 ? minOfFemale - 1 : minOfFemale

                if (isInconstancy) this.setState(
                    (state, _) => (
                        {
                            minOfMale: newMinOfMale,
                            minOfFemale: newMinOfFemale
                        }
                    )
                )
            }
        )
    }

    onHandleMinOfMale(e) {
        const nbOfMember = this.state.nbOfMember

        const minOfMale = parseInt(e.target.value)
        const currentMinOfFemale = this.state.minOfFemale

        const diff = nbOfMember - minOfMale

        const minOfFemale = diff < currentMinOfFemale ? diff : currentMinOfFemale

        this.setState((state, _) => ({ minOfMale, minOfFemale }))
    }

    onHandleMinOfFemale(e) {
        const nbOfMember = this.state.nbOfMember

        const minOfFemale = parseInt(e.target.value)
        const currentMinOfMale = this.state.minOfMale

        const diff = nbOfMember - minOfFemale

        const minOfMale = diff < currentMinOfMale ? diff : currentMinOfMale

        this.setState((state, _) => ({ minOfMale, minOfFemale }))
    }

    onHandleGround(ground) {
        this.setState((state, _) => ({ ground }))
    }

    onHandleLevel(min, max) {
        this.setState((state, _) => ({ level: { min, max } }))
    }

    onHandleDefaultMessage(_) {
        const gender = genderWith(this.state.nbOfMember, this.state.minOfMale, this.state.minOfFemale)
        const level = levelWith(this.state.level.min.key, this.state.level.max.key)
        const phoneNumber = this.state.phoneNumber !== "" ? "☎️\t" + this.state.phoneNumber + "\n" : ""
        const mail = this.state.mail !== "" ? "✉️\t" + this.state.mail + "\n" : ""

        const defaultMessage = "" +
            this.state.name + "\n\n" +
            "🏟\tTerrain " + GROUND.filter(it => it.key === this.state.ground)[0].value + "\n" +
            "🗓️\t" + fullDate(this.state.startDate) + "\n" +
            "⏰\t" + "Horaire: " + timesParser(this.state.startTime, this.state.endTime) + "\n" +
            "🎫\t" + formatPrice(this.state.price) + " / Joueur" + "\n" +
            "⚔️\t" + this.state.nbOfMember + "v" + this.state.nbOfMember + " " + gender + "\n" +
            "🦾\t" + level + "\n" +
            phoneNumber +
            mail +
            "📍\t" + this.state.address + "\n" +
            "🎁\tLots a gagner" + "\n" +
            "🧃\tBuvette sur place"

        this.setState((state, _) => ({ detail: defaultMessage }))
    }

    onHandleDetail(e) {
        this.setState((state, _) => ({ detail: e.target.value }))
    }

    onHandleTextInput(id, value) {
        this.setState((state, _) => ( { [id]: value } ))
    }

    onHandleAdvanceSettings(
        visibility,
        canManageRegistration,
        canUseWaitingList,
        shouldValidateLevel,
        shouldAskPlayerLicensed,

        nbOfExtraMember,
        deadlineOfIndividualPayment,

        dayOfEventPlayerPrice
    ) {
        this.setState((state, _) => ( {
            visibility: visibility,
            canManageRegistration: canManageRegistration,
            canUseWaitingList: canUseWaitingList,
            shouldValidateLevel: shouldValidateLevel,
            shouldAskPlayerLicensed: shouldAskPlayerLicensed,
            nbOfExtraMember: nbOfExtraMember,
            deadlineOfIndividualPayment: deadlineOfIndividualPayment,
            dayOfEventPlayerPrice: dayOfEventPlayerPrice
        } ))
    }

    resetGuestSaver = () => this.setState(({ isAuthTimerOn: false, authId: "", code: "" }))

    setAuthId = (authId) => this.setState((state, _) => ({ authId: authId }))

    openAuthModal= () => {
        this.setState(
            (state, _) => ({ isAuthTimerOn: true }),
            () => openModal("auth-modal")
        )
    }

    closeMailModal = () => {
        this.resetGuestSaver()

        closeModal("mail-modal")
    }

    setTimerOn = (value) => this.setState((state, _) => ({ isAuthTimerOn: value }))

    createEventAsGuest = async (authCode) => {
        const authId = this.state.authId

        const res = await gameService.createAsGuest(
            this.state.name,

            this.state.ground.split("-")[0],

            this.state.nbOfTeam,
            this.state.nbOfGround,
            this.state.nbOfMember,

            this.state.minOfMale,
            this.state.minOfFemale,

            this.state.level.min.key,
            this.state.level.max.key,

            this.state.startDate.format(DATE_FORMAT),
            this.state.startDate.format(DATE_FORMAT),
            this.state.startTime,
            this.state.endTime,

            this.state.price,

            this.state.address,
            this.state.region,

            this.state.phoneNumber,
            this.state.mail,
            this.state.whatsAppGroupLink,

            this.state.detail,

            authId,
            authCode
        )

        this.resetGuestSaver()

        closeModal("auth-modal")

        if (res.error != null || res.httpCode != null) return

        const isNotUpdatedFile = !this.state.isUpdatedFile

        if (isNotUpdatedFile) return this.navigate("/success?message=gameEdition.congrats")

        const file = this.state.file

        await recordService.createAsGuest(file, "event", res.id, authId, authCode)

        return this.navigate("/success?message=gameEdition.congrats")
    }

    onClickOpenRegistryModal = () => openModal("registryModal")

    saveButton() {
        const saveIcon = <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16" height="16"
            fill="currentColor"
            className="bi bi-floppy2"
            viewBox="-2 0 20 15"
        >
            <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v3.5A1.5 1.5 0 0 1 11.5 6h-7A1.5 1.5 0 0 1 3 4.5V1H1.5a.5.5 0 0 0-.5.5m9.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"/>
        </svg>

        const isValidFrom = this.state.inputIsValid

        if (!isValidFrom) return <PActionButton clazzname="w-100" disabled={ true }>
            <div className="d-flex justify-content-around">
                <div className="me-auto">
                    { saveIcon }
                </div>
                <div className="me-auto">{ i18n.t("button.save") }</div>
            </div>
        </PActionButton>

        const guestMode = this.props.guestMode === true

        return <PActionButton clazzname="w-100" onClick={ guestMode ? this.saveAsGuest : this.save }>
            <div className="d-flex justify-content-around">
                <div className="me-auto">
                    { saveIcon }
                </div>
                <div className="me-auto">{ i18n.t("button.save") }</div>
            </div>
        </PActionButton>
    }

    async saveAsGuest() {
        const authId = this.state.authId

        if (authId == null || authId === "") openModal("mail-modal")
    }

    async save() {
        const createdOrUpdated = await gameService.createOrUpdate(
            this.state.id,
            this.state.name,
            this.state.visibility,

            this.state.ground.split("-")[0],

            this.state.nbOfTeam,
            this.state.nbOfGround,
            this.state.nbOfMember,

            this.state.minOfMale,
            this.state.minOfFemale,

            this.state.level.min.key,
            this.state.level.max.key,

            this.state.startDate.format(DATE_FORMAT),
            this.state.startDate.format(DATE_FORMAT),
            this.state.startTime,
            this.state.endTime,

            this.state.price,
            this.state.dayOfEventPlayerPrice,

            this.state.canManageRegistration,
            this.state.canUseWaitingList,
            this.state.shouldValidateLevel,
            this.state.shouldAskPlayerLicensed,

            this.state.nbOfExtraMember,
            this.state.deadlineOfIndividualPayment,

            this.state.address,
            this.state.region,

            this.state.phoneNumber,
            this.state.mail,
            this.state.whatsAppGroupLink,

            this.state.detail,
        )

        if (createdOrUpdated.status === 401) disconnect(this.navigate, this.props.context, this.props.location.pathname)

        if (createdOrUpdated.error != null || createdOrUpdated.status >= 400 || createdOrUpdated.httpCode != null) return this.navigate(-1)

        const isNotUpdatedFile = !this.state.isUpdatedFile

        if (isNotUpdatedFile) return this.navigate("/game/" + createdOrUpdated.id, { state: { game: createdOrUpdated } })

        const file = this.state.file

        if (file == null && this.state.id != null) await recordService.delete("event", this.state.id)
        else if (file != null) await recordService.create(file, "event", createdOrUpdated.id)

        return this.navigate("/game/" + createdOrUpdated.id, { state: { game: createdOrUpdated } })
    }

    minNbOfTeam = () => {
        if (this.props.game == null) return 2

        return this.props.game.nbOfRegisterTeam <= 2 ? 2 : this.props.game.nbOfRegisterTeam
    }

    onRegistration = () => {
        if (this.props.game == null) return false

        return this.props.game.nbOfRegisterTeam > 0
    }

    render() {

        return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto pb-3">

            <div className="row border rounded shadow-sm px-3 py-2 mx-0 mb-3">
                <div className="col-7 col-md-9 col-lg-10 px-0 my-auto">
                    <p className="fs-2 m-0">{ i18n.t("gameEdition.title") }</p>
                </div>
                <div className="col-5 col-md-3 col-lg-2 text-end m-auto px-0">
                    <div className="row row-gap-2">
                        <div className="col-12">
                            { this.saveButton() }
                        </div>
                        <div className="col-12" hidden={ this.props.guestMode }>
                            <div className="text-center rounded">
                                <button
                                    className="btn btn-secondary w-100"
                                    style={ { borderRadius: "0.2rem", paddingLeft: "8px", paddingRight: "8px" } }
                                    onClick={ this.onClickOpenRegistryModal }
                                >
                                    <div className="d-flex justify-content-around">
                                        <div className="me-auto my-auto">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16" height="16"
                                                fill="currentColor"
                                                className="bi bi-box"
                                                viewBox="0 0 17 17"
                                            >
                                                <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464z"/>
                                            </svg>
                                        </div>
                                        <div className="me-auto">{ i18n.t("button.advanced-settings") }</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-1 my-4">
                <div className="col card border-2" style={ { alignSelf: "center" } }></div>
            </div>

            <InputGameEdition
                id={ this.state.id }
                name={ this.state.name }
                address={ this.state.address }
                region={ this.state.region }
                price={ this.state.price }
                phoneNumber={ this.state.phoneNumber }
                mail={ this.state.mail }
                whatsAppGroupLink={ this.state.whatsAppGroupLink }
                startDate={ this.state.startDate }
                startTime={ this.state.startTime }
                endTime={ this.state.endTime }
                onHandleInput={ this.onHandleInput }
                onRegistration={ this.onRegistration() }
            />

            <div className="my-3">
                <Record files={ this.records() } onHandleFile={ this.onHandleFile }/>
            </div>

            <div className="row row-cols-1 row-gap-3">
                <div className="col-sm-12 col-md-12 col-lg-6 pe-lg-1">
                    <InputNumber
                        label={ i18n.t("gameEdition.nbOfTeam") }
                        initialValue={ this.state.nbOfTeam }
                        minValue={ this.minNbOfTeam() }
                        onChange={ this.onHandleNbOfTeam }
                    />
                </div>

                <div className="col-sm-12 col-md-12 col-lg-6 ps-lg-1">
                    <InputNumber
                        label={ i18n.t("gameEdition.nbOfGround") }
                        initialValue={ this.state.nbOfGround }
                        minValue={ 1 }
                        onChange={ this.onHandleNbOfGround }
                    />
                </div>
            </div>

            <div className="border rounded shadow-sm p-4 mt-3">
                <p className="text-center">{ i18n.t("gameEdition.nbOfPlayerByTeam") }: { this.state.nbOfMember }</p>
                <input
                    type="range"
                    className="form-range"
                    min="2"
                    max="6"
                    id="nbOfMember"
                    value={ this.state.nbOfMember }
                    onChange={ this.onHandleNbOfPlayer }
                    disabled={ this.onRegistration() }
                />
            </div>

            <div className="border rounded shadow-sm p-4 mt-3">
                <div className="row">
                    <div className="col-6 p-4">
                        <p className="text-center py-2">{ i18n.t("gameEdition.minOfMale") }: { this.state.minOfMale }</p>
                        <input
                            type="range"
                            className="form-range"
                            min="0"
                            max={ this.state.nbOfMember }
                            id="minOfMale"
                            value={ this.state.minOfMale }
                            onChange={ this.onHandleMinOfMale }
                            disabled={ this.onRegistration() }
                        />
                    </div>
                    <div className="col-6 p-4">
                        <p className="text-center py-2">{ i18n.t("gameEdition.minOfFemale") }: { this.state.minOfFemale }</p>
                        <input
                            type="range"
                            className="form-range"
                            min="0"
                            max={ this.state.nbOfMember }
                            id="minOfFemale"
                            value={ this.state.minOfFemale }
                            onChange={ this.onHandleMinOfFemale }
                            disabled={ this.onRegistration() }
                        />
                    </div>
                </div>
            </div>

            <Ground ground={ this.state.ground } onHandleGround={ this.onHandleGround }/>

            <div className="mt-3">
                <Level
                    min={ this.state.level.min }
                    max={ this.state.level.max }
                    onHandleLevel={ this.onHandleLevel }
                    disabled={ this.onRegistration() }
                />
            </div>

            <div className="border rounded p-0 mt-3">
                <div className="d-flex justify-content-between py-2">
                    <div className="ms-2">
                        <div className="d-flex">
                            <div className="form-label rounded-top m-auto p-2">
                                { i18n.t("gameEdition.detail") }
                            </div>
                            <div
                                className="text-body-secondary m-auto fst-italic fw-lighter"
                            >
                                { this.state.detail.length } : 1440
                            </div>
                        </div>
                    </div>
                    <div className="me-2 mt-1 p-0">
                        <button className="btn btn-sm btn-primary" onClick={ this.onHandleDefaultMessage }>
                            { i18n.t("gameEdition.default-message") }
                        </button>
                    </div>
                </div>
                <textarea
                    id="detail"
                    className="form-control shadow-sm rounded-top-0 border-bottom-0 border-start-0 border-end-0"
                    rows="15"
                    value={ this.state.detail }
                    onChange={ this.onHandleDetail }
                    maxLength={ 1440 }
                ></textarea>
            </div>

            <MailModal
                mail={ this.state.mail }
                scopes={ [ "CREATE_GAME", "CREATE_RECORD" ] }
                setAuthId={ this.setAuthId }
                nextAction={ this.openAuthModal }
                close={ this.closeMailModal }
            />
            <AuthModal
                isTimerOn={ this.state.isAuthTimerOn }
                setIsTimerOn={ this.setTimerOn }
                nextAction={ this.createEventAsGuest }
                close={ this.resetGuestSaver }
            />

            <InputGameAdvanced
                visibility={ this.state.visibility }

                isPaymentAllow={ this.state.isPaymentAllow }

                collapseRegistration={ this.collapseRegistration }

                canManageRegistration={ this.state.canManageRegistration }
                canUseWaitingList={ this.state.canUseWaitingList }
                shouldValidateLevel={ this.state.shouldValidateLevel }
                shouldAskPlayerLicensed={ this.state.shouldAskPlayerLicensed }

                nbOfExtraMember={ this.state.nbOfExtraMember }
                deadlineOfIndividualPayment = { this.state.deadlineOfIndividualPayment }

                dayOfEventPlayerPrice={ this.state.dayOfEventPlayerPrice }
                onHandleAdvanceSettings={ this.onHandleAdvanceSettings }

                disabled={ this.onRegistration() }
            />
        </div>
    }
}