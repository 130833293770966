import playerAttack from "../../public/player_attack.png"
import { formatPrice, startDate } from "../const/utils"
import { gender, regionFinder, regionTranslation } from "../const/common"
import i18n from "i18next"
import React, { useEffect, useState } from "react"
import { recordService } from "../service/record-service"
import { useNavigate } from "react-router-dom"

export default function GameRegistrySummary(props) {
    const navigate = useNavigate()

    const game = props.game

    const [ record, setRecord ] = useState("")

    useEffect( () => {
        async function fetchRecord() {
            const res = await recordService.search([ game.id ], [])

            if (res == null || res.length === 0) return setRecord(playerAttack)
            if (res.status >= 400) return navigate("/")

            setRecord(res[0].url)
        }

        if (game != null) fetchRecord().then()
    }, [ game ])

    if (game == null) return <React.Fragment></React.Fragment>

    const extraPlayerMessage = () => {
        if (game.nbOfExtraMember < 2) return i18n.t("common.additional-player") + " " + i18n.t("common.accepted")

        return i18n.t("common.additional-players") + " " + i18n.t("common.accepteds")
    }

    return <React.Fragment>
        <button
            className="btn text-start card shadow-sm px-0 w-100"
            data-bs-toggle="modal"
            data-bs-target="#gameModal"
        >
            <div className="card-body">
                <div className="card-title">
                    { game.name.toUpperCase() }
                </div>
                <div className="row row-gap-2">
                    <div className="row row-gap-1">
                        <div className="small">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16"
                                fill="currentColor"
                                className="bi bi-calendar-event"
                                viewBox="0 0 16 16"
                            >
                                <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                            </svg>
                            { "\t" }{ startDate(game.startDate) } de { game.startTime.slice(0, -3) } à { game.endTime.slice(0, -3) }
                        </div>
                        <div className="small">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16"
                                fill="currentColor"
                                className="bi bi-geo-fill"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411"
                                />
                            </svg>
                            { "\t" }{ regionTranslation(regionFinder(game.region)) }
                        </div>
                        <div className="small">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16"
                                fill="currentColor"
                                className="bi bi-people-fill"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
                            </svg>
                            { "\t" }{ game.nbOfMember }v{ game.nbOfMember } { gender(game) }
                        </div>
                        <div className="small" hidden={ game.nbOfExtraMember === 0 }>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16"
                                fill="currentColor"
                                className="bi bi-plus-circle"
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                            </svg>{ "\t" }{ game.nbOfExtraMember } { extraPlayerMessage() }
                        </div>
                    </div>
                    <div className="row row-gap-1 small">
                        <div>
                            { i18n.t("home.price-player") } { formatPrice(game.playerPrice) }
                        </div>
                        <div hidden={ game.nbOfExtraMember === 0 }>
                            { i18n.t("home.price-additional-player") } { formatPrice(game.playerPrice) }
                        </div>
                        <div hidden={ game.dayOfEventPlayerPrice == null }>
                            { i18n.t("home.price-at-day-of-event") } { formatPrice(game.dayOfEventPlayerPrice) }
                        </div>
                    </div>
                </div>
            </div>
        </button>

        <div
            className="modal fade"
            id="gameModal"
            tabIndex="-1"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="col">
                        <div className="z-0">
                            <img
                                src={ record }
                                className="card-img-top"
                                alt="tounament_image"
                            />
                        </div>
                        <div style={ { position: "absolute", top: "8px", right: "8px" } }>
                            <button
                                type="button"
                                className="z-1 btn btn-lg btn-danger shadow"
                                data-bs-dismiss="modal"
                                style={ { paddingBottom: "9px", paddingTop: "5px" } }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-x m-auto"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>

}