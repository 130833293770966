import { APPLICATION_JSON, GET, POST } from "../const/request"
import { refreshToken, resParser } from "../const/utils"
import { store } from "../repository/store"

class OrderService {

    async getByIdAsGuest(
        authId,
        authCode,
        orderId,
    ) {
        const path = process.env.BASE_URL + "/api/v1/orders/" + orderId + "/as-guest"

        const headers = {
            "Content-Type": APPLICATION_JSON,
            "Authorization-Id": authId,
            "Authorization-Code": authCode,
        }

        const response = await fetch(path, {
            method: GET,
            headers: headers,
        })

        return orderTransformer(await resParser(response))
    }

    async getById(orderId) {
        await refreshToken()

        const path = process.env.BASE_URL + "/api/v1/orders/" + orderId

        const auth = "Bearer " + store.data.token.access.value

        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }

        const response = await fetch(path, { method: GET, headers: headers })

        return orderTransformer(await resParser(response))
    }

    async search(
        ids,
        username
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/orders/search"

        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }

        const body = JSON.stringify({
            ids: ids,
            username: username,
        })

        const response = await fetch(path, {
            method: POST,
            headers: headers,
            body: body
        })

        return ordersTransformers(await resParser(response))
    }
}

export const orderTransformer = (value) => {
    value.totalAmount = value.totalAmount / 100
    value.payment.amount = value.payment.amount / 100
    value.items = itemsTransformers(value.items)

    return value
}

export const ordersTransformers = (values) => {
    if (values.length != null) return values.map(it => orderTransformer(it))

    return values
}

export const itemTransformer = (value) => {
    value.amount = value.amount / 100

    return value
}

const itemsTransformers = (values) => {
    if (values.length != null) return values.map(it => itemTransformer(it))

    return values
}

export const orderService = new OrderService()