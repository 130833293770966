import React, { useEffect, useState } from "react"
import { closeModal } from "../const/common"
import i18n from "../config/i18n"

export default function AuthModal(props) {

    const [ code, setCode ] = useState("")

    const [ min, setMin ] = useState(10)
    const [ sec, setSec ] = useState(59)

    const [ borderColor, setBorderColor ] = useState("")
    const [ disableClose, setDisableClose ] = useState(false)

    useEffect(() => {
        if (!props.isTimerOn) {
            setMin(10)
            setSec(59)
            setCode("")
            setBorderColor("")
        }
    }, [ props.isTimerOn ])

    useEffect(() => {
        if (sec === 59 && min !== 0) setMin(min - 1)
    }, [ sec ])

    useEffect(() => {
        const timer = setInterval( () => {
            if (min === 0 && sec === 0) return close()
            if (sec === 0) setSec(59)
            else if (props.isTimerOn) setSec(sec - 1)
        }, 1000)

        return () => clearInterval(timer)
    }, [ sec, props.isTimerOn ])

    const close = () => {
        setCode("")
        setBorderColor("")
        props.setIsTimerOn(false)
        props.close()
        closeModal("auth-modal")
    }

    const codeSetter = (e) => {
        const newValue = e.target.value.replace(/\D/g,'')

        if (newValue.length === 6) {
            setDisableClose(true)
            setBorderColor(" border-primary ")
            props.nextAction(newValue)
        }
        else setBorderColor("")

        setTimeout(() => { setDisableClose(false) }, 10000)

        setCode(newValue)
    }

    return <div
        id="auth-modal"
        className="modal fade"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
    >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{ i18n.t("common.auth-key-received-by-email") }</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={ () => close() }
                        disabled={ disableClose }
                    ></button>
                </div>
                <div className="modal-body mx-auto text-center">
                    <div className="row row-gap-3 mx-auto">
                        <div
                            className={
                            "auth-input input-group input-group-lg flex-nowrap " +
                                "bg-secondary-subtle " +
                                "shadow-sm " +
                                "border border-2 " + borderColor  +
                                "rounded mx-auto "
                            }
                        >
                            <input
                                type="text"
                                inputMode="numeric"
                                className="form-control bg-transparent border-0 outline-0"
                                placeholder="______"
                                aria-label="AuthKey"
                                aria-describedby="auth-key"
                                maxLength="6"
                                value={ code }
                                onChange={ codeSetter }
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer p-0">
                    <div className="py-3 mx-auto small ">
                        <div className="fst-italic fw-bold">{ min }:{ String(sec).padStart(2, '0') } min</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}