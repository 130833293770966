import { APPLICATION_JSON, POST } from "../const/request"
import { resParser } from "../const/utils"

class AuthorizationService {

    async create(
        scope,
        channel,
        contact,
    ) {
        const body = JSON.stringify({
            scope: scope,
            channel: channel,
            contact: contact,
        })

        const path = process.env.BASE_URL + "/api/v1/authorizations"

        const response = await fetch(path, {
            method: POST,
            headers: { "Content-Type": APPLICATION_JSON },
            body: body,
        })

        return await resParser(response)
    }

}

export const authService = new AuthorizationService()