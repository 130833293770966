import i18n from "i18next"

import React, { useContext, useEffect, useState } from "react"
import { PActionButton } from "../component/action-button"
import { useNavigate } from "react-router-dom"
import GameRegistrySummary from "../component/game-registry-summary"
import Input from "../component/input"
import { captainCard, closeModal, i118nTPlayerWith, LEVEL, levels, openModal, registerTeam } from "../const/common"
import { userService } from "../service/user-service"
import {
    disconnect,
    emptyablePhoneNbValidator,
    formatPrice,
    phoneNbValidator
} from "../const/utils"
import { Context } from "../const/context"
import ButtonHelloAssoPayment from "../component/button-hello-asso-payment"
import moment from "moment/moment"
import { teamService } from "../service/team-service"
import { orderService } from "../service/order-service"
import MembersSuggestion from "../component/members-suggestion"
import { notifierService } from "../service/notifier-service"
import { eventTransformer } from "../service/game-service"

const MODAL_OPEN_ORDER = {
    0 : "phone-modal",
    1 : "ask-modal",
    2 : "pay-modal",
}

export default function GameRegistriesAsConnected(props) {
    const navigate = useNavigate()

    const context = useContext(Context)

    const [ game, setGame ] = useState(props.game)

    const [ actionButtonDisable, setActionButtonDisable ] = useState(true)

    const [ user, setUser ] = useState(null)
    const [ userSearch, setUserSearch ] = useState([])
    const [ requestMembers, setRequestMembers ] = useState([])

    const [ teamName, setTeamName ] = useState("")
    const [ phoneNumber, setPhoneNumber ] = useState("")

    const [ keyword, setKeyword ] = useState("")
    const [ onlyInterestedMembers, setOnlyInterestedMembers ] = useState(false)
    const [ gender, setGender ] = useState(null)

    const [ maleAddButtonDisable, setMaleAddButtonDisable ] = useState(false)
    const [ femaleAddButtonDisable, setFemaleAddButtonDisable ] = useState(false)

    const [ level, setLevel] = useState("CHOOSE")
    const [ isOnlyLicensedPlayer, setIsOnlyLicensedPlayer ] = useState(false)

    const [ nbOfTicketToPay, setNbOfTicketToPay ] = useState(null)

    const [ disablePayment, setDisablePayment ] = useState(false)

    const [ modalOrder, setModalOrder ] = useState(0)

    const [ levelFrom, setLevelFrom ] = useState(LEVEL.OUT_OF_COMPETITION)
    const [ levelTo, setLevelTo ] = useState(LEVEL.LEGEND)
    const [ isDayOfEvent, setIsDayOfEvent ] = useState(false)

    const [ emittedTime, setEmittedTime ] = useState(null)

    useEffect(() => {

        const fetchNotifierTime = async () => {
            const res = await notifierService.notifierTime()

            setEmittedTime(res.data)
        }

        const fetchData = async () => {
            const res = await notifierService.notifier(emittedTime)

            const isUnAuthorized = res.status === 401 || res.status === 403 || res.httpCode === 403

            if (isUnAuthorized) return disconnect(navigate, context, location.pathname)
            else if (res.status > 500) return

            res.map(it => {
                setEmittedTime(it.emittedTime)

                const content = it.data
                const type = it.type

                if (type !== "GAME_UPDATED") return

                const eventGame = eventTransformer(content)

                if (game.id !== eventGame.id) return

                const registrationNotAllow = eventGame.teams.length >= eventGame.nbOfTeam && !eventGame.canUseWaitingList

                if (registrationNotAllow) return navigate("/home")

                setGame(eventGame)
            })
        }

        if (emittedTime == null) fetchNotifierTime().then()

        const timer = setInterval( () => { if (emittedTime != null) fetchData().then() }, 5000)

        return () => clearInterval(timer)
    }, [ emittedTime ])

    useEffect(() => {
        if (registerTeam(game).length >= game.nbOfTeam && !game.canUseWaitingList) return navigate("/home")

        setLevelFrom(LEVEL[game.levelFrom])
        setLevelTo(LEVEL[game.levelTo])
        setIsDayOfEvent(moment(game.startDate, "YYYY-MM-DD") <= moment())
    }, [ game ])

    useEffect(() => {
        if (user != null) return

        async function fetchUser() {
            const res = await userService.getById(context.user.userId)

            if (res.status === 401) return disconnect(navigate, context, -1)
            if (res.error != null || res.status >= 402 || res.httpCode != null) return
            if (res.type === "O2AUTH" && res.partner === "HELLO_ASSO") return navigate(-1)

            setUser(res)
            setPhoneNumber(res.phoneNumber)
        }

        if (user == null) fetchUser().then()
    }, [])

    useEffect(() => {
        if (user == null) return

        const nbOfMember = game.nbOfMember
        const nbOfExtraMember = game.nbOfExtraMember
        const minMaleInTeam = game.minMaleInTeam
        const minFemaleInTeam = game.minFemaleInTeam

        const isOnlyMale = nbOfMember === minMaleInTeam
        const isOnlyFemale = nbOfMember === minFemaleInTeam

        const isMaleCaptain = user.gender === "MALE"
        const isFemaleCaptain = user.gender === "FEMALE"

        if (isMaleCaptain && isOnlyFemale) navigate("/")
        if (isFemaleCaptain && isOnlyMale) navigate("/")

        const maxMaleNumber = nbOfMember - minFemaleInTeam
        const maxFemaleNumber = nbOfMember - minMaleInTeam

        const totalNbOfMember = requestMembers.length + 1
        const nbOfMaleMembers = requestMembers.filter(it => it.gender === "MALE").length + (user.gender === "MALE" ? 1 : 0)
        const nbOfFemaleMembers = requestMembers.filter(it => it.gender === "FEMALE").length + (user.gender === "FEMALE" ? 1 : 0)

        const isNbOfMemberExceed = totalNbOfMember < (nbOfMember + nbOfExtraMember)

        const isMaleAddButtonAllow = isNbOfMemberExceed && nbOfMaleMembers < maxMaleNumber + nbOfExtraMember
        const isFemaleAddButtonAllow = isNbOfMemberExceed && nbOfFemaleMembers < maxFemaleNumber + nbOfExtraMember

        setFemaleAddButtonDisable(!isFemaleAddButtonAllow)
        setMaleAddButtonDisable(!isMaleAddButtonAllow)

        if (teamName == null || teamName === "") setActionButtonDisable(true)
        else setActionButtonDisable(false)
    }, [ teamName, requestMembers, user ])

    useEffect(() => {
        if (user == null) return
        if (keyword === "") fetchUserSearch().then()
        if (keyword.length > 3) fetchUserSearch().then()
    }, [
        keyword, onlyInterestedMembers, gender, user
    ])

    const basketPrice = () => formatPrice(nbOfTicket() * playerPrice())

    const reinitModalOrder = () => setModalOrder(0)

    const isFull = () => game.nbOfTeam <= game.teams.length

    const showModal = () => {
        let modalTarget = modalOrder

        if (modalTarget === 0 || modalTarget === 3) {
            closeModal(MODAL_OPEN_ORDER[modalTarget])
            setModalOrder(1)
            return openModal(MODAL_OPEN_ORDER[0])
        }
        if (modalTarget === 1) {
            closeModal(MODAL_OPEN_ORDER[0])
            setModalOrder(2)
            modalTarget = 2
            if (game.shouldValidateLevel || game.shouldAskPlayerLicensed) return openModal(MODAL_OPEN_ORDER[1])
        }
        if (modalTarget === 2 && (!game.isPaymentAllow || isFull())) {
            closeModal(MODAL_OPEN_ORDER[1])
            return createTeam()
        }
        if (modalTarget === 2) {
            closeModal(MODAL_OPEN_ORDER[1])
            setModalOrder(3)
            if (game.isPaymentAllow) return openModal(MODAL_OPEN_ORDER[2])
        }
    }

    const createTeam = async () => {
        setDisablePayment(true)
        setTimeout(() => { setDisablePayment(false) }, 10000)

        const backUrl = process.env.BASE_HA_REDIRECT_URL + "/payments"

        const levelFormatted = level === "CHOOSE" ? null : level

        const res = await teamService.create(
            game.id,
            teamName,
            phoneNumber,
            nbOfTicket(),
            requestMembers.map(it => it.id),
            levelFormatted,
            backUrl, backUrl, backUrl
        )

        if (res.error != null || res.httpCode != null) return closeModal("pay-modal")
        if (!game.isPaymentAllow || isFull()) return navigate("/registrations")

        const captainUsername = res.captain

        const captain = res.members.find(it => it.username === captainUsername)

        const orderId = captain.orderId

        if (orderId == null) return closeModal("pay-modal")

        const orderRes = await orderService.getById(orderId)

        if (orderRes.error != null || orderRes.httpCode != null) return

        window.location.href = orderRes.payment.paymentUrl
    }

    const fetchUserSearch = async () => {

        const requestMemberIds = requestMembers.map(it => it.id)
        const teamDefaultMemberIds = game.teams.filter(t => t.type === "TEAM_DEFAULT").flatMap(t => t.members).flatMap(m => m.userId)

        const notIds = [ ...requestMemberIds, ...teamDefaultMemberIds, user.id ]

        const ids = onlyInterestedMembers ? game.membersSearchingTeam : null
        const size = onlyInterestedMembers && game.membersSearchingTeam.length > 0 ? game.membersSearchingTeam.length : 10

        const res = await userService.search(
            ids,
            notIds,
            keyword,
            gender,
            "USER_DEFAULT",
            true,
            [],
            size
        )

        if (res.error != null || res.status >= 402 || res.httpCode != null) return
        if (res.status === 401) return disconnect(navigate, context, -1)

        setUserSearch(res)
    }

    const playerPrice = () => {
        if (game.dayOfEventPlayerPrice == null || game.dayOfEventPlayerPrice === "") return game.playerPrice

        return isDayOfEvent ? game.dayOfEventPlayerPrice : game.playerPrice
    }

    const nbOfTicket = () => {
        return nbOfTicketToPay == null ? game.nbOfMember : nbOfTicketToPay
    }

    const onHandleRemove = (user) => {
        const newSearchUsers = [ ...userSearch, user ]
        const newRequestMember = requestMembers.filter(it => it.id !== user.id)

        setRequestMembers(newRequestMember)
        setUserSearch(newSearchUsers)
    }

    const onHandleAdd = (user) => {
        const newRequestMember = [ ...requestMembers, user ]
        const newSearchUsers = userSearch.filter(it => it.id !== user.id)

        setUserSearch(newSearchUsers)
        setRequestMembers(newRequestMember)
    }

    const isDisableTicketRange = () => {
        const startDate = moment(game.startDate, 'YYYY-MM-DD')
        const deadlineOfIndividualPaymentDate = startDate.subtract(game.deadlineOfIndividualPayment, "d")
        const currentDate = moment()

        return currentDate.isAfter(deadlineOfIndividualPaymentDate)
    }

    const isVisibleRegisterButton = (game) => game.canManageRegistration && !(registerTeam(game).length >= game.nbOfTeam)
    const isVisibleWaitingListButton = (game) => game.canManageRegistration && registerTeam(game).length >= game.nbOfTeam && game.canUseWaitingList

    const onHandleTicketRange = (e) => setNbOfTicketToPay(e.target.value)

    const onHandleFilter = (filter) => {

        const onlyInterestedMembers = filter["onlyInterestedMembers"]
        const gender = filter["gender"]

        setOnlyInterestedMembers(onlyInterestedMembers)

        if (gender != null) {
            if (gender === "MIXED") setGender(null)
            if (gender === "FEMALE") setGender("FEMALE")
            if (gender === "MALE") setGender("MALE")
        }
    }

    const removeButton = (user) => {
        return <button
            className="btn btn-danger"
            onClick={ () => onHandleRemove(user) }
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16" height="16"
                fill="currentColor"
                className="bi bi-dash-lg"
                viewBox="0 0 16 16"
            >
                <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"/>
            </svg>
        </button>
    }

    const addButton = (user) => {
        return <button
            className="btn btn-primary"
            onClick={ () => onHandleAdd(user) }
            disabled={ user.gender === "FEMALE" ? femaleAddButtonDisable : maleAddButtonDisable }
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16" height="16"
                fill="currentColor"
                className="bi bi-plus-lg" viewBox="0 0 16 16"
            >
                <path
                    fillRule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                />
            </svg>
        </button>
    }

    return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto px-0 pb-3">

        <div className="row border rounded shadow-sm px-3 py-2 mx-0 mb-3">
            <div className="col-7 col-md-9 px-0 my-auto">
                <p className="fs-2 m-0">{ i18n.t("common.reserve-your-place") }</p>
            </div>
            <div className="col-5 col-md-3 text-end m-auto px-0">
                <div className="d-flex justify-content-end">
                    <PActionButton
                        disabled={ actionButtonDisable }
                        onClick={ showModal }
                        hidden={ !isVisibleRegisterButton(game) }
                    >
                        <div className="row">
                            <div className="col-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-ticket-perforated-fill"
                                    viewBox="0 0 16 16"
                                    style={ { verticalAlign: "revert-layer" } }
                                >
                                    <path
                                        d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6zm4-1v1h1v-1zm1 3v-1H4v1zm7 0v-1h-1v1zm-1-2h1v-1h-1zm-6 3H4v1h1zm7 1v-1h-1v1zm-7 1H4v1h1zm7 1v-1h-1v1zm-8 1v1h1v-1zm7 1h1v-1h-1z"/>
                                </svg>
                            </div>
                            <div className="col px-1">{ i18n.t("button.register") }</div>
                        </div>
                    </PActionButton>
                    <button
                        className="btn btn-primary rounded-1"
                        disabled={ actionButtonDisable }
                        hidden={ !isVisibleWaitingListButton(game) }
                        onClick={ showModal }
                    >
                        { i18n.t("common.register-in-waiting-list") }
                    </button>
                </div>
            </div>
        </div>

        <div className="px-1 my-4">
            <div className="col card border-2" style={ { alignSelf: "center" } }></div>
        </div>

        <div className="row row-gap-3 mx-0">
            <GameRegistrySummary game={ game }/>

            <Input
                id="team-name"
                type="text"
                iconName="people"
                placeholder="teamName"
                label={ i18n.t("common.team-name") + "*" }
                value={ teamName }
                validator={ (value) => value.length > 0 }
                onHandleInput={ (id, value) => setTeamName(value) }
            />

            { captainCard(user) }

            <MembersSuggestion
                event={ game }
                requestMembers={ requestMembers }
                userSearch={ userSearch }
                addButton={ addButton }
                removeButton={ removeButton }
                onHandleFilter={ onHandleFilter }
                setKeyword={ setKeyword }
            />
        </div>

        <div
            id="phone-modal"
            className="modal fade"
            data-bs-keyboard="false"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ i18n.t("common.phone-number-verification") }</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={ reinitModalOrder }
                        ></button>
                    </div>
                    <div className="modal-body text-center">
                        <div className="row row-gap-3 mx-0 px-0">
                            <Input
                                id="phone"
                                type="text"
                                iconName="phone"
                                placeholder={ i18n.t("common.phone-number") + "*" }
                                validator={ emptyablePhoneNbValidator }
                                onHandleInput={ (id, value) => setPhoneNumber(value) }
                                value={ phoneNumber }
                            />
                        </div>
                    </div>
                    <div className="modal-footer p-0">
                        <button
                            className="btn btn-primary rounded-top-0 w-100 m-0"
                            disabled={ !phoneNbValidator(phoneNumber) }
                            onClick={ showModal }
                        >
                            { i18n.t("button.next") }
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="ask-modal"
            className="modal fade"
            data-bs-keyboard="false"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h5 className="modal-title">{ i18n.t("common.finalizing-registration") }</h5>
                        <button
                            className="btn-close"
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={ reinitModalOrder }
                        ></button>
                    </div>
                    <div className="modal-body overflow-x-hidden px-0 py-0">
                        <ul className="list-group list-group-flush">
                            <div className="row row-gap-2">
                                <li className="list-group-item py-3 px-4" hidden={ !game.shouldValidateLevel }>
                                    <div>
                                        <select
                                            className="form-select"
                                            aria-label="level-select-from"
                                            value={ level }
                                            onChange={ (e) => setLevel(e.target.value) }
                                        >
                                            <option defaultValue value={ "CHOOSE" }>{ i18n.t("common.team-level") }</option>
                                            { levels(levelFrom, levelTo) }
                                        </select>
                                    </div>
                                </li>
                                <li className="list-group-item pb-3 px-4" hidden={ !game.shouldAskPlayerLicensed }>
                                    <div className="border rounded p-4">
                                        <div className="form-check">
                                            <input
                                                id="only-licensed-player-checkbox"
                                                className="form-check-input"
                                                type="checkbox"
                                                value={ isOnlyLicensedPlayer }
                                                onChange={ () => setIsOnlyLicensedPlayer(!isOnlyLicensedPlayer) }
                                            />
                                            <label
                                                className="form-check-label small"
                                                htmlFor="only-licensed-player-checkbox"
                                            >
                                                { i18n.t("common.license-ask") }
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                    <div className="modal-footer p-0"></div>
                    <button
                        className="btn btn-primary rounded-top-0 w-100 h-100"
                        onClick={ showModal }
                        disabled={
                            (game.shouldValidateLevel && level === "CHOOSE") || (game.shouldAskPlayerLicensed && !isOnlyLicensedPlayer)
                        }
                    >
                        { i18n.t("button.next") }
                    </button>
                </div>
            </div>
        </div>

        <div
            id="pay-modal"
            className="modal fade"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ i18n.t("common.hello-asso.payment-with") }</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            disabled={ disablePayment }
                            onClick={ reinitModalOrder }
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row row-gap-3">
                            <span className="small">
                                { i18n.t("common.hello-asso.payment-disclaimer") }
                            </span>
                            <div>
                                <div className="text-center border rounded py-4 px-4">
                                    <label htmlFor="nbOfTicketRange" className="form-label">
                                        { i18n.t("common.book-for") } { nbOfTicket() } { i118nTPlayerWith(nbOfTicket()) } { basketPrice() }
                                    </label>
                                    <input
                                        id="price-range"
                                        type="range"
                                        step="1"
                                        className="form-range"
                                        value={ nbOfTicket() }
                                        onChange={ onHandleTicketRange }
                                        min="1" max={ game.nbOfMember }
                                        disabled={ isDisableTicketRange() }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer p-0"></div>
                    <ButtonHelloAssoPayment onClick={ createTeam } disabled={ disablePayment } />
                </div>
            </div>
        </div>

    </div>
}