import "../css/rainbow.css"

import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import i18n from "i18next"

export default function(props) {
    const navigate = useNavigate()

    const [ searchParams] = useSearchParams()

    const params = {
        message: searchParams.get("message")
    }

    const messageFromParams = params.message

    const isNotEmptyMessage = messageFromParams != null

    const message = isNotEmptyMessage ? i18n.t(messageFromParams) : i18n.t("success.default-message")

    return <Success { ...props } navigate={ navigate } message={ message } />
}

class Success extends React.Component {

    constructor(props) {
        super(props)

        this.navigate = this.props.navigate
    }

    render() {
        return <div className="rainbow col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto p-0 rounded">
            <div className="row shadow-sm border rounded mx-0 text-center p-4">
                <div className="col-12 px-0 m-auto m-4">
                    <h1 className="align-middle">🎊 { i18n.t("success.title") } 🎊</h1>
                    <div className="my-2">...</div>
                    <div className="lead fw-semibold align-bottom">
                        { this.props.message }
                    </div>
                </div>
            </div>
        </div>
    }
}