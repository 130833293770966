import { APPLICATION_JSON, GET } from "../const/request"
import { resParser } from "../const/utils"

class GeoLocService {

    async search(
        keyword,
    ) {
        const path = process.env.GEO_LOC_URL + "/search/?q=" + keyword

        const response = await fetch(path, {
            method: GET,
            headers: { "Content-Type": APPLICATION_JSON },
        })

        return await resParser(response)
    }
}

export const geoLocService = new GeoLocService()