import React, { useEffect, useState } from "react"
import i18n from "i18next"
import Input from "./input"
import { InputNumber } from "./input-number"

export default function InputGameAdvanced(
    props
) {
    const [ visibility, setVisibility ] = useState(props.visibility)
    const [ canManageRegistration, setCanManageRegistration ] = useState(props.canManageRegistration)
    const [ canUseWaitingList, setCanUseWaitingList ] = useState(props.canUseWaitingList)
    const [ shouldValidateLevel, setShouldValidateLevel ] = useState(props.shouldValidateLevel)
    const [ shouldAskPlayerLicensed, setShouldAskPlayerLicensed ] = useState(props.shouldAskPlayerLicensed)

    const [ nbOfExtraMember, setNbOfExtraMember] = useState(props.nbOfExtraMember)
    const [ deadlineOfIndividualPayment, setDeadlineOfIndividualPayment ] = useState(props.deadlineOfIndividualPayment)

    const [ dayOfEventPlayerPrice, setDayOfEventPlayerPrice ] = useState(props.dayOfEventPlayerPrice)

    const [ disabled, setDisabled ] = useState(props.disabled)

    useEffect(() => { setVisibility(props.visibility) }, [ props.visibility ])
    useEffect(() => { setCanManageRegistration(props.canManageRegistration) }, [ props.canManageRegistration ])
    useEffect(() => { setNbOfExtraMember(props.nbOfExtraMember) }, [ props.nbOfExtraMember ])
    useEffect(() => { setCanUseWaitingList(props.canUseWaitingList) }, [ props.canUseWaitingList ])
    useEffect(() => { setShouldValidateLevel(props.shouldValidateLevel) }, [ props.shouldValidateLevel ])
    useEffect(() => { setShouldAskPlayerLicensed(props.shouldAskPlayerLicensed) }, [ props.shouldAskPlayerLicensed ])

    useEffect(() => { setDayOfEventPlayerPrice(props.dayOfEventPlayerPrice) }, [ props.dayOfEventPlayerPrice ])

    useEffect(() => { setDisabled(props.disabled) }, [ props.disabled ])

    useEffect(() => {
        props.onHandleAdvanceSettings(
            visibility,
            canManageRegistration,
            canUseWaitingList,
            shouldValidateLevel,
            shouldAskPlayerLicensed,
            nbOfExtraMember,
            deadlineOfIndividualPayment,
            dayOfEventPlayerPrice
        )
    }, [
        visibility,
        canManageRegistration,
        canUseWaitingList,
        shouldValidateLevel,
        shouldAskPlayerLicensed,
        nbOfExtraMember,
        deadlineOfIndividualPayment,
        dayOfEventPlayerPrice
    ])

    const onHandleDayOfEventPlayerPrice = (id, value) => setDayOfEventPlayerPrice(value)

    const closeOrSaveButton = !!props.onSave ? <button
        className="btn btn-primary"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={ props.onSave }
    >{ i18n.t("button.save") }</button> : <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
    ></button>

    return <div
        className="modal fade"
        data-bs-keyboard="false"
        id="registryModal"
        tabIndex="-1"
    >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                    <h1 className="modal-title fs-5">{ i18n.t("advanced-settings.name") }</h1>
                    { closeOrSaveButton }
                </div>
                <div className="modal-body px-0">

                    <ul className="list-group list-group-flush">
                        <li className="list-group-item pb-4">
                            <div className="d-flex justify-content-end">
                                <div className="btn-group col-12" role="group">
                                    <input
                                        id="public-visibility-radio"
                                        name="btnradio"
                                        className="btn-check"
                                        type="radio"
                                        autoComplete="off"
                                        checked={ visibility === "PUBLIC" }
                                        onChange={ () => setVisibility("PUBLIC") }
                                    />
                                    <label className="btn btn-outline-primary" htmlFor="public-visibility-radio">
                                        { i18n.t("advanced-settings.public") }
                                    </label>
                                    <input
                                        id="private-visibility-radio"
                                        name="btnradio"
                                        className="btn-check"
                                        type="radio"
                                        autoComplete="off"
                                        checked={ visibility === "PRIVATE" }
                                        onChange={ () => setVisibility("PRIVATE") }
                                    />
                                    <label className="btn btn-outline-primary" htmlFor="private-visibility-radio">
                                        { i18n.t("advanced-settings.private") }
                                    </label>
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item py-4">
                            <div className="form-check form-switch">
                                <input
                                    id="canManageRegistration"
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={ canManageRegistration }
                                    onChange={ () => setCanManageRegistration(!canManageRegistration) }
                                    data-bs-toggle="collapse"
                                    data-bs-target="#registryQuestions"
                                    aria-expanded={ canManageRegistration }
                                    disabled={ disabled }
                                />
                                <label className="form-check-label" htmlFor="canManageRegistration">
                                    { i18n.t("advanced-settings.use-registration") }
                                </label>
                            </div>
                            <div className={ props.collapseRegistration } id="registryQuestions">
                                <div className="card card-body mt-3 shadow">
                                    <div className="form-check form-switch">
                                        <input
                                            id="canUseWaitingList"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={ canUseWaitingList }
                                            onChange={ () => setCanUseWaitingList(!canUseWaitingList) }
                                            disabled={ disabled }
                                        />
                                        <label className="form-check-label" htmlFor="canUseWaitingList">
                                            { i18n.t("advanced-settings.waiting-list") }
                                        </label>
                                    </div>
                                    <div className="form-check form-switch mt-3">
                                        <input
                                            id="shouldValidateLevel"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={ shouldValidateLevel }
                                            onChange={ () => setShouldValidateLevel(!shouldValidateLevel) }
                                            disabled={ disabled }
                                        />
                                        <label className="form-check-label" htmlFor="shouldValidateLevel">
                                            { i18n.t("advanced-settings.level-validation") }
                                        </label>
                                    </div>
                                    <div className="form-check form-switch mt-3">
                                        <input
                                            id="shouldAskPlayerLicensed"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={ shouldAskPlayerLicensed }
                                            onChange={ () => setShouldAskPlayerLicensed(!shouldAskPlayerLicensed) }
                                            disabled={ disabled }
                                        />
                                        <label className="form-check-label" htmlFor="shouldAskPlayerLicensed">
                                            { i18n.t("advanced-settings.accept-only-licensed-player") }
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item py-4" hidden={ !props.isPaymentAllow }>
                            <div className="row row-gap-3 mx-0 px-0">
                                <div className="card shadow-sm px-0">
                                    <div className="card-body">
                                        <span className="small">
                                            { i18n.t("common.nb-of-day-before-definition") }
                                        </span>
                                    </div>
                                </div>
                                <div className="px-0">
                                    <InputNumber
                                        label={ i18n.t("common.nb-of-day-before") }
                                        initialValue={ deadlineOfIndividualPayment }
                                        minValue={ 0 }
                                        onChange={ setDeadlineOfIndividualPayment }
                                        hideFiveButton={ true }
                                    />
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item py-4">
                            <div className="row row-gap-3 px-0">
                                <InputNumber
                                    label={ i18n.t("common.nb-of-extra-member") }
                                    initialValue={ nbOfExtraMember }
                                    minValue={ 0 }
                                    onChange={ setNbOfExtraMember }
                                    hideFiveButton={ true }
                                    disabled={ disabled }
                                />
                            </div>
                        </li>
                        <li className="list-group-item pt-4">
                            <div className="row row-gap-3 mx-0 pt-0">
                                <Input
                                    id="dayOfEventPlayerPrice"
                                    type="number"
                                    iconName="price"
                                    placeholder={ i18n.t("advanced-settings.price-at-day-of-event") }
                                    validator={ () => true }
                                    onHandleInput={ onHandleDayOfEventPlayerPrice }
                                    value={ dayOfEventPlayerPrice }
                                />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
}