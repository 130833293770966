import { capitalizeFirstLetter, gender, regionFinder, regionTranslation } from "../const/common"
import moment from "moment"
import i18n from "i18next"
import { formatPrice, newTab, startDate } from "../const/utils"
import React from "react"

export const stateButton = (order) => {
    if (order.payment.state === "CANCELED") return <button
        className="btn btn-sm btn-danger border-danger bg-danger h-100"
        style={ { placeContent: "center" } }
    >
        { i18n.t("order-state.CANCELED") }
    </button>
    if (order.payment.state === "REFUNDED") return <button
        className="btn btn-sm btn-warning border-warning bg-warning h-100"
        style={ { placeContent: "center" } }
    >
        { i18n.t("order-state.REFUNDED") }
    </button>
    if (order.payment.state === "TO_PAY_ONLINE") return <button
        onClick={ () => newTab(order.payment.paymentUrl) }
        className="btn btn-sm btn-warning h-100"
        style={ { placeContent: "center" } }
    >
            { i18n.t("order-state.TO_PAY_ONLINE") } <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16" height="16"
            fill="currentColor"
            className="bi bi-arrow-right-circle"
            viewBox="0 -2 16 18"
        >
            <path
                fillRule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
            />
        </svg>
    </button>

    if (order.payment.state === "PROCESSED" && order.isPOA) return <button
        className="btn btn-sm btn-info border-info bg-info h-100"
        style={ { placeContent: "center" } }
    >
        { i18n.t("order-state.POA_PROCESSED") }
    </button>

    if (order.payment.state === "PROCESSED") return <button
        onClick={ () => newTab(order.payment.paymentReceiptUrl) }
        className="btn btn-sm btn-info h-100"
        style={ { placeContent: "center" } }
    >
        { i18n.t("order-state." + order.payment.state) } <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16" height="16"
            fill="currentColor"
            className="bi bi-arrow-right-circle"
            viewBox="0 -2 16 18"
        >
            <path
                fillRule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
            />
        </svg>
    </button>
}

const itemCard = (item) => <div className="card w-100">
    <div className="card-body text-start small p-1">
        <div className="row row-gap-2 mx-0 py-2">
            <div className="d-flex justify-content-between">
                <div>
                    { item.eventName }
                </div>
                <div className="text-end ps-1" style={ { minWidth: "fit-content" } }>
                    { item.quantity } x { capitalizeFirstLetter(item.type) } - { formatPrice(item.amount) }
                </div>
            </div>
            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16"
                    fill="currentColor"
                    className="bi bi-calendar-event"
                    viewBox="0 0 17 16"
                >
                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                </svg>
                { "\t" }{ startDate(item.dates.startDate) }
            </div>
            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16"
                    fill="currentColor"
                    className="bi bi-clock"
                    viewBox="0 0 17 16"
                >
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                </svg>
                { "\t" }{ item.dates.startTime.slice(0, -3) } - { item.dates.endTime.slice(0, -3) }
            </div>
            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16"
                    fill="currentColor"
                    className="bi bi-geo-fill"
                    viewBox="0 0 16 16"
                >
                    <path
                        fillRule="evenodd"
                        d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411"
                    />
                </svg>
                { "\t" }{ item.locale.address }, { regionTranslation(regionFinder(item.locale.region)) }
            </div>
            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16"
                    fill="currentColor"
                    className="bi bi-people-fill"
                    viewBox="0 -1 17 16"
                >
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
                </svg>
                { "\t" }{ item.teamCondition.nbOfMember }v{ item.teamCondition.nbOfMember } { gender(item.teamCondition) }
            </div>
        </div>
    </div>
</div>

export const orderCard = (
    order,
    nameOfTheUser,
    shadow = "shadow-sm"
) => {
    const paymentDate = moment(order.createdDate).locale(i18n.language)

    return <div className={ "card emergence " + shadow }>
        <div className="card-body">
            <div className="row row-gap-3">
                <div className="d-flex mx-0 px-0" style={ { alignItems: "center" } }>
                    <button
                        className="btn text-start fw-bold border px-2"
                        style={ { minHeight: "31px", alignContent: "center" } }
                    >
                        { i18n.t("common.payment-of") } { paymentDate.format("YYYY-MM-DD") } { i18n.t("common.at") } { paymentDate.format("HH:mm") }
                    </button>
                    <button
                        className="btn btn-sm border-0"
                        style={ { verticalAlign: "sub" } }
                        disabled
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16"
                            fill="currentColor"
                            className="bi bi-arrow-right"
                            viewBox="0 0 16 16">
                            <path
                                fillRule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                            />
                        </svg>
                    </button>
                    { stateButton(order) }
                </div>

                <div className="px-0" hidden={ nameOfTheUser == null }>
                    <div className="d-flex px-0" style={ { alignItems: "center" } }>
                        <div className="px-0" hidden={ nameOfTheUser == null }>
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-return-right"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0" hidden={ nameOfTheUser == null }>
                            <button className="btn btn-sm border px-3">{ i18n.t("common.identifier") }</button>
                        </div>
                        <div className="px-0" hidden={ nameOfTheUser == null }>
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-right"
                                    viewBox="0 0 16 16">
                                    <path
                                        fillRule="evenodd"
                                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0" hidden={ nameOfTheUser == null }>
                            <button className="btn btn-sm border px-3">#{ order.payment.id }</button>
                        </div>
                    </div>
                </div>

                <div className="px-0" hidden={ nameOfTheUser == null }>
                    <div className="d-flex px-0" style={ { alignItems: "center" } }>
                        <div className="px-0" hidden={ nameOfTheUser == null }>
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-return-right"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0" hidden={ nameOfTheUser == null }>
                            <button className="btn btn-sm border px-3">{ nameOfTheUser }</button>
                        </div>
                    </div>
                </div>
                <div className="px-0">
                    <div className="d-flex px-0" style={ { alignItems: "center" } }>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-return-right"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0">
                            <button className="btn btn-sm border px-3">
                                Total: { formatPrice(order.totalAmount) }
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row row-gap-3 px-0 mx-0">
                    { order.items.map((item, index) => <div className="col-12 px-0" key={ index }>{ itemCard(item) }</div>) }
                </div>
            </div>
        </div>
    </div>
}