import i18n from "i18next"

import React, { useContext, useEffect, useState } from "react"
import { isNotConnected } from "../const/utils"
import { Context } from "../const/context"
import Login from "./login"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import GameRegistryAsGuest from "./game-registry-as-guest"
import GameRegistriesAsConnected from "./game-registry-as-connected"
import { closeModal, openModal } from "../const/common"
import { gameService } from "../service/game-service"

export default function GameRegistry() {
    const location = useLocation()
    const navigate = useNavigate()

    const context = useContext(Context)

    const { id } = useParams()
    const [ searchParams] = useSearchParams()

    const isPaymentFailed = searchParams.get("status") === "payment-failed"
    const isBackForRegistry = searchParams.get("status") === "back"

    const [ game, setGame ] = useState()

    useEffect(() => {
        if (game != null) return

        async function fetchGame() {
            const res = await gameService.getById(id)

            if (res == null) return navigate("/")
            if (res.status >= 400) return navigate("/")
            if (res.length === 0) return navigate("/")

            setGame(res)
        }

        if (game == null) fetchGame().then()
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => { if (isPaymentFailed) openModal("failed-payment-modal") }, 1000)

        return () => clearInterval(timer)
    }, [])

    const userNotConnected = isNotConnected(context)
    const state = location.state
    const guestMode = state ? state.guestMode : false

    if (game == null) return <React.Fragment></React.Fragment>
    if (!game.canManageRegistration) navigate("/home")

    const isAvailableRegistration = game.nbOfTeam > game.teams.length

    if (!isPaymentFailed && !isBackForRegistry && userNotConnected && !guestMode) return <Login
        guestMode={ isAvailableRegistration }
        previousPath={ location.pathname }
        haHide={ true }
    />

    function render(
        userNotConnected
    ) {
        if (userNotConnected) return <GameRegistryAsGuest game={ game }/>

        return <GameRegistriesAsConnected game={ game }/>
    }

    return <div>
        { render(userNotConnected) }

        <div
            id="failed-payment-modal"
            className="modal fade"
            data-bs-backdrop="static"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ i18n.t("common.hello-asso.payment-with") }</h5>
                    </div>
                    <div className="modal-body">
                        <span className="small">
                            { i18n.t("common.your-payment-failed") }
                        </span>
                    </div>
                    <div className="modal-footer p-0"></div>
                    <button
                        className="btn btn-primary rounded-top-0 px-0"
                        onClick={ () => closeModal("failed-payment-modal") }
                    >{ i18n.t("common.close") }</button>
                </div>
            </div>
        </div>
    </div>

}