{
  "navBar": {
    "home": "Home",
    "submitTournament": "Submit tournament",
    "my-tournaments": "My tournaments",
    "all-tournaments": "All Tournaments",
    "gameToValidate": "Game to validate",
    "contact": "Contact",
    "games": "Games",
    "users": "Users",
    "login": "Login",
    "account": {
      "name": "Account",
      "my-registrations": "My Registrations",
      "my-payments": "My payments",
      "setting": "Setting",
      "logout": "Logout"
    }
  },
  "sideBar": {
    "title": "Filters",
    "keyword": "Keyword",
    "region": {
      "name": "Region",
      "choose": "Choose"
    },
    "date": {
      "startDate": "Start date",
      "endDate": "End date"
    },
    "search": "Search"
  },
  "record": {
    "dragNDrop": "Drag 'n' drop some files here, or click to select a picture",
    "limit": "Limit",
    "picture-too-heavy": "Picture too heavy"
  },
  "common": {
    "hello-asso": {
      "payment-with": "Payment via HelloAsso",
      "payment-disclaimer": "HelloAsso's solidarity model guarantees that 100% of your payment will be paid to the chosen association. You can support the help they provide to associations by leaving a voluntary contribution to HelloAsso at the time of your payment.",
      "payment-fail": "Your payment failed"
    },
    "yes": "Yes",
    "no": "No",
    "to": "to",
    "at": "at",
    "of": {
      "default": "of",
      "d": ""
    },
    "and": "and",
    "new": "new",
    "or": "or",
    "more": "More",
    "choose": "Choose",
    "hasBeen": "has been",
    "open": "Open",
    "register": "Register",
    "close": "Close",
    "copy": "Copy",
    "identifier": "Identifier",
    "confirm": "Confirm",
    "address": "Address",
    "phoneNumber": "Phone number",
    "mail": "Email",
    "details": "Details",
    "country": "Country",
    "username": "Username",
    "user-tag": "Usertag",
    "organization-slug": "Organization slug",
    "rna-number": "RNA Number",
    "password": {
      "name": "Password",
      "rule": {
        "1SpecialCharacter": "One special character",
        "1UppercaseCharacter": "One uppercase character",
        "1LowercaseCharacter": "One lowercase character",
        "moreThan8Characters": "More than 8 characters"
      }
    },
    "name": "Name",
    "firstname": "Firstname",
    "lastname": "Lastname",
    "male": "Male",
    "female": "Female",
    "other": "Other",
    "view": "View",
    "team": "Team",
    "search": "Search",
    "level": "Level",
    "captain": "Captain",
    "team-name": "Team name",
    "phone-number-verification": "Confirm your phone number",
    "phone-number": "Phone number",
    "finalizing-registration": "Finalizing registration",
    "license-ask": "By checking this box you validate that all players have a license",
    "book-for": "Book for",
    "payments": "Payments",
    "payment-of": "Payment of",
    "event": "Event",
    "members": "Members",
    "hourly": "Hourly",
    "settings": "Settings",
    "private": "Private",
    "public": "Public",
    "filter": "Filter",
    "no-result-found": "No results found",
    "mixed": "Mixed",
    "girl": "Girls",
    "boy": "Boy",
    "delete": "Delete",
    "only-member-looking-for-a-team": "Only player looking for a team",
    "looking-for-a-team": "Looking for a team",
    "teams": "Teams",
    "detail": "Detail",
    "whats-app-group-link": "What's App group link",
    "join-whats-app-group": "Join What's App Group",
    "send-message-to-captain": "Send a message to the captain",
    "nb-of-extra-member": "Number of extra-member",
    "nb-of-day-before": "Number of day before",
    "nb-of-day-before-definition": "Define the number of days before the event for the end of individual payment",
    "additional-player": "Additional player",
    "additional-players": "Additional players",
    "accepted": "accepted",
    "accepteds": "accepted",
    "register-in-waiting-list": "Register in waiting list",
    "waiting-list": "Waiting list",
    "auth-key-received-by-email": "Authorization key received by email",
    "on-request": "Waiting for response",
    "on-requested": "Waiting your response",
    "player-with": "player with",
    "players-with": "players with",
    "check-teams": "Check teams",
    "validated-teams": "Validated teams",
    "teams-being-registered": "Teams being registered",
    "waitlisted-teams": "Waitlisted teams",
    "your-payment-failed": "Your payment failed",
    "reserve-your-place": "Reserve your place",
    "basket": "Basket",
    "team-registration": "Team registration",
    "players": "Players",
    "continue": "Continue",
    "payment-via-helloasso": "Payment via HelloAsso",
    "helloass-business-model": "HelloAsso's solidarity model guarantees that 100% of your payment will be paid to the chosen association. You can support the help they provide to associations by leaving a voluntary contribution to HelloAsso at the time of your payment",
    "send-authorization-key": "Send authorization key",
    "registrations": "Registrations",
    "organization": "Organization",
    "no-registrations-yet": "No registrations yet",
    "no-payment-yet": "No payment yet",
    "team-level": "Team level",
    "born-date": "Born date",
    "gender": "Gender",
    "no-games-founded": "No games founded",
    "archive": "Archive",
    "certified": "Certified",
    "not-certified": "Not certified"
  },
  "region": {
    "ANTWERP": "Antwerp",
    "EAST_FLANDERS": "East Flanders",
    "FLEMISH_BRABANT": "Flemish Brabant",
    "LIMBURG": "Limburg",
    "WEST_FLANDERS": "West Flanders",
    "HAINAUT": "Hainaut",
    "LIEGE": "Liège",
    "LUXEMBOURG": "Luxembourg",
    "NAMUR": "Namur",
    "WALLOON_BRABANT": "Walloon Brabant",
    "BRUSSELS_CAPITAL_REGION": "Brussels Capital Region"
  },
  "button": {
    "enable": "Enable",
    "activate": "Enable",
    "activated": "Activated",
    "disable": "Disable",
    "disabled": "Disabled",
    "reOpen": "Re-open",
    "waitingForValidation": "Waiting for validation",
    "add": "Add",
    "update": "Update",
    "cancel": "Cancel",
    "delete": "Delete",
    "back": "Back",
    "save": "Save",
    "send": "Send",
    "advanced-settings": "Advanced Settings",
    "register": "Register",
    "next": "Next"
  },
  "toast": {
    "error": {
      "NOT_FOUND": "Not found",
      "USER_NOT_FOUND": "User is not found",
      "SESSION_NOT_FOUND": "Session is not found",
      "GAME_NOT_FOUND": "Game is not found",
      "AUTH_NOT_FOUND": "Accreditation is not found",
      "MEDIA_NOT_FOUND": "Publication is not found",

      "INVALID_MAIL": "Mail not valid",
      "MAIL_VALIDATION_FAIL": "Validation fail",
      "AUTHENTICATION_DENIED": "Access denied",
      "USER_VERIFICATION_FAILED": "User not yet verified",
      "INCORRECT_REASON": "Error on the process",
      "INVALID_GAME_STATUS": "Game is on invalid status",
      "INVALID_UPDATE_IN_GAME_PAST": "Game is on past state",
      "INVALID_ROOT_USER_DISABLING": "Invalid root user can not be disabled",
      "USER_VERIFIED": "Account already verified",
      "USER_NOT_ENABLE": "User is disabled",
      "CODE_NOT_FOUND": "Retry with another code or regenerate one",
      "USER_DUPLICATION": "User already existing",
      "CREDENTIAL_DENIED": "Authorization denied",
      "FILE_SIZE": "Invalid file size",
      "USERNAME_ALREADY_EXIST": "Username already taken",
      "TEAM_NOT_FOUND": "The team was not found",
      "MEMBER_NOT_FOUND": "The team member was not found",
      "CHECKOUT_NOT_FOUND": "The payment was not found",
      "INVALID_HA_ORGANIZATION": "The organization is invalid",
      "HA_REFRESH_TOKEN_EXPIRED": "HelloAsso credentials have expired",
      "REGISTRATION_DENIED": "Reservation is not allowed",
      "PAYMENT_PROVIDER_NOT_FOUND": "HelloAsso link broken",
      "EXCEEDED_NB_OF_REGISTRATION": "The maximum number of registrants has been reached",
      "FULLY_REGISTERED_WL_NOT_ALLOW": "The maximum number of registrants has been reached and waiting list not allow",
      "EXCEEDED_NB_MALE_MEMBER": "The maximum number boy(s) reached",
      "EXCEEDED_NB_FEMALE_MEMBER": "The maximum number girl(s) reached",
      "INVALID_NB_OF_EXTRA_MEMBER": "The number of extra player is invalid",
      "INVALID_LEVEL": "The level is invalid",
      "USER_ALREADY_IN_TEAM": "The player is already in a team",
      "TEAM_NAME_ALREADY_TAKEN": "Team name already taken",
      "CANCEL_NOT_ALLOW_PSA": "Cancellation impossible, payment still in available",
      "DELETE_NOT_ALLOW_PSA": "Deletion impossible, payment still in available",
      "DELETE_NOT_ALLOW_MEMBER_PROCEED_PAYMENT": "Player deletion impossible, payment still in available",
      "NB_OF_TEAM_PAYMENT_EXCEEDED": "Invalid update, number of registered or registering team is more than new number of team",
      "UPDATE_NOT_ALLOW_TEAM_REGISTERED": "Invalid update, team are already registered or registering",
      "REQUEST_MEMBER_NOT_FOUND": "Player was not found",
      "NO_NEW_TICKET_NEEDED": "No need for another reservation",
      "TOO_MUCH_TICKET_TO_REGISTER": "The number of reservations greater than the number of players in the team",
      "INVALID_GENDER_TO_REGISTER": "Invalid gender to register",
      "INVALID_USER_GENDER_TO_REGISTER": "For reservation your gender must be either female or male",
      "CAPTAIN_NOT_FOUND": "The captain was not found",
      "NOT_ALLOW_TO_REMOVE_PP": "Deletion impossible, teams are registered or in the process of registering",

      "server-error": "An unexpected server error has occurred"
    },
    "success": {
      "user-created": "User successfully created",
      "connected": "Connected",
      "new-code-generated": "New code generated in your mail box",
      "password-update": "Password updated"
    },
    "info": {
      "event-waiting-validate": "Event waiting validation"
    }
  },
  "level": {
    "name": "Level",
    "leisure": "Leisure",
    "dep": "Departmental",
    "reg": "Regional",
    "nat": "National",
    "elite": "Elite",
    "pro": "Pro",
    "legend": "Legend"
  },
  "success": {
    "title": "Congratulations",
    "default-message": "Your action has been validated",
    "team-created": "Your team has been successfully created"
  },
  "error": {
    "message": "Sorry, an unexpected error has occurred."
  },
  "advanced-settings": {
    "name": "Advanced Settings",
    "public": "Public",
    "private": "Private",
    "use-registration": "Use Accrotournois for registrations",
    "waiting-list": "Waiting list",
    "level-validation": "Level validation at registration",
    "accept-only-licensed-player": "Accept only licensed player",
    "price-additional-player": "Price of additional player",
    "price-at-day-of-event": "Price of player at the event day"
  },
  "settings": {
    "name": "Settings",
    "theme": {
      "auto": "Auto",
      "light": "Light",
      "dark": "Dark"
    },
    "language": {
      "name": "Language",
      "french": "French",
      "english": "English"
    }
  },
  "home": {
    "noGameFound": "No games found",
    "recently-added": "Recently added",
    "weekOf": "Week of",
    "ground": "Ground",
    "team-registered": "Number of teams registered",
    "players": "Players",
    "price-player": "Price of player",
    "price-additional-player": "Price of additional player",
    "price-at-day-of-event": "Price of player at the event day",
    "gender": {
      "onlyMale": "Only male",
      "onlyFemale": "Only female",
      "unknown": "Multiple choice",
      "mixed": "Mixed",
      "male": "male",
      "female": "female",
      "min": "minimum"
    },
    "deleteFilter": "Delete filters",
    "sureToDeleteGame": "Are you sure to delete"
  },
  "login": {
    "title": "Please sign in",
    "rememberMe": "Remember me",
    "signIn": "Sign in",
    "continue-as-guest": "Continue as guest",
    "createAccount": "create an account",
    "passwordForgotten": "password forgotten",
    "validAccount": "valid your account",
    "log-with-ha": "Log with HelloAsso"
  },
  "registry": {
    "title": "Please registry",
    "mailAddress": "Email address",
    "passwordConfirmation": "Password Confirmation",
    "bornDate": "Born date",
    "bornDateInput": "Born date - ex: 1970-02-30",
    "registry": "Registry",
    "rule": {
      "bornDate": {
        "userShouldBeOlderThan18": "User should be older than 18 years"
      }
    }
  },
  "identifier": {
    "title": "Select your identifier",
    "next": "Next"
  },
  "password": {
    "title": "Reset your password",
    "newPassword": "New password",
    "code": "code",
    "reset": "Reset",
    "newCode": "New code"
  },
  "code": {
    "title": "Verification",
    "code": "Code",
    "send": "Send",
    "newCode": "New code"
  },
  "gameCardEdition": {
    "title": "My tournaments",
    "newEvent": "New"
  },
  "games-all": {
    "title": "All tournaments"
  },
  "game": {
    "public": "Public",
    "private": "Private",
    "share": "Share",
    "organizer-management": "Manage organizers",
    "connected": "Connected",
    "use-helloasso": "Use HelloAsso for payment",
    "connect-helloasso": "Connect to HelloAsso",
    "link-helloasso": "Link to your HelloAsso account",
    "helloasso-message": "HelloAsso helps associations collect online payments and offers its services for free. It covers all transaction costs so that you can benefit from all the amounts paid by your audiences, free of charge. The voluntary contributions left by them are their only source of income.",
    "activate-registration": "Activate registration",
    "delete-payment-link": "Delete payment link",
    "network-publish": "Publish on Accrootournois FaceBook and Instagram",
    "publish": "Publish",
    "tip-before-publish": "Tips before posting on Accrootournois social networks",
    "why-use-aot-for-registration": "Why use Accrotournois for registration management",
    "inform-registration-on-aot": "Inform the community of registrations on Accrootournois",
    "have-waiting-list": "To have a waiting list",
    "have-early-access-registration": "Manage early access registrations",
    "have-player-level-validation": "Validate the level of players upon registration",
    "accept-only-licensed-player": "Accept only licensed player",
    "addtional-player-management": "Additional player management",
    "add-payment=with-helloasso": "Adding a payment via HelloAsso",
    "if-you-use-helloasso": "If you want to use HelloAsso for payment",
    "helloasso-connect-tips": "It is best to connect to HelloAsso before publishing on social networks, so Accrootournois can inform future players how to register and pay.",
    "warning-one-publication": "Please note that only one publication is possible",
    "publiah-anyway": "Publish anyway"
  },
  "gameEdition": {
    "title": "Organize your event",
    "name": "Name",
    "address": "Address",
    "region": "Region",
    "start-at": "Start at",
    "end-at": "End at",
    "price": "Price",
    "player": "Player",
    "phoneNumber": "Phone number",
    "mail": "mail",
    "file": {
      "name": "Choose file",
      "noFile": "No image chosen"
    },
    "addImage": "Add a picture",
    "deleteImage": "Delete image",
    "nbOfTeam": "Number of team",
    "nbOfGround": "Number of ground",
    "nbOfPlayerByTeam": "Number of player by team",
    "minOfMale": "Min of male",
    "minOfFemale": "Min of female",
    "detail": "Detail",
    "default-message": "Default message",
    "email-sending-code": "Email sending code",
    "code-send-by-mail": "Code sent by email",
    "congrats":"Your tournament has been created, it is awaiting validation"
  },
  "managers": {
    "title": "Managers"
  },
  "gameToValidate": {
    "title": "Games awaiting validation"
  },
  "user-settings": {
    "title": "Account",
    "past-connexion": "Connexion",
    "connexion-card": {
      "date-of-connexion": "Date of connexion",
      "browser": "Browser",
      "device": "Device",
      "authentication-successful": "Authentication successful"
    },
    "authorization": "Authorization"
  },
  "sessions": {
    "title": "Connection"
  },
  "users":{
    "title": "Users",
    "expired": "Expired",
    "blocked": "Blocked"
  },
  "social-network": {
    "title": "Media",
    "default": "Default publication",
    "reminder": "Reminder",
    "full": "Full",
    "capacity": "Capacity limited",
    "network": "Network",
    "too-many-publication": "To make more publications contact the administrator of acrootournois.fr"
  },
  "contact": {
    "title": "Contact",
    "submit": "Submit an email",
    "emailAddress": "Email address"
  },
  "order-state": {
    "TO_PAY_ONLINE": "Waiting for payment",
    "PROCESSED": "Payment made",
    "POA_PROCESSED": "Payment on site",
    "CANCELED": "Payment canceled",
    "REFUNDED": "Payment refunded"
  },
  "team-status": {
    "payment-in-validation": "Payment in validation",
    "remains-to-be-reserved": "Remains to be reserved",
    "currently-booking": "Currently booking",
    "to-pay-on-arrival": "Reserved - To pay on arrival",
    "REGISTERED": "Reserved",
    "WAITING_LIST": "In waiting list",
    "CANCELED": "Reservation canceled"
  }
}