import React from "react"

export class PrivacyPolicy extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return <div style={ { textJustify: "auto", textAlign: "justify" } }>
            <h1>Politique de confidentialité</h1>
            <div>
                <br/>
            </div>
            <div>
                <strong>Introduction</strong>
            </div>
            <div>
                Devant le développement des nouveaux outils de communication, il est
                nécessaire de porter une attention particulière à la protection de la vie
                privée. C’est pourquoi, nous nous engageons à respecter la confidentialité
                des renseignements personnels que nous collectons.
            </div>
            <div>
                <br/>
            </div>
            <h2>Collecte des renseignements personnels</h2>
            <div>
                <ul>
                    <li>Prénom</li>
                    <li>Nom de famille</li>
                    <li>Mail</li>
                    <li>Genre</li>
                    <li>Date de naissance</li>
                </ul>
            </div>
            <div>
                Les renseignements personnels que nous collectons sont recueillis au travers
                de formulaires et grâce à l’interactivité établie entre vous et notre site
                Web. Nous utilisons également, comme indiqué dans la section suivante.
            </div>
            <div>
                <br/>
            </div>
            <h2>Formulaires&nbsp;et interactivité:</h2>
            <div>
                Vos renseignements personnels sont collectés par le biais de formulaire, à
                savoir :
            </div>
            <div>
                <br/>
            </div>
            <div>
                <ul>
                    <li>Formulaire d'inscription au site Web</li>
                </ul>
            </div>
            <div>
                Nous utilisons les renseignements ainsi collectés pour les finalités
                suivantes :
            </div>
            <div>
                <br/>
            </div>
            <div>
                <ul>
                    <li>Gestion de l'utilisateur</li>
                    <li>Création, modification, suppresion de tounrnoi</li>
                </ul>
            </div>
            <div>
                Nous utilisons les renseignements ainsi collectés pour les finalités
                suivantes :
            </div>
            <div>
                <br/>
            </div>
            <div>
                <ul>
                    <li>Contact</li>
                </ul>
            </div>
            <div>
                <br/>
            </div>
            <h2>Droit d’opposition et de retrait</h2>
            <div>
                Nous nous engageons à vous offrir un droit d’opposition et de retrait quant
                à vos renseignements personnels.
            </div>
            <div>
                Le droit d’opposition s’entend comme étant la possiblité offerte aux
                internautes de refuser que leurs renseignements personnels soient utilisées
                à certaines fins mentionnées lors de la collecte.
            </div>
            <div>
                <br/>
            </div>
            <div>
                Le droit de retrait s’entend comme étant la possiblité offerte aux
                internautes de demander à ce que leurs renseignements personnels ne figurent
                plus, par exemple, dans une liste de diffusion.
            </div>
            <div>
                <br/>
            </div>
            <div>
                <strong>Pour pouvoir exercer ces droits, vous pouvez :</strong>
            </div>
            <div>
                <br/>
            </div>
            <div>Courriel :&nbsp; root.accrootournois@gmail.com</div>
            <div>Courriel :&nbsp; accrootournois@gmail.com</div>
            <div>
                <br/>
            </div>
            <h2>Droit d’accès</h2>
            <div>
                Nous nous engageons à reconnaître un droit d’accès et de rectification aux
                personnes concernées désireuses de consulter, modifier, voire radier les
                informations les concernant.
            </div>
            <div>
                <br/>
            </div>
            <div>
                <strong>L’exercice de ce droit se fera :</strong>
            </div>
            <div>
                <br/>
            </div>
            <div/>
            <div>Courriel :&nbsp; root.accrootournois@gmail.com</div>
            <div>Courriel :&nbsp; accrootournois@gmail.com</div>
            <div/>
            <div>
                <br/>
            </div>
            <h2>Sécurité</h2>
            <div>
                Les renseignements personnels que nous collectons sont conservés dans un
                environnement sécurisé. Les personnes travaillant pour nous sont tenues de
                respecter la confidentialité de vos informations.
            </div>
            <div>
                <br/>
            </div>
            <div>
                Pour assurer la sécurité de vos renseignements personnels, nous avons
                recours aux mesures suivantes :
            </div>
            <div>
                <br/>
            </div>
            <div>
                <ul>
                    <li>Protocole SSL</li>
                    <li>Gestion des accès - personne autorisée</li>
                    <li>Gestion des accès - personne concernée</li>
                    <li>Sauvegarde informatique</li>
                    <li>Identifiant / mot de passe</li>
                </ul>
            </div>
            <div>
                Nous nous engageons à maintenir un haut degré de confidentialité en
                intégrant les dernières innovations technologiques permettant d’assurer la
                confidentialité de vos transactions. Toutefois, comme aucun mécanisme
                n’offre une sécurité maximale, une part de risque est toujours présente
                lorsque l’on utilise Internet pour transmettre des renseignements
                personnels.
            </div>
            <div>
                <br/>
            </div>
            <h2>Législation</h2>
            <div>
                Nous nous engageons à respecter les dispositions législatives énoncées dans
                :
            </div>
            <div>
                <br/>
            </div>
            <div>Législation: RGPD</div>
            <div>
                <br/>
            </div>
        </div>
    }
}