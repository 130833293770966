import React, { useContext, useEffect, useState } from "react"
import { disconnect, isNotConnected } from "../const/utils"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { Context } from "../const/context"
import { eventTransformer, gameService } from "../service/game-service"
import RegistrationsTeamGuest from "../component/registrations-team-guest"
import RegistrationTeamDefault from "../component/registration-team-default"
import Login from "./login"
import RegistrationSearchingTeam from "../component/registration-searching-team"
import { notifierService } from "../service/notifier-service"
import i18n from "i18next"

const TEAM_STATUS = {
    "WAITING_LIST": 1,
    "REGISTERING": 2,
    "REGISTERED": 3,
    "CANCELED": 4
}

export default function Registrations() {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    const [ searchParams ] = useSearchParams()

    const isNoEmailParam = searchParams.get("email") == null

    const userId = isNoEmailParam ? context.user.userId : ""
    const username = isNoEmailParam ? context.user.username : searchParams.get("email")

    const [ events, setEvents ] = useState(null)

    const [ emittedTime, setEmittedTime ] = useState(null)

    useEffect(() => {

        const fetchNotifierTime = async () => {
            const res = await notifierService.notifierTime()

            setEmittedTime(res.data)
        }

        const updateEvents = (event) => {
            const isSameGame = (it) => it.id === event.id

            const currentEvents = events

            const indexOfUpdatedGame = currentEvents.findIndex(isSameGame)

            if (indexOfUpdatedGame === -1) return setEvents([ ...currentEvents, event ])
            else currentEvents[indexOfUpdatedGame] = event

            setEvents(currentEvents)
        }

        const fetchData = async () => {
            const res = await notifierService.notifier(emittedTime)

            const isUnAuthorized = res.status === 401 || res.status === 403 || res.httpCode === 403

            if (isUnAuthorized) return disconnect(navigate, context, location.pathname)
            else if (res.status > 500) return

            res.map(async it => {
                setEmittedTime(it.emittedTime)

                const content = it.data
                const type = it.type

                if (type !== "GAME_UPDATED") return

                updateEvents(eventTransformer(content))
            })
        }

        if (emittedTime == null) fetchNotifierTime().then()

        const timer = setInterval(
            () => { if (emittedTime != null && events != null) fetchData().then() },
            5000
        )

        return () => clearInterval(timer)
    }, [ emittedTime, events ])

    useEffect(() => {

        if (isNotConnected(context)) setEvents([])

        async function fetchMyTeam() {

            const res = await gameService.getByUsername(username)

            if (res.status === 401) return disconnect(navigate, context, location.pathname + location.search)
            if (res.error != null || res.status >= 401 || res.httpCode != null) return

            setEvents(res)
        }

        fetchMyTeam().then()
    }, [ isNotConnected(context), username ])

    if (isNotConnected(context)) return <Login/>

    const searchingTeamsCard = () => events.filter(
        it => searchingTeamFilter(it)
    ).map(
        (it, index) => <React.Fragment key={ "searching-teams" + index }>{ teamSearching(it) }</React.Fragment>
    )

    const eventsCard = () => events.flatMap(
        e => e.teams.filter(it => teamFilter(it)).map(t => ({ event: e, team: t }))
    ).sort(
        (first, second) => TEAM_STATUS[first.team.status] - TEAM_STATUS[second.team.status]
    ).map(it =>
        <React.Fragment key={ "events-" + it.event.id + "-" + it.team.id }>
            { teamCard(it.event, it.team) }
        </React.Fragment>
    )

    const searchingTeamFilter = (event) => event.membersSearchingTeam.includes(userId)

    const teamFilter = (team) => {
        if (team.type === "TEAM_GUEST" && team.captain != null && team.captain.username === username) return true

        if (team.type === "TEAM_DEFAULT") {
            if (team.members.filter(it => it.username === username).length > 0) return true
            if (team.requestMembers.filter(it => it === username).length > 0 && team.status !== "CANCELED") return true
        }

        return false
    }

    const teamCard = (event, team) => {
        if (team.type === "TEAM_GUEST") return teamGuest(event, team)
        if (team.type === "TEAM_DEFAULT") return teamDefault(event, team)

        return <React.Fragment></React.Fragment>
    }

    const teamSearching = (event) => <RegistrationSearchingTeam event={ event }/>
    const teamGuest = (event, team) => <RegistrationsTeamGuest event={ event } team={ team }/>
    const teamDefault = (event, team) => <RegistrationTeamDefault event={ event } team={ team }/>

    if (events == null || events.length === 0) return <div
        className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto p-0 pb-3"
    >
        <div className="row border rounded shadow-sm px-3 py-2 mx-0">
            <div className="col-7 col-md-9 px-0 my-auto">
                <p className="fs-2 m-0">{ i18n.t("common.registrations") }</p>
            </div>
        </div>

        <div className="px-1 my-4">
            <div className="col card border-2" style={ { alignSelf: "center" } }></div>
        </div>

        <div className="card shadow-sm emergence">
            <div className="card-body text-center fs-4">
                { i18n.t("common.no-registrations-yet") }
            </div>
        </div>
    </div>

    return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto p-0 pb-3">

        <div className="row border rounded shadow-sm px-3 py-2 mx-0">
            <div className="col-7 col-md-9 px-0 my-auto">
                <p className="fs-2 m-0">{ i18n.t("common.registrations") }</p>
            </div>
        </div>

        <div className="px-1 my-4">
            <div className="col card border-2" style={ { alignSelf: "center" } }></div>
        </div>

        <div className="row row-gap-3 mx-0 px-0">
            { searchingTeamsCard() }
            { eventsCard() }
        </div>
    </div>
}