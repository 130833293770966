import { LEVEL } from "../const/common"
import moment from "moment"

class Store {

    static #DATA = "data"

    constructor() {
        const data = JSON.parse(localStorage.getItem(Store.#DATA))

        if (data != null) this.data = data
    }

    save() {
        localStorage.setItem(Store.#DATA, JSON.stringify(this.data))

        return this.data
    }

    data= {
        user: {
            identifier: "",
            password: "",
            remember: false,
            theme: "",
            language: ""
        },
        token: {
            access: {
                value: null,
                deadline: null
            },
            refresh: {
                value: null,
                deadline: null
            }
        },
        o2Auth: {
            ha: {
                codeVerifier: null
            }
        },
        search: {
            keyword: "",
            country: "CHOOSE",
            region: "CHOOSE",
            dates: {
                startDate: moment(),
                endDate: null
            },
            level: {
                min: LEVEL.OUT_OF_COMPETITION,
                max: LEVEL.LEGEND
            },
            groundSelected: {
                indoor: true,
                beach: true,
                green: true,
                water: true,
                snow: true
            }
        }
    }
}

export const store = new Store()