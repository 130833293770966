import React, { useEffect, useState } from "react"
import Input from "./input"
import i18n from "i18next"
import { emptyableMailValidator, mailValidator } from "../const/utils"
import { authService } from "../service/authorization-service"

export default function MailModal(props) {

    const [ mail, setMail ] = useState(props.mail)

    useEffect(() => { setMail(props.mail) }, [ props.mail ])

    const sendAuth = () => authService.create(
        props.scopes,
        "EMAIL",
        mail
    ).then(res => {
        props.setAuthId(res.id)
        props.nextAction()
    })

    return <div
        id="mail-modal"
        className="modal fade"
        data-bs-keyboard="false"
        tabIndex="-1"
    >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{ i18n.t("common.send-authorization-key") }</h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={ props.close }
                    ></button>
                </div>
                <div className="modal-body text-center">
                    <div className="row row-gap-3 mx-auto">
                        <Input
                            id="mail"
                            type="email"
                            iconName="mail"
                            placeholder={ i18n.t("gameEdition.mail") }
                            validator={ emptyableMailValidator }
                            onHandleInput={ (id, value) => setMail(value) }
                            value={ mail }
                        />
                    </div>
                </div>
                <div className="modal-footer p-0"></div>
                <button
                    className="btn btn-primary rounded-top-0"
                    disabled={ mail === "" || !mailValidator(mail) }
                    onClick={ () => sendAuth() }
                    data-bs-dismiss="modal"
                    data-bs-target={ props.dataBsTarget }
                    data-bs-toggle={ props.dataBsToggle }
                >{ i18n.t("button.send") }</button>
            </div>
        </div>
    </div>
}