import "../css/action-button.css"
import React from "react"

export const PActionButton = React.memo((props) => <ActionButton {...props} />)

function ActionButton(props) {

    return <div className="overflow-hidden rounded-1 h-100">
        <button
            className={ "uiverse w-100 h-100 " + props.clazzname }
            onClick={ props.onClick }
            disabled={ props.disabled }
            style={ props.style }
            data-bs-toggle={ props.dataBsToggle }
            data-bs-target={ props.dataBsTarget }
            data-bs-dismiss={ props.dataBsDismiss }
            aria-label={ props.ariaLabel }
            hidden={ props.hidden }
        >
            <div className="wrapper">
                <span className="fw-semibold">{ props.children }</span>
                {/*<div className="circle circle-12"></div>*/ }
                {/*<div className="circle circle-11"></div>*/ }
                {/*<div className="circle circle-10"></div>*/ }
                {/*<div className="circle circle-9"></div>*/ }
                {/*<div className="circle circle-8"></div>*/ }
                {/*<div className="circle circle-7"></div>*/ }
                <div className="circle circle-6"></div>
                <div className="circle circle-5"></div>
                <div className="circle circle-4"></div>
                {/*<div className="circle circle-3"></div>*/ }
                <div className="circle circle-2"></div>
                {/*<div className="circle circle-1"></div>*/ }
            </div>
        </button>
    </div>
}