import playerAttack from "../../public/player_attack.png"
import i18n from "i18next"

import React, { useEffect, useState } from "react"
import { capitalizeFirstLetter, gender, regionFinder, regionTranslation, timeParser } from "../const/common"
import moment from "moment"
import { PActionButton } from "./action-button"

function buttonBy(ground) {
    switch (ground) {
        case "INDOOR": return "btn-bd-custom"
        case "GREEN": return "btn-success"
        case "BEACH": return "btn-warning"
        case "WATER": return "btn-primary"
        case "SNOW": return "btn-info"
    }
}

export default function GameCard(props) {

    const [ game, setGame ] = useState(props.game)

    const isFull = game.nbOfTeam <= game.nbOfRegisterTeam

    const canManageRegistration = game.canManageRegistration
    const isWaitingListAllowed = game.canUseWaitingList

    const [ isVisibleRegisterButton, setIsVisibleRegisterButton ] = useState(canManageRegistration && !isFull)
    const [ isVisibleWaitingListButton, setIsVisibleWaitingListButton ] = useState(canManageRegistration && isFull && isWaitingListAllowed)
    const [ isVisibleDetailButton, setIsVisibleDetailButton ] = useState(!isVisibleRegisterButton && !isVisibleWaitingListButton)

    const [ grayscale, setGrayscale ] = useState(isFull ? "grayscale(100%)" : "grayscale(0%)")

    useEffect(() => updateEventVisibility(game), [ game ])

    useEffect(() => {
        updateEventVisibility(props.game)
        setGame(props.game)
    }, [ props.game ])

    const updateEventVisibility = (game) => {
        const isFull = game.nbOfTeam <= game.nbOfRegisterTeam

        const canManageRegistration = game.canManageRegistration
        const isWaitingListAllowed = game.canUseWaitingList

        setIsVisibleRegisterButton(canManageRegistration && !isFull)
        setIsVisibleWaitingListButton(canManageRegistration && isFull && isWaitingListAllowed)
        setIsVisibleDetailButton(!isVisibleRegisterButton && !isVisibleWaitingListButton)

        setGrayscale(isFull ? "grayscale(100%)" : "grayscale(0%)")
    }

    const url = props.url != null && props.url !== "" ? props.url : playerAttack
    const onHandleModalUrl = props.onHandleModalUrl
    const onHandleOffCanvasGame = props.onHandleOffCanvasGame

    return <div className="card shadow-sm emergence">

        <button
            type="button"
            className="btn p-0 border-0 col-12"
            data-bs-toggle="modal"
            data-bs-target="#gameModal"
            data-bs-whatever="@mdo"
            onClick={ () => onHandleModalUrl(url) }
        >
            <img
                src={ url }
                className="card-img-top rounded-bottom-0"
                alt="tounament_image"
                style={ {
                    objectFit: "cover",
                    objectPosition: "top",
                    maxHeight: window.mobileCheck ? "300px" : "500px",
                    borderRadius: "0.375rem",
                    padding: 0,
                    WebkitFilter: grayscale,
                    filter: grayscale
                } }
            />
        </button>

        <div style={ { position: "absolute", top: "16px", left: "16px" } }>
            <button
                className={ "btn btn-sm fw-semibold " + buttonBy(game.ground) }
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
                onClick={ () => onHandleOffCanvasGame(game) }
                style={ { height: "38px" } }
            >
                { capitalizeFirstLetter(game.ground.toLowerCase()) }
            </button>
        </div>

        <div className="card-body pb-3">
            <h5 className="card-title"
                style={ { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" } }
            >
                { capitalizeFirstLetter(game.name.toLowerCase()) }
            </h5>
            <small className="text-muted cat d-flex">
                <div className="me-2" hidden={ !game.canManageRegistration }>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16" height="16"
                        fill="currentColor"
                        className="bi bi-ticket-perforated-fill"
                        viewBox="0 0 16 16"
                        style={ { verticalAlign: "text-bottom" } }
                    >
                        <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6zm4-1v1h1v-1zm1 3v-1H4v1zm7 0v-1h-1v1zm-1-2h1v-1h-1zm-6 3H4v1h1zm7 1v-1h-1v1zm-7 1H4v1h1zm7 1v-1h-1v1zm-8 1v1h1v-1zm7 1h1v-1h-1z"/>
                    </svg> { game.nbOfTeam - game.nbOfRegisterTeam }
                </div>
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16" height="16"
                        fill="currentColor"
                        className="bi bi-people-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
                    </svg> { gender(game) }
                </div>
            </small>
            <p className="card-text small my-2">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16"
                    fill="currentColor"
                    className="bi bi-geo-fill"
                    viewBox="0 0 16 16"
                >
                    <path
                        fillRule="evenodd"
                        d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411"
                    />
                </svg> { regionTranslation(regionFinder(game.region)) }
            </p>
            <div className="views">
                { capitalizeFirstLetter(moment(game.startDate).locale(i18n.language).format("ddd D MMM")) } { i18n.t("common.at") } { timeParser(game.startTime) }
            </div>
        </div>
        <div className="card-footer d-flex justify-content-between bg-transparent border-top-0 pt-0 pb-3">
            <button
                className="btn btn-sm btn-primary fw-semibold"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
                onClick={ () => onHandleOffCanvasGame(game) }
                hidden={ !isVisibleDetailButton }
                style={ { height: "38px", minWidth: "fit-content" } }
            >
                { i18n.t("common.open") }
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right-square-fill ms-2"
                    viewBox="0 0 16 16"
                >
                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                </svg>
            </button>
            <button
                className="btn btn-sm btn-primary fw-semibold"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
                onClick={ () => onHandleOffCanvasGame(game) }
                hidden={ !isVisibleWaitingListButton }
                style={ { height: "38px", minWidth: "fit-content" } }
            >
                { i18n.t("common.waiting-list") }
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right-square-fill ms-2"
                    viewBox="0 0 16 16"
                >
                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                </svg>
            </button>
            <PActionButton
                clazzname="fw-semibold"
                dataBsToggle="offcanvas"
                dataBsTarget="#offcanvas"
                onClick={ () => onHandleOffCanvasGame(game) }
                hidden={ !isVisibleRegisterButton }
                style={ { height: "38px" } }
            >
                { i18n.t("common.register") }
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right-square-fill ms-2"
                    viewBox="0 0 16 16"
                >
                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                </svg>
            </PActionButton>
        </div>
    </div>
}