import i18n from "i18next"
import { userCard } from "../const/common"
import React, { useContext, useEffect, useState } from "react"
import { userService } from "../service/user-service"
import { Context } from "../const/context"
import GameOffcanvas from "./game-offcanvas"
import { gameService } from "../service/game-service"
import { disconnect } from "../const/utils"

export default function RegistrationSearchingTeam(props) {

    const context = useContext(Context)

    const [ user, setUser ] = useState()
    const [ event, setEvent ] = useState(props.event)

    useEffect(() => {

        async function fetchUsers() {
            const res = await userService.search(
                [],
                [],
                "",
                null,
                "USER_DEFAULT",
                null,
                [ context.user.username ]
            )

            setUser(res.find(it => it.id === context.user.userId))
        }

        fetchUsers().then()
    }, [ ])

    if (user == null || event == null) return <React.Fragment />

    const deleteMemberSearchingForTeam = async () => {
        const res = await gameService.memberSearchingTeam(event.id, "DELETE")

        if (res.status === 401) return disconnect(navigate, context, location.pathname)
        else if (res.error != null || res.httpCode != null) return

        setEvent(null)
    }

    return <div className="card shadow-sm emergence">
        <div className="my-3">
            <div className="row row-gap-2 mx-0 px-0">
                <div className="d-flex px-0">
                    <button className="btn">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16" fill="currentColor"
                            className="bi bi-arrow-90deg-right" viewBox="0 0 16 16"
                        >
                            <path fillRule="evenodd"
                                  d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708z"/>
                        </svg>
                    </button>
                    <div>
                        <div className="px-0">
                            <button
                                className="btn btn-sm bg-info border-info btn-info"
                                type="button"
                            >
                                { i18n.t("common.looking-for-a-team") }
                            </button>
                        </div>
                    </div>
                </div>
                <div className="d-flex px-0">
                    <div className="px-0">
                        <button
                            className="btn border-0"
                            style={ { verticalAlign: "sub" } }
                            disabled
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16" fill="currentColor"
                                className="bi bi-arrow-90deg-right" viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708z"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="px-0">
                        <button
                            className="btn btn-sm btn-primary"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target={ "#game-offcanvas-" + event.id }
                            aria-controls="game-offcanvas"
                        >
                            { i18n.t("common.event") }
                        </button>
                    </div>
                    <div className="px-0">
                        <button
                            className="btn btn-sm border-0"
                            style={ { verticalAlign: "sub" } }
                            disabled
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16"
                                fill="currentColor"
                                className="bi bi-arrow-right"
                                viewBox="0 -1 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="px-0">
                        <button
                            className="btn btn-sm border"
                            style={ {
                                maxWidth: "140px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap"
                            } }
                        >
                            { event.name }
                        </button>
                    </div>
                </div>
                <div className="card px-0">
                    <div className="card-body p-2">
                        <div className="row row-gap-2 rounded">
                            <div className="col">
                                { userCard(user, null, "") }
                            </div>
                            <div className="col-md-2 col-12 ps-md-0">
                                <button
                                    className="btn btn-sm btn-danger p-1 w-100 h-100"
                                    onClick={ deleteMemberSearchingForTeam }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16" height="16"
                                        fill="currentColor"
                                        className="bi bi-trash" viewBox="0 0 16 16"
                                    >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <GameOffcanvas id={ "game-offcanvas-" + event.id } game={ event }/>
    </div>
}