import { useLocation, useNavigate } from "react-router-dom"
import React, { useContext, useEffect, useState } from "react"
import i18n from "i18next"
import RegistrationTeamDefault from "../component/registration-team-default"
import RegistrationsTeamGuest from "../component/registrations-team-guest"
import { disconnect, isNotConnected } from "../const/utils"
import Login from "./login"
import { Context } from "../const/context"
import { eventTransformer, gameService } from "../service/game-service"
import { notifierService } from "../service/notifier-service"

const TEAM_STATUS = {
    "REGISTERED": 1,
    "REGISTERING": 2,
    "WAITING_LIST": 3,
    "CANCELED": 4
}

export default function GameTeams() {
    const context = useContext(Context)

    const location = useLocation()
    const navigate = useNavigate()

    const state = location.state

    const [ event, setEvent ] = useState(null)
    const [ teams, setTeams ] = useState([])

    const [ emittedTime, setEmittedTime ] = useState(null)

    const updateEvent = async (notifierEvent) => {
        if (notifierEvent == null || notifierEvent.id !== event.id) return

        setEvent(notifierEvent)
        setTeams(notifierEvent.teams)
    }

    useEffect(() => {

        const fetchNotifierTime = async () => {
            const res = await notifierService.notifierTime()

            setEmittedTime(res.data)
        }

        const fetchData = async () => {
            const res = await notifierService.notifier(emittedTime)

            const isUnauthorized = res.status === 401 || res.status === 403 || res.httpCode === 403

            if (isUnauthorized) return disconnect(navigate, context, location.pathname)
            else if (res.status > 500) return

            res.map(it => {
                setEmittedTime(it.emittedTime)

                const content = it.data
                const type = it.type

                if (type !== "GAME_UPDATED") return

                updateEvent(eventTransformer(content))
            })
        }

        if (emittedTime == null) fetchNotifierTime().then()

        const timer = setInterval( () => { if (emittedTime != null) fetchData().then() }, 5000)

        return () => clearInterval(timer)
    }, [ emittedTime ])

    useEffect(() => {
        if (state == null || state.eventId == null) return navigate(-1)

        const eventId = state.eventId

        const notConnected = isNotConnected(context)

        async function fetchGame() {
            const res = await gameService.getAll(eventId)

            if (res == null) return navigate("/games")
            if (res.status === 401) return disconnect(navigate, context, location.pathname)
            if (res.length === 0) return navigate("/games")

            const event = res[0]

            setEvent(event)
            setTeams(event.teams)
        }

        if (event == null && !notConnected) fetchGame().then()
    }, [ context.user.isConnected ])

    if (isNotConnected(context)) return <Login/>

    function teamRender(it) {
        if (it.type === "TEAM_DEFAULT") {
            const username = it.captain
            const userId = it.members.find(m => m.username === username).userId

            return <RegistrationTeamDefault event={ event } team={ it } userId={ userId } username={ username }/>
        }
        if (it.type === "TEAM_GUEST") return <RegistrationsTeamGuest event={ event } team={ it }/>
    }

    const teamsRender = () => teams.sort(
        (t1, t2) => TEAM_STATUS[t1.status] - TEAM_STATUS[t2.status]
    ).map(
        it => <React.Fragment key={ it.id }>{ teamRender(it) }</React.Fragment>
    )

    if (event == null || teams.length === 0) return <React.Fragment />

    return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto pb-3">

        <div className="row border rounded shadow-sm px-3 py-2 mx-0 mb-3">
            <div className="col-7 col-md-9 col-lg-10 px-0 my-auto">
                <p className="fs-2 m-0">{ i18n.t("common.teams") }</p>
            </div>
            <div className="col-5 col-md-3 col-lg-2 text-end m-auto px-0">
                <div className="row row-gap-2">
                    <div className="col-12">
                        <div className="text-end rounded">
                            <button
                                className="btn btn-warning shadow-sm"
                                onClick={ () => navigate(-1) }
                            >
                                <div className="row">
                                    <div className="col-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="17" height="17"
                                            fill="currentColor"
                                            className="bi bi-arrow-left-circle"
                                            viewBox="-2 0 20 15"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="col px-1">{ i18n.t("button.back") }</div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="px-1 my-4">
            <div className="col card border-2" style={ { alignSelf: "center" } }></div>
        </div>

        <div className="row row-gap-3 mx-0 px-0">
            { teamsRender() }
        </div>
    </div>
}