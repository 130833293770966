import i18n from 'i18next'
import React from "react"
import { LEVEL, levelFromEnum, levelName, levelPosition } from "../const/common"

export class Level extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            level: {
                min: {
                    name: props.min.name,
                    position: props.min.position
                },
                max: {
                    name: props.max.name,
                    position: props.max.position
                }
            },
        }

        this.padding = props.padding !== undefined ? props.padding : "p-4"

        this.onHandleMinRangeLevel = this.onHandleMinRangeLevel.bind(this)
        this.onHandleMaxRangeLevel = this.onHandleMaxRangeLevel.bind(this)
    }

    onHandleMinRangeLevel(e) {
        const minName = levelName(e.target.value)
        const minPosition = levelPosition(minName)

        const currentMaxPosition = this.state.level.max.position

        const maxPosition = minPosition > currentMaxPosition ? minPosition : currentMaxPosition
        const maxName = levelName(maxPosition)

        this.setState((state, _) => ( {
            level: {
                min: { name: minName, position: minPosition },
                max: { name: maxName, position: maxPosition }
            }
        } ),
            () => this.props.onHandleLevel(LEVEL[this.state.level.min.name], LEVEL[this.state.level.max.name])
        )
    }

    onHandleMaxRangeLevel(e) {
        const maxName = levelName(e.target.value)
        const maxPosition = levelPosition(maxName)

        const currentMinPosition = this.state.level.min.position

        const minPosition = maxPosition < currentMinPosition ? maxPosition : currentMinPosition
        const minName = levelName(minPosition)

        this.setState((state, _) => ( {
            level: {
                min: { name: minName, position: minPosition },
                max: { name: maxName, position: maxPosition }
            }
        } ),
            () => this.props.onHandleLevel(LEVEL[this.state.level.min.name], LEVEL[this.state.level.max.name])
        )
    }

    render() {
        return <div className={ "border rounded shadow-sm " + this.padding }>
            <div className="text-center">
                <p>{ i18n.t("level.name") }</p>
            </div>
            <div className={ "row " + this.padding } style={ { maxHeight: "50px" } }>
                <div className="col-6">
                    <p className="text-center">
                        { i18n.t("level." + levelFromEnum(this.state.level.min.name)) }
                    </p>
                </div>
                <div className="col-6">
                    <div>
                        <p className="text-center">
                            { i18n.t("level." + levelFromEnum(this.state.level.max.name)) }
                        </p>
                    </div>
                </div>
            </div>
            <div className={ "row " + this.padding }>
                <div className="col-6">
                    <input
                        type="range"
                        className="form-range"
                        min="0"
                        max="6"
                        step="1"
                        id="minRangeLevel"
                        value={ this.state.level.min.position }
                        onChange={ this.onHandleMinRangeLevel }
                        disabled={ this.props.disabled }
                    />
                </div>
                <div className="col-6">
                    <input
                        type="range"
                        className="form-range"
                        min="0"
                        max="6"
                        step="1"
                        id="maxRangeLevel"
                        value={ this.state.level.max.position }
                        onChange={ this.onHandleMaxRangeLevel }
                        disabled={ this.props.disabled }
                    />
                </div>
            </div>
        </div>
    }
}