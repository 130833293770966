import i18n from "i18next"
import { captainCard, levelFromEnum } from "../const/common"
import React, { useContext, useEffect, useState } from "react"
import { userService } from "../service/user-service"
import GameOffcanvas from "./game-offcanvas"
import PaymentsOffcanvas from "./payments-offcanvas"
import { teamOrderService } from "../service/team-order-service"
import MembersSettingsOffcanvas from "./members-settings-offcanvas"
import { Context } from "../const/context"
import { isPOA, newTab } from "../const/utils"
import { teamService } from "../service/team-service"

export default function RegistrationsTeamGuest(props) {

    const context = useContext(Context)

    const [ event, setEvent ] = useState(props.event)
    const [ team, setTeam ] = useState(props.team)

    const [ captain, setCaptain ] = useState()
    const [ users, setUsers ] = useState([])
    const [ orders, setOrders ] = useState([])

    useEffect(() => setEvent(props.event), [ props.event ])
    useEffect(() => setTeam(props.team), [ props.team ])

    useEffect(() => {

        async function fetchOrders() {
            const res = await teamOrderService.getTeamOrders(event.id, team.id)

            setOrders(res)
        }

        fetchOrders().then()
    }, [ team ]);

    useEffect(() => {

        async function fetchCaptain() {
            const res = await userService.search(
                [],
                [],
                null,
                null,
                null,
                null,
                [ team.captain.username ]
            )

            setUsers(res)
            setCaptain(res[0])
        }

        fetchCaptain().then()
    }, [])

    const isCurrentUserAdminOrManager = () => context.user.isAdmin || event.managers.includes(context.user.userId)

    const onHandleCancelTeam = async (eventId, teamId) => {
        const res = await teamService.cancel(eventId, teamId)

        if (res.error == null && res.httpCode == null) setTeam(res)
    }

    const statusButton = () => {
        if (orders.length === 0) return teamStatus()

        const order = orders[0]

        const isPOARegistration = !event.isPaymentAllow && isPOA(orders)

        if (order.payment.state === "TO_PAY_ONLINE") return <button
            onClick={ () => newTab(order.payment.paymentUrl) }
            className="btn btn-sm btn-warning h-100"
            style={ { placeContent: "center" } }
        >
                { i18n.t("order-state.TO_PAY_ONLINE") } <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16" height="16"
                fill="currentColor"
                className="bi bi-arrow-right-circle"
                viewBox="0 -2 16 18"
            >
                <path
                    fillRule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                />
            </svg>
        </button>
        if (team.status === "REGISTERED" && isPOARegistration) return <button
            className="btn btn-sm btn-success bg-success border-success h-100"
        >
            { i18n.t("team-status.to-pay-on-arrival") }
        </button>
        if (team.status === "REGISTERED") return <button
            className="btn btn-sm btn-success bg-success border-success h-100"
        >
            { i18n.t("team-status.REGISTERED") }
        </button>
        if (order.payment.state === "CANCELED") return <button
            className="btn btn-sm btn-danger border-danger bg-danger h-100"
            style={ { placeContent: "center" } }
        >
            { i18n.t("order-state.CANCELED") }
        </button>
        if (order.payment.state === "REFUNDED") return <button
            className="btn btn-sm btn-warning border-warning bg-warning h-100"
            style={ { placeContent: "center" } }
        >
            { i18n.t("order-state.REFUNDED") }
        </button>
    }

    const teamStatus = () => {

        const status = team.status
        const isPOARegistration = !event.isPaymentAllow && isPOA(orders)

        if (team.status === "REGISTERED" && isPOARegistration) return <button
            className="btn btn-sm btn-success bg-success border-success h-100"
        >
            { i18n.t("team-status.to-pay-on-arrival") }
        </button>
        if (team.status === "REGISTERED") return <button
            className="btn btn-sm btn-success bg-success border-success h-100"
        >
            { i18n.t("team-status.REGISTERED") }
        </button>
        if (status === "WAITING_LIST") return <button
            className="btn btn-sm btn-info bg-info border-success h-100"
        >
            { i18n.t("team-status.WAITING_LIST") }
        </button>
        if (status === "CANCELED") return <button
            className="btn btn-sm btn-danger bg-danger border-danger h-100"
        >
            { i18n.t("team-status.CANCELED") }
        </button>
    }

    if (event == null || team == null) return <React.Fragment></React.Fragment>

    const currentUsername = team.captain.username

    return <div className="card shadow-sm emergence">
        <div className="card-body px-0">
            <div className="row row-gap-3">
                <div className="d-flex justify-content-between mx-0" style={ { alignItems: "center" } }>
                    <div className="d-flex h-100 mx-0">
                        <button className="btn fw-bold border">{ team.name }</button>
                        <button
                            className="btn btn-sm border-0"
                            style={ { verticalAlign: "sub" } }
                            disabled
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16"
                                fill="currentColor"
                                className="bi bi-arrow-right"
                                viewBox="0 0 16 16">
                                <path
                                    fillRule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                />
                            </svg>
                        </button>
                        { statusButton() }
                    </div>
                    <div className="d-flex h-100 mx-0 ps-2 ps-md-0">
                        <button
                            className="btn btn-danger"
                            hidden={ !isCurrentUserAdminOrManager() || team.status === "CANCELED" }
                            onClick={ () => onHandleCancelTeam(event.id, team.id) }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16"
                                fill="currentColor"
                                className="bi bi-x-lg" viewBox="0 0 16 16"
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="row row-gap-3 mx-0">
                    <div className="d-flex px-0" style={ { alignItems: "center" } }>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-return-right"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0 h-100">
                            <button
                                className="btn btn-sm h-100 btn-primary"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target={ "#game-offcanvas-" + team.id }
                                aria-controls="game-offcanvas"
                            >
                                { i18n.t("common.event") }
                            </button>
                        </div>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-right"
                                    viewBox="0 -1 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border"
                                style={ {
                                    maxWidth: "140px",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                } }
                            >
                                { event.name }
                            </button>
                        </div>
                    </div>
                    <div className="d-flex px-0" style={ { alignItems: "center" } }>
                        <div className="px-0">
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-return-right"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0">
                            <button className="btn btn-sm btn-primary" disabled>
                                { i18n.t("common.team") }
                            </button>
                        </div>
                        <div className="px-0" hidden={ team.level == null }>
                            <button
                                className="btn btn-sm border-0"
                                style={ { verticalAlign: "sub" } }
                                disabled
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-right"
                                    viewBox="0 0 16 16">
                                    <path
                                        fillRule="evenodd"
                                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-0" hidden={ team.level == null }>
                            <button
                                className="btn btn-sm border"
                            >{ i18n.t("level." + levelFromEnum(team.level)) }</button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="card">
                        <div className="card-body p-2">
                            <div className="row row-gap-2 rounded">
                                <div className="col">
                                    { captainCard(captain, "", team.captain.phoneNumber) }
                                </div>
                                <div
                                    className="col-md-2 col-12 ps-md-0"
                                    hidden={ orders.length === 0 }
                                >
                                    <div className={"w-100 " + (currentUsername !== context.user.username ? "h-100" : "h-50") }>
                                        <button
                                            className="btn btn-sm btn-primary p-1 w-100 h-100"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target={ "#payments-offcanvas-" + team.id }
                                            aria-controls="payments-offcanvas"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16" height="16"
                                                fill="currentColor" className="bi bi-credit-card" viewBox="0 -1 16 16"
                                            >
                                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
                                                <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
                                            </svg>{ "\t" }{ i18n.t("common.payments") }
                                        </button>
                                    </div>
                                    <div className="w-100 h-50 py-1" hidden={ currentUsername !== context.user.username }>
                                        <div className="card my-1 h-100">
                                            <div className="card-body h-100 p-0" style={ { alignContent: "center" } }>
                                                <button
                                                    className="btn btn-sm h-100 w-100 px-0"
                                                    data-bs-toggle="offcanvas"
                                                    data-bs-target={ "#members-settings-offcanvas-" + team.id + "-" + currentUsername }
                                                    aria-controls="members-settings-offcanvas"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16" height="16"
                                                        fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16"
                                                        style={ { paddingTop: "2px" } }
                                                    >
                                                        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                                                        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                                                    </svg>{ "\t" }{ i18n.t("common.settings") }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <GameOffcanvas id={ "game-offcanvas-" + team.id } game={ event } users={ users }/>
        <PaymentsOffcanvas id={ "payments-offcanvas-" + team.id } orders={ orders } users={ users }/>
        <MembersSettingsOffcanvas
            id={ "members-settings-offcanvas-" + team.id + "-" + currentUsername }
            eventId={ event.id }
            teamId={ team.id }
            member={ team.captain }
        />
    </div>
}