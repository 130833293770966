import i18n from 'i18next'
import React from "react"

export class Error extends React.Component {
    render() {
        return <div className="d-flex justify-content-center pt-3 pb-3">
            <div id="message" className="text-center">
                <h1>Oops!</h1>
                <p>{ i18n.t("error.message") }</p>
            </div>
        </div>
    }
}