import { useNavigate, useSearchParams } from "react-router-dom"
import React, { useContext, useEffect, useState } from "react"
import { Context } from "../const/context"
import { disconnect, isNotConnected } from "../const/utils"
import { orderService, orderTransformer } from "../service/order-service"
import i18n from "i18next"
import { orderCard } from "../component/order"
import { notifierService } from "../service/notifier-service"
import Login from "./login"

export default function Payments() {
    const navigate = useNavigate()
    const context = useContext(Context)

    const [ searchParams ] = useSearchParams()

    const isNoEmailParam = searchParams.get("email") == null

    const username = isNoEmailParam ? context.user.username : searchParams.get("email")

    const [ orders, setOrders ] = useState(null)

    const [ emittedTime, setEmittedTime ] = useState(null)

    const updateOrders = async (order) => {
        const isSameOrder = (it) => it.id === order.id

        const indexOfUpdatedOrder = orders.findIndex(isSameOrder)

        if (indexOfUpdatedOrder === -1) orders.push(order)
        else orders[indexOfUpdatedOrder] = order

        setOrders([ ...orders ])
    }

    useEffect(() => {

        const fetchNotifierTime = async () => {
            const res = await notifierService.notifierTime()

            setEmittedTime(res.data)
        }

        const fetchData = async () => {
            const res = await notifierService.notifier(emittedTime)

            const isUnauthorized = res.status === 401 || res.status === 403 || res.httpCode === 403

            if (isUnauthorized) return disconnect(navigate, context, location.pathname)
            else if (res.status > 500) return

            res.map(it => {
                setEmittedTime(it.emittedTime)

                const content = it.data
                const type = it.type

                if (type !== "ORDER_UPDATE") return

                updateOrders(orderTransformer(content))
            })
        }

        if (emittedTime == null) fetchNotifierTime().then()

        const timer = setInterval(
            () => { if (emittedTime != null && orders != null) fetchData().then() },
            5000
        )

        return () => clearInterval(timer)
    }, [ emittedTime, orders ])

    useEffect(() => {
        async function fetchOrders() {
            const res = await orderService.search([], username)

            if (res.status === 401) return disconnect(navigate, context, -1)
            if (res.error != null || res.status >= 402 || res.httpCode != null) return

            setOrders(res)
        }

        fetchOrders().then()
    }, [])

    const ordersCard = () => <div className="row row-gap-3 px-0 mx-0">
        {
            orders.map(
                it => <React.Fragment key={ it.id }>{ orderCard(it) }</React.Fragment>
            )
        }
    </div>

    if (isNotConnected(context)) return <Login/>

    if (orders == null || orders.length === 0) return <div
        className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto p-0 pb-3"
    >
        <div className="row border rounded shadow-sm px-3 py-2 mx-0 mb-3">
            <div className="col-7 col-md-9 px-0 my-auto">
                <p className="fs-2 m-0">{ i18n.t("common.payments") }</p>
            </div>
        </div>

        <div className="px-1 my-4">
            <div className="col card border-2" style={ { alignSelf: "center" } }></div>
        </div>

        <div className="card shadow-sm emergence">
            <div className="card-body text-center fs-4">
                { i18n.t("common.no-payment-yet") }
            </div>
        </div>
    </div>

    return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto p-0 pb-3">

        <div className="row border rounded shadow-sm px-3 py-2 mx-0 mb-3">
            <div className="col-7 col-md-9 px-0 my-auto">
                <p className="fs-2 m-0">{ i18n.t("common.payments") }</p>
            </div>
        </div>

        <div className="px-1 my-4">
            <div className="col card border-2" style={ { alignSelf: "center" } }></div>
        </div>

        { ordersCard() }
    </div>
}