import React from "react"
import { statusFromGame } from "../const/common"
import i18n from "i18next"
import { reducedDate } from "../const/utils"
import { Context } from "../const/context"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import ShowcasePicture from "./showcase-picture"

export default function(props) {
    const navigate = useNavigate()
    const context = useContext(Context)

    return <GameEditionCard { ...props } navigate={ navigate } context={ context } />
}

class GameEditionCard extends React.Component {

    constructor(props) {
        super(props)

        this.navigate = props.navigate

        this.onHandleModalUrl = props.onHandleModalUrl
    }

    onHandleGame = (game) => this.navigate("/game/" + game.id, { state: { game: game } })

    render() {
        const it = this.props.game
        const record = this.props.record

        return <div className="card h-100 shadow-sm">
            <ShowcasePicture url={ record } onHandleModalUrl={ this.onHandleModalUrl } roundedBottom={ false }/>
            <div style={ { position: "absolute", top: "16px", left: "16px" } }>
                { statusFromGame(it.status) }
            </div>
            <div className="card-body d-flex align-items-end flex-column">

                <div className="row mx-0 px-0 mb-3 w-100 small bold">
                    <div className="col-7 ps-0">
                        { it.name }
                    </div>
                    <div className="col-5 text-end pe-0">
                        { reducedDate(it.startDate) }
                    </div>
                </div>

                <div className="row row-gap-3 mx-0 px-0 mt-auto w-100">
                    <div className="col-12 px-0">
                        <button
                            className="btn btn-sm btn-secondary col-12"
                            onClick={ () => this.onHandleGame(it) }
                        >{ i18n.t("common.view") }</button>
                    </div>
                </div>
            </div>
        </div>
    }
}