import "dotenv/config"

import { APPLICATION_JSON, DELETE, GET, POST, PUT } from "../const/request"
import { store } from "../repository/store"
import { refreshToken, resParser } from "../const/utils"

class GameService {

    async createAsGuest(
        name,
        ground,

        nbOfTeam,
        nbOfGround,
        nbOfMember,

        minMaleInTeam,
        minFemaleInTeam,

        levelFrom,
        levelTo,

        startDate,
        endDate,
        startTime,
        endTime,

        price,

        address,
        region,

        phoneNumber,
        mail,
        whatsAppGroupLink,

        detail,

        authorizationId,
        authorizationCode
    ) {
        const body = JSON.stringify({
            name: name,
            ground: ground,

            nbOfTeam: nbOfTeam,
            nbOfGround: nbOfGround,

            nbOfMember: nbOfMember,

            minMaleInTeam: minMaleInTeam,
            minFemaleInTeam: minFemaleInTeam,

            levelFrom: levelFrom,
            levelTo: levelTo,

            startDate: startDate,
            endDate: endDate,
            startTime: startTime,
            endTime: endTime,

            playerPrice: !price ? null : price * 100,

            address: address,
            region: region,

            phoneNumber: phoneNumber,
            mail: mail,
            whatsAppGroupLink: whatsAppGroupLink,

            detail: detail
        })

        const path = process.env.BASE_URL + "/api/v1/games/as-guest"

        const headers = {
            "Content-Type": APPLICATION_JSON,
            "Authorization-Id": authorizationId,
            "Authorization-Code": authorizationCode
        }

        const response = await fetch(path, {
            method: POST,
            headers: headers,
            body: body,
        })

        return eventTransformer(await resParser(response))
    }

    async updateRegistry(
        id,
        visibility,
        dayOfEventPlayerPrice,
        canManageRegistration,
        canUseWaitingList,
        shouldValidateLevel,
        shouldAskPlayerLicensed,
        nbOfExtraMember,
        deadlineOfIndividualPayment,
        phoneNumber,
        mail,
        whatsAppGroupLink,
        detail
    ) {
        return await this.createOrUpdate(
            id,
            null,
            visibility,
            null,

            null,
            null,
            null,

            null,
            null,

            null,
            null,

            null,
            null,
            null,
            null,

            null,
            dayOfEventPlayerPrice,

            canManageRegistration,
            canUseWaitingList,
            shouldValidateLevel,
            shouldAskPlayerLicensed,

            nbOfExtraMember,
            deadlineOfIndividualPayment,

            null,
            null,

            phoneNumber,
            mail,
            whatsAppGroupLink,

            detail,
        )
    }

    async createOrUpdate(
        id,

        name,
        visibility,
        ground,

        nbOfTeam,
        nbOfGround,
        nbOfMember,

        minMaleInTeam,
        minFemaleInTeam,

        levelFrom,
        levelTo,

        startDate,
        endDate,
        startTime,
        endTime,

        price,
        dayOfEventPlayerPrice,

        canManageRegistration,
        canUseWaitingList,
        shouldValidateLevel,
        shouldAskPlayerLicensed,

        nbOfExtraMember,
        deadlineOfIndividualPayment,

        address,
        region,

        phoneNumber,
        mail,
        whatsAppGroupLink,

        detail
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const body = JSON.stringify({
            name: name,
            visibility: visibility,
            ground: ground,

            nbOfTeam: nbOfTeam,
            nbOfGround: nbOfGround,

            nbOfMember: nbOfMember,

            minMaleInTeam: minMaleInTeam,
            minFemaleInTeam: minFemaleInTeam,

            levelFrom: levelFrom,
            levelTo: levelTo,

            startDate: startDate,
            endDate: endDate,
            startTime: startTime,
            endTime: endTime,

            playerPrice: !price ? null : price * 100,
            dayOfEventPlayerPrice: !dayOfEventPlayerPrice ? null : dayOfEventPlayerPrice * 100,

            nbOfExtraMember: nbOfExtraMember,
            deadlineOfIndividualPayment: deadlineOfIndividualPayment,

            canManageRegistration: canManageRegistration,
            canUseWaitingList: canUseWaitingList,
            shouldValidateLevel: shouldValidateLevel,
            shouldAskPlayerLicensed: shouldAskPlayerLicensed,

            address: address,
            region: region,

            phoneNumber: phoneNumber,
            mail: mail,
            whatsAppGroupLink: whatsAppGroupLink,

            detail: detail
        })

        const isNotNullId = id != null

        const defaultPath = process.env.BASE_URL + "/api/v1/games"
        const path = isNotNullId ? defaultPath + "/" + id : defaultPath
        const method = isNotNullId ? PUT : POST

        const response = await fetch(path, {
            method: method,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
            body: body,
        })

        return eventTransformer(await resParser(response))
    }

    async getAll(
        ids,
        keyword,
        statuses,
        isMixed,
        grounds,
        startDate,
        endDate,
        levelFrom,
        levelTo,
        regions,
        managerIds,
        page,
        size
    ) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        let path = process.env.BASE_URL + "/api/v1/games?page=0&size=100"

        if (ids != null && ids !== "") path = path + "&ids=" + ids
        if (keyword != null && keyword !== "") path = path + "&keyword=" + keyword
        if (statuses != null && statuses !== "") path = path + "&statuses=" + statuses
        if (managerIds != null && managerIds !== "") path = path + "&managerIds=" + managerIds
        if (startDate != null && startDate !== "") path = path + "&startDate=" + startDate
        if (endDate != null && endDate !== "") path = path + "&endDate=" + endDate
        if (page != null && page !== "") path = path + "&page=" + page
        if (size != null && size !== "") path = path + "&size=" + size

        const response = await fetch(path, {
            method: GET,
            headers: { "Content-Type": APPLICATION_JSON, "Authorization": auth },
        })

        return eventsTransformers(await resParser(response))
    }

    async search(
        ids,
        keyword,
        isMixed,
        grounds,
        startDate,
        endDate,
        levelFrom,
        levelTo,
        regions,
        page,
        size,
    ) {
        const body = JSON.stringify({
            ids: ids,
            keyword: keyword,
            isMixed: isMixed,
            grounds: grounds,
            startDate: startDate,
            endDate: endDate,
            levelFrom: levelFrom,
            levelTo: levelTo,
            regions: regions,
            page: page, size: size
        })

        const path = process.env.BASE_URL + "/api/v1/games/search"

        const response = await fetch(path, {
            method: POST,
            headers: { "Content-Type": APPLICATION_JSON },
            body: body
        })

        return eventsTransformers(await resParser(response))
    }

    async getById(id) {
        const path = process.env.BASE_URL + "/api/v1/games/" + id

        const response = await fetch(path, {
            method: GET,
            headers: { "Content-Type": APPLICATION_JSON }
        })

        return eventTransformer(await resParser(response))
    }

    async updateStatus(id, status) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const body = JSON.stringify({ status: status })

        const path = process.env.BASE_URL + "/api/v1/games/" + id + "/status"

        const response = await fetch(path, {
            method: PUT,
            headers: { "Content-Type": APPLICATION_JSON, "Authorization": auth },
            body: body
        })

        return eventTransformer(await resParser(response))
    }

    async updateManager(id, userId, action) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const body = JSON.stringify({ userToGrant: { [action]: userId } })

        const path = process.env.BASE_URL + "/api/v1/games/" + id + "/managers"

        const response = await fetch(path, {
            method: PUT,
            headers: { "Content-Type": APPLICATION_JSON, "Authorization": auth },
            body: body
        })

        return eventTransformer(await resParser(response))
    }

    async delete(id) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/games/" + id

        const response = await fetch(path, {
            method: DELETE,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
        })

        return await resParser(response)
    }

    async paymentProviderByOrganisation(id, rnaNumber) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/games/" + id + "/payment-provider"

        const body = JSON.stringify({ organization: { rnaNumber: rnaNumber } })

        const response = await fetch(path, {
            method: PUT,
            headers: { "Content-Type": APPLICATION_JSON, "Authorization": auth },
            body: body
        })

        return eventTransformer(await resParser(response))
    }

    async paymentProviderByConnection(id, code, codeVerifier, redirectUri) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/games/" + id + "/payment-provider"

        const body = JSON.stringify({
            connection: {
                code: code,
                codeVerifier: codeVerifier,
                redirectUri: redirectUri
            }
        })

        const response = await fetch(path, {
            method: PUT,
            headers: { "Content-Type": APPLICATION_JSON, "Authorization": auth },
            body: body
        })

        return eventTransformer(await resParser(response))
    }

    async deletePaymentProvider(id) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/games/" + id + "/payment-provider"

        const response = await fetch(path, {
            method: PUT,
            headers: { "Content-Type": APPLICATION_JSON, "Authorization": auth },
            body: JSON.stringify({ })
        })

        return eventTransformer(await resParser(response))
    }

    async getByUsername(username) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/games/registrations/" + username

        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }

        const response = await fetch(path, { method: GET, headers: headers })

        return eventsTransformers(await resParser(response))
    }

    async memberSearchingTeam(gameId, action) {
        await refreshToken()

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.BASE_URL + "/api/v1/games/" + gameId + "/registration/members-searching-team"
        const headers = { "Content-Type": APPLICATION_JSON, "Authorization": auth }
        const body = JSON.stringify({ action: action })

        const response = await fetch(path, {
            method: PUT,
            headers: headers,
            body: body
        })

        return eventTransformer(await resParser(response))
    }

}

export const eventTransformer = (value) => {
    value.playerPrice = value.playerPrice / 100
    value.dayOfEventPlayerPrice = !value.dayOfEventPlayerPrice ? null : value.dayOfEventPlayerPrice / 100

    return value
}

export const eventsTransformers = (values) => {
    if (values.length != null) return values.map(it => eventTransformer(it))

    return values
}

export const gameService = new GameService()