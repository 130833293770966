import i18n from "i18next"
import React, { useEffect, useState } from "react"
import { teamService } from "../service/team-service"

export default function MembersSettingsOffcanvas(props) {

    const eventId = props.eventId
    const teamId = props.teamId
    const member = props.member
    const initVisibility = member != null ? member.visibility : undefined

    const [ visibility, setVisibility ] = useState(initVisibility)

    useEffect(() => {
        if (props.member == null || props.member.visibility == null) return

        setVisibility(props.member.visibility)
    }, [ props.member ]);

    if (member == null) return <React.Fragment></React.Fragment>

    const onHandleUpdateMember = async () => await teamService.updateMember(
        eventId,
        teamId,
        member.username,
        visibility
    )

    return <div
        id={ props.id }
        className="offcanvas offcanvas-end"
        data-bs-scroll="false"
        tabIndex="-1"
    >
        <div className="offcanvas-header border-bottom shadow">
            <div className="row col-12 mx-0" style={ { height: "48px" } }>
                <div className="col-6 ps-0">
                    <button
                        type="button"
                        className="btn btn-primary shadow-sm h-100 w-100"
                        data-bs-dismiss="offcanvas"
                        onClick={ onHandleUpdateMember }
                    >
                        { i18n.t("button.save") }
                    </button>
                </div>
                <div className="col-6 pe-0">
                    <button
                        type="button"
                        className="btn btn-warning shadow-sm h-100 w-100"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16"
                            fill="currentColor"
                            className="bi bi-x-lg" viewBox="0 0 16 16"
                        >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div className="offcanvas-body">
            <div className="btn-group w-100 shadow-sm" aria-label="Visibility radio toggle button group">

                <input
                    id={ "btn-radio-public-" + teamId + "-" + member.username }
                    type="radio"
                    className="btn-check"
                    autoComplete="off"
                    checked={ visibility === "PUBLIC" }
                    onChange={ () => setVisibility("PUBLIC") }
                />
                <label
                    className="btn btn-outline-primary btn-sm"
                    htmlFor={ "btn-radio-public-" + teamId + "-" + member.username }
                >{ i18n.t("common.public") }</label>

                <input
                    id={ "btn-radio-private-" + teamId + "-" + member.username }
                    type="radio"
                    className="btn-check"
                    autoComplete="off"
                    checked={ visibility === "PRIVATE" }
                    onChange={ () => setVisibility("PRIVATE") }
                />
                <label
                    className="btn btn-outline-primary btn-sm"
                    htmlFor={ "btn-radio-private-" + teamId + "-" + member.username }
                >{ i18n.t("common.private") }</label>

            </div>
        </div>
    </div>
}