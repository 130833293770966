import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ShowcasePicture from "./showcase-picture"
import i18n from "i18next"
import { warningToast } from "../const/utils"

const thumb = {
    display: 'inline-flex',
    width: 150,
    maxHeight: 300,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 7,
    borderColor: '#a4a4a4',
    borderStyle: 'dashed',
    color: '#989797',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

export default function Record(props) {

    const initFiles = props.files != null ? props.files : []
    const onHandleFile = props.onHandleFile

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxFiles: 1,
        accept: {
            'image/*': []
        },
        onDrop: async acceptedFiles => {

            const file = await fileGetter(acceptedFiles)

            if (file.size > 1000000) {
                return warningToast(i18n.t("record.picture-too-heavy") + " ⚠ 1MB " + i18n.t("record.limit") + " Max ⚠")
            }

            const files = acceptedFiles.map(it => Object.assign(it, { preview: URL.createObjectURL(it) }))

            onHandleFile(file)
            setFiles(files)
        }
    })

    async function fileGetter(files) {
        if (files == null || files.length === 0) return null

        return files[0]
    }

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ])

    const [files, setFiles] = useState(initFiles)
    const [modalUrl, setModalUrl] = useState("")

    const onClick = () => {
        onHandleFile(null)
        setFiles([])
    }

    const thumbs = files.map(file => {
        return (
            <div className="mt-3" style={ thumb } key={ file.name }>
                <div style={ thumbInner }>
                    <div
                        style={ {
                            position: "relative",
                            textAlign: "center",
                            marginLeft: "10px",
                            marginRight: "10px",
                            marginTop: "10px"
                        } }
                    >
                        <ShowcasePicture url={ file.preview } onHandleModalUrl={ (url) => setModalUrl(url) }/>
                        <div style={ { position: "absolute", top: "-7px", left: "-5px" } }>
                            <button
                                className="btn p-0 m-0 border-0 bg-transparent"
                                onClick={ onClick }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-x-circle bg-danger rounded-circle text-white"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [])

    return <React.Fragment>
        <div className="modal-fade bg-body-tertiary shadow-sm border rounded p-3">
            <div { ...getRootProps({ style } ) }>
                <input { ...getInputProps() } />
                <div className="text-center">
                    <p className="mb-1">{ i18n.t("record.dragNDrop") }</p>
                    <p className="mb-0">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-exclamation-triangle-fill"
                            style={ { marginBottom: "0.757px" } }
                            viewBox="0 0 16 16"
                        >
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                        </svg> 1MB { i18n.t("record.limit") } Max <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-exclamation-triangle-fill"
                            style={ { marginBottom: "0.757px" } }
                            viewBox="0 0 16 16"
                        >
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                        </svg>
                    </p>
                </div>
            </div>
            <div>
                <aside>
                    { thumbs }
                </aside>
            </div>
        </div>
        <div
            id="gameModal"
            className="modal fade"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="col">
                        <div>
                            <img
                                src={ modalUrl }
                                className="card-img-top"
                                alt="tounament_image"
                                style={ { borderTopRightRadius: "0.375rem", borderTopLeftRadius: "0.375rem" } }
                            />
                        </div>
                        <div style={ { position: "absolute", top: "8px", right: "8px" } }>
                            <button
                                type="button"
                                className="z-1 btn btn-lg btn-danger shadow-sm"
                                data-bs-dismiss="modal"
                                style={ { paddingBottom: "9px", paddingTop: "5px" } }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-x m-auto"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}