import Input from "../component/input"
import i18n from "i18next"
import MembersSuggestion, { initGender } from "../component/members-suggestion"
import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { userService } from "../service/user-service"
import { disconnect } from "../const/utils"
import { Context } from "../const/context"
import { captainCard, usersCard } from "../const/common"
import { PActionButton } from "../component/action-button"
import { teamService } from "../service/team-service"

export default function Team() {

    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    const state = location.state

    const event = state.event
    const team = state.team
    const orders = state.orders

    const maxMaleNumber = event.nbOfMember - event.minFemaleInTeam
    const maxFemaleNumber = event.nbOfMember - event.minMaleInTeam
    const nbOfTeamMember = team.members.length

    const [ keyword, setKeyword ] = useState("")

    const [ teamName, setTeamName ] = useState(team.name)
    const [ gender, setGender ] = useState(null)
    const [ onlyInterestedMembers, setOnlyInterestedMembers ] = useState(event.membersSearchingTeam.length > 0)

    const [ captain, setCaptain ] = useState(null)

    const [ teamMembers, setTeamMembers ] = useState([])
    const [ membersToRemove, setMembersToRemove ] = useState([])
    const [ userSearch, setUserSearch ] = useState([])
    const [ requestMembers, setRequestMembers ] = useState([])

    const [ maleAddButtonDisable, setMaleAddButtonDisable ] = useState(false)
    const [ femaleAddButtonDisable, setFemaleAddButtonDisable ] = useState(false)

    const [ teamRequestMembers, setTeamRequestMembers ] = useState([])

    useEffect(() => {
        if (event == null || team == null || captain != null) return

        async function fetchUsers() {
            const captainUserId = team.members.find(it => it.username === team.captain).userId

            const userRes = await userService.getById(captainUserId)

            if (userRes.status === 401) return disconnect(navigate, context, -1)
            if (userRes.error != null || userRes.status >= 402 || userRes.httpCode != null) return
            if (userRes.type === "O2AUTH" && userRes.partner === "HELLO_ASSO") return navigate(-1)

            const memberUsername = team.members.map(it => it.username).filter(it => it !== userRes.username)
            const requestMembers = team.requestMembers

            const res = await userService.search(
                null,
                null,
                null,
                null,
                "USER_DEFAULT",
                true,
                [...new Set([...requestMembers, ...memberUsername])],
                100
            )

            setTeamMembers(res.filter(it => memberUsername.includes(it.username)))
            setRequestMembers(res.filter(it => requestMembers.includes(it.username)))
            setTeamRequestMembers(res.filter(it => requestMembers.includes(it.username)))
            setCaptain(userRes)
        }

        fetchUsers().then()
    }, [ ])

    useEffect(() => {
        if (captain == null) return

        const totalNbOfMember = nbOfTeamMember + requestMembers.length

        const nbOfMaleRequest = requestMembers.filter(it => it.gender === "MALE").length
        const nbOfFemaleRequest = requestMembers.filter(it => it.gender === "FEMALE").length
        const nbOfMaleTeamMember = teamMembers.filter(it => it.gender === "MALE").length
        const nbOfFemaleTeamMember = teamMembers.filter(it => it.gender === "FEMALE").length

        const nbOfMaleMembers = nbOfMaleRequest + nbOfMaleTeamMember + (captain.gender === "MALE" ? 1 : 0)
        const nbOfFemaleMembers = nbOfFemaleRequest + nbOfFemaleTeamMember + (captain.gender === "FEMALE" ? 1 : 0)

        const nbOfExtraMember = event.nbOfExtraMember

        const isNbOfMemberExceed = totalNbOfMember < event.nbOfMember + nbOfExtraMember

        const isMaleAddButtonAllow = isNbOfMemberExceed && nbOfMaleMembers < maxMaleNumber + nbOfExtraMember
        const isFemaleAddButtonAllow = isNbOfMemberExceed && nbOfFemaleMembers < maxFemaleNumber + nbOfExtraMember

        setFemaleAddButtonDisable(!isFemaleAddButtonAllow)
        setMaleAddButtonDisable(!isMaleAddButtonAllow)
    }, [
        userSearch, teamMembers, requestMembers, captain
    ])

    useEffect(() => {
        if (event == null || team == null || captain == null) return
        if (keyword === "") fetchUserSearch().then()
        if (keyword.length > 3) fetchUserSearch().then()
    }, [
        keyword, onlyInterestedMembers, gender, captain
    ])

    const fetchUserSearch = async () => {
        const memberIdsToRemove = membersToRemove.map(it => it.id)
        const allTeamMemberIds = event.teams.filter(
            it => it.type === "TEAM_DEFAULT"
        ).flatMap(
            it => it.members
        ).flatMap(
            it => it.userId
        )

        const notIds = [
            ...teamMembers.map(it => it.id),
            ...requestMembers.map(it => it.id),
            ...allTeamMemberIds.filter(it => !memberIdsToRemove.includes(it)),
            captain.id
        ]

        const ids = onlyInterestedMembers ? event.membersSearchingTeam : null
        const size = onlyInterestedMembers && event.membersSearchingTeam.length > 0 ? event.membersSearchingTeam.length : 10

        const res = await userService.search(
            ids,
            notIds,
            keyword,
            gender,
            "USER_DEFAULT",
            true,
            [],
            size
        )

        if (res.error != null || res.status >= 402 || res.httpCode != null) return
        if (res.status === 401) return disconnect(navigate, context, -1)

        setUserSearch(res)
    }

    const onHandleFilter = (filter) => {

        const onlyInterestedMembers = filter["onlyInterestedMembers"]
        const gender = filter["gender"]

        setOnlyInterestedMembers(onlyInterestedMembers)

        if (gender != null) {
            if (gender === "MIXED") setGender(null)
            if (gender === "FEMALE") setGender("FEMALE")
            if (gender === "MALE") setGender("MALE")
        }
    }

    const onHandleRemoveTeamMember = (user) => {
        const newTeamMembers = teamMembers.filter(it => it.id !== user.id)
        const newTeamMembersToRemove = [ ...membersToRemove, user ]

        setTeamMembers(newTeamMembers)
        setMembersToRemove(newTeamMembersToRemove)
    }

    const onHandleAddRequestMember = (user) => {
        const newSearchUsers = userSearch.filter(it => it.id !== user.id)

        setUserSearch(newSearchUsers)

        if (membersToRemove.find(it => it.id === user.id)) setTeamMembers([ ...teamMembers, user ])
        else setRequestMembers([ ...requestMembers, user ])
    }

    const onHandleRemoveRequestMember = (user) => {
        const newSearchUsers = [ ...userSearch, user ]
        const newRequestMember = requestMembers.filter(it => it.id !== user.id)

        setUserSearch(newSearchUsers)
        setRequestMembers(newRequestMember)
    }

    const onSaveTeam = async () => {
        const formattedMembersToRemove = membersToRemove.map(it => it.id)

        const formattedRequestMembers = requestMembers.map(it => it.id)
        const currentRequestMembers = teamRequestMembers.filter(
            it => team.requestMembers.includes(it.username)
        ).map(
            it => it.id
        )

        const requestMembersToAdd = formattedRequestMembers.filter(it => !currentRequestMembers.includes(it))
        const requestMembersToRemove = currentRequestMembers.filter(it => !formattedRequestMembers.includes(it))

        const res = await teamService.update(
            event.id,
            team.id,
            formattedMembersToRemove,
            requestMembersToAdd,
            requestMembersToRemove
        )

        if (res.status === 401) return disconnect(navigate, context, -1)

        navigate(-1)
    }

    const removeMemberButton = (user) => {
        const isDisabled = orders.filter(it => it.username === user.username).length > 0

        return <button
            className="btn btn-danger"
            onClick={ () => onHandleRemoveTeamMember(user) }
            disabled={ isDisabled }
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16" height="16"
                fill="currentColor"
                className="bi bi-dash-lg"
                viewBox="0 0 16 16"
            >
                <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"/>
            </svg>
        </button>
    }

    const addRequestMemberButton = (user) => <button
        className="btn btn-primary"
        disabled={ user.gender === "FEMALE" ? femaleAddButtonDisable : maleAddButtonDisable }
        onClick={ () => onHandleAddRequestMember(user) }
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16" height="16"
            fill="currentColor"
            className="bi bi-plus-lg" viewBox="0 0 16 16"
        >
            <path
                fillRule="evenodd"
                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
            />
        </svg>
    </button>

    const removeRequestMemberButton = (user) => <button
        className="btn btn-danger"
        onClick={ () => onHandleRemoveRequestMember(user) }
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16" height="16"
            fill="currentColor"
            className="bi bi-dash-lg"
            viewBox="0 0 16 16"
        >
            <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"/>
        </svg>
    </button>


    return <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto p-0 pb-3">

        <div className="row border shadow-sm rounded px-3 py-2 mx-0 mb-3">

            <div className="col-4 px-0 my-auto">
                <p className="fs-2 m-0">{ i18n.t("common.team") }</p>
            </div>

            <div className="col-8 text-end m-auto px-0">
                <div className="d-flex justify-content-end">
                    <div className="d-flex justify-content-between">
                        <button
                            className="btn btn-warning me-2"
                            onClick={ () => navigate(-1) }
                        >
                            <div className="row">
                                <div className="col-1">

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17" height="17"
                                        fill="currentColor"
                                        className="bi bi-arrow-left-circle"
                                        viewBox="-2 0 20 15"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                        />
                                    </svg>
                                </div>
                                <div className="col px-1">{ i18n.t("button.back") }</div>
                            </div>
                        </button>
                        <div>
                            <div className="d-flex justify-content-end h-100">
                                <PActionButton
                                    onClick={ onSaveTeam }
                                    disabled={ teamName.length <= 0 }
                                >
                                    <div className="row">
                                        <div className="col-1">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="17" height="17"
                                                fill="currentColor"
                                                className="bi bi-floppy2"
                                                viewBox="0 -1 20 15"
                                            >
                                                <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v3.5A1.5 1.5 0 0 1 11.5 6h-7A1.5 1.5 0 0 1 3 4.5V1H1.5a.5.5 0 0 0-.5.5m9.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"/>
                                            </svg>
                                        </div>
                                        <div className="col px-1">{ i18n.t("button.save") }</div>
                                    </div>
                                </PActionButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="px-1 my-4">
            <div className="col card border-2" style={ { alignSelf: "center" } }></div>
        </div>

        <div className="row row-gap-3 mx-0">

            <Input
                id="team-name"
                type="text"
                iconName="people"
                placeholder="teamName"
                label={ i18n.t("common.team-name") + "*" }
                value={ teamName }
                validator={ (value) => value.length > 0 }
                onHandleInput={ (id, value) => setTeamName(value) }
                disabled
            />

            { captainCard(captain) }

            { usersCard(teamMembers, removeMemberButton) }

            <MembersSuggestion
                event={ event }
                requestMembers={ requestMembers }
                userSearch={ userSearch }
                addButton={ addRequestMemberButton }
                removeButton={ removeRequestMemberButton }
                setKeyword={ setKeyword }
                filters={ {
                    onlyInterestedMembers: onlyInterestedMembers,
                    gender: initGender(event),
                } }
                onHandleFilter={ onHandleFilter }
            />
        </div>
    </div>
}